import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import OverdueFee from 'src/types/OverdueFee'

export const getOverdueFees = createAsyncThunk(
  'overdueFees/getOverdueFees',
  ({ companyID }: { companyID: number }) => api.getOverdueFees(companyID),
)

interface OverdueFeesProps {
  isLoading: boolean
  totalDue: number | null
  accountBalance: number | null
  overdueFees: OverdueFee[]
}

export const initialState: OverdueFeesProps = {
  isLoading: true,
  totalDue: null,
  accountBalance: null,
  overdueFees: [],
}

const overdueFeesSlice = createSlice({
  name: 'overdueFees',
  initialState,
  reducers: {
    setOverdueFees: (state, action) => {
      state.totalDue = action.payload.totalDue
    },
    resetOverdueFees: (state, action) => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getOverdueFees.fulfilled, (state, action) => {
      state.isLoading = false
      state.totalDue = action.payload.total_due_in_cents
      state.accountBalance = action.payload.account_balance_in_cents
      state.overdueFees = action.payload.overdue_fees
    })
    builder.addCase(getOverdueFees.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export const { setOverdueFees, resetOverdueFees } = overdueFeesSlice.actions

export default overdueFeesSlice.reducer
