/* eslint-disable new-cap */
import dayjs from 'dayjs'
import { getCurrentScope } from '@sentry/react'

import { DELETE_AUTH } from 'src/config/constants'
import { saveSMSDigits } from 'src/utils/_functions'
import * as api from 'src/api/ggAPI'
import { getAuth, setAuth, saveRememberDeviceToken } from 'src/utils/auth'
import * as ggUrl from 'src/utils/ggUrl'
import { userLogin, saveQR, getUser, trackSnowplow, logout } from '../slices/user.slice'
import routes from 'src/pages/AppWrapper/AppRoutes'
import { getInvestments } from '../slices/investments.slice'
import { getCompanyOnboardings } from '../slices/companyOnboardings.slice'
import { setUserCountry } from '../slices/countries.slice'
import { identifyWithThrottle } from '../../utils/functions'

const SESSION_TIMEOUT_MINUTES = 30
const REAUTHENTICATE_THRESHOLD_MINUTES = 15

const handleReauth = (dispatch) => {
  const timer = setInterval(async () => {
    const auth = getAuth()
    if (auth) {
      const currentTime = dayjs()
      const minutesSinceLastAuthed = dayjs(currentTime).diff(auth.timestamp, 'minute')
      if (
        minutesSinceLastAuthed >= REAUTHENTICATE_THRESHOLD_MINUTES &&
        minutesSinceLastAuthed < SESSION_TIMEOUT_MINUTES
      ) {
        clearInterval(timer)
        await dispatch(reauthenticate())
      }
      if (minutesSinceLastAuthed >= SESSION_TIMEOUT_MINUTES) {
        clearInterval(timer)
        dispatch(logout())
        routes?.navigate(ggUrl.loginUrl())
      }
    }
  }, 3 * 1000) // check auth every 3 seconds
}

export const reauthenticate = () => async (dispatch) => {
  await api
    .reauthenticate()
    .then(async (response) => {
      const { jwt_token } = response
      setAuth(jwt_token)
      const user = await dispatch(getUser()).unwrap()
      if (user) {
        /* update session storage for launchdarkly keys */
        api.getCountries().then((res) => {
          const matchingCountry = res?.countries?.find((country) => country.id === user?.country)
          dispatch(setUserCountry(matchingCountry || null))
          sessionStorage.setItem('userCountry', matchingCountry?.country_name?.toString())
        })
        sessionStorage.setItem('userID', user.id.toString())
        if (user?.email) {
          sessionStorage.setItem('user_email', user.email)
        }
      }
      handleReauth(dispatch)
    })
    .catch((err) => {
      if (err.message !== 'claims not authorised' && (err.status === 401 || err.status === 404)) {
        dispatch(logout())
        routes?.navigate(ggUrl.loginUrl())
      }
    })
}

export const unAuthJwt = (email, password) => (dispatch) => {
  return api.unAuthJwt(email, password).then(async (response = {}) => {
    if (response.token) {
      setAuth(response.token)
    }
    if (response.phone_last_four_digits) {
      saveSMSDigits(response.phone_last_four_digits)
    }
    if (response.key_uri) {
      dispatch(saveQR(response.key_uri))
    }
    return response
  })
}

export const verifyQRToken = (input, isRememberDevice) => (dispatch) => {
  return api.verifyQRToken(input, isRememberDevice).then(async (response) => {
    await handleVerifyResponse(response, dispatch)
    return response
  })
}

export const login = (token) => (dispatch) => {
  return api.login(token).then(async (response) => {
    setAuth(response.jwt_token)
    dispatch(getUser())
    dispatch(userLogin(response))
    return response
  })
}

export const getEmployee = () => (dispatch) => {
  return api.getEmployee().then((res) => {
    dispatch(userLogin(res))
    return res
  })
}

export const deleteAuth = () => (dispatch) => {
  dispatch({ type: DELETE_AUTH })
}

export const verifySMS = (input, isRememberDevice) => (dispatch) => {
  return api.verifySMS(input, isRememberDevice).then(async (response) => {
    await handleVerifyResponse(response, dispatch)
    return response
  })
}

const handleVerifyResponse = async (response, dispatch) => {
  if (response) {
    const { jwt_token, user, remember_device_token } = response

    if (user.id) {
      const scope = getCurrentScope()
      scope.setUser({
        id: user.id,
        email: user.email,
      })

      identifyWithThrottle(user.id, { email: user.email })

      if (window.heap) {
        window.heap.identify(user.id.toString())
      }
      if (window.auryc) {
        window.auryc.identify(user.id.toString())
      }
    }

    setAuth(jwt_token)
    if (remember_device_token) {
      await saveRememberDeviceToken(remember_device_token)
    }
    handleReauth(dispatch)
    if (user) {
      api.getCountries().then((res) => {
        const matchingCountry = res?.countries?.find((country) => country.id === user?.country)
        dispatch(setUserCountry(matchingCountry || null))
        sessionStorage.setItem('userCountry', matchingCountry?.country_name?.toString())
      })
      sessionStorage.setItem('userID', user.id.toString())
      if (user?.email) {
        sessionStorage.setItem('user_email', user.email)
      }
      dispatch(userLogin(user))
      dispatch(getUser())
      dispatch(getInvestments())
      dispatch(getCompanyOnboardings())
      dispatch(
        trackSnowplow({
          category: 'auth',
          action: 'login_success',
        }),
      )
    }
  }
}

export default {
  reauthenticate,
  unAuthJwt,
  verifyQRToken,
  login,
  getEmployee,
  deleteAuth,
  verifySMS,
}
