import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import GGPayment, { PaymentUpdateData } from 'src/types/GGPayment'

export const getGGPayments = createAsyncThunk(
  'payments/getGGPayments',
  ({ companyID }: { companyID: number }) => {
    return api.getGGPayments(companyID)
  },
)

export const getCanUpdateCreditCard = createAsyncThunk(
  'payments/getCanUpdateCreditCard',
  (companyId: string) => api.getCanUpdateCreditCard(companyId),
)

export const fetchUpdateCreditCardInfo = createAsyncThunk(
  'payments/fetchUpdateCreditCardInfo',
  ({
    companyId,
    successUrl,
    cancelUrl,
  }: {
    companyId: string
    successUrl?: string
    cancelUrl?: string
  }) => api.fetchUpdateCreditCardInfo(companyId, successUrl, cancelUrl),
)

interface PaymentsInterface {
  isLoading: boolean
  data: GGPayment[]
  paymentUpdateData: PaymentUpdateData
}

const initialState: PaymentsInterface = {
  isLoading: true,
  data: [],
  paymentUpdateData: {},
}

function setPaymentUpdateDetailsLoading(state: any) {
  return {
    ...state,
    paymentUpdateData: {
      ...state.paymentUpdateData,
      isLoading: true,
      error: undefined,
    },
  }
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGGPayments.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getGGPayments.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    })
    builder.addCase(getCanUpdateCreditCard.pending, setPaymentUpdateDetailsLoading)
    builder.addCase(getCanUpdateCreditCard.fulfilled, (state, action) => {
      return {
        ...state,
        paymentUpdateData: {
          ...state.paymentUpdateData,
          isLoading: false,
          canCreateSubscriptionUpdateLink: action.payload.can_create_subscription_update_link,
        },
      }
    })
    builder.addCase(fetchUpdateCreditCardInfo.pending, setPaymentUpdateDetailsLoading)
    builder.addCase(fetchUpdateCreditCardInfo.fulfilled, (state, action) => {
      return {
        ...state,
        paymentUpdateData: {
          ...state.paymentUpdateData,
          isLoading: false,
          checkoutSessionId: action.payload.checkout_session_id,
        },
      }
    })
    builder.addCase(fetchUpdateCreditCardInfo.rejected, (state) => {
      return {
        ...state,
        paymentUpdateData: {
          ...state.paymentUpdateData,
          isLoading: false,
          error: 'Something went wrong.  Please try again',
        },
      }
    })
  },
})

export default paymentsSlice.reducer
