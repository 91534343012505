import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ParsedQuery } from 'query-string'
import { MarketplaceListing } from 'src/types/PropertyListing'
import { sortMapping } from 'src/components-pages/BrowseProperty/Filters/SelectOptions'
import {
  getListingsDevs as apiGetListingsDevs,
  getListingsFilters as apiGetListingsFilters,
  saveListing as apiSaveListing,
  removeSavedListing as apiRemoveSavedListing,
  saveDevelopmentListing as apiSaveDevelopmentListing,
  removeSavedDevelopmentListing as apiRemoveSavedDevelopmentListing,
} from 'src/api/ggAPI'
import { getPropertyListingFilteredQueryString } from 'src/utils/functions'
import Filter from 'src/types/ListingFilter'
import routes from 'src/pages/AppWrapper/AppRoutes'

interface InitialState {
  filters: Filter[]
  listings: MarketplaceListing[]
  page: number
  maxSize: number
  isLoadingFilters: boolean
  isLoading: boolean
  isLoadingSavedProperties: boolean
  total: number
  totalSavedListings: number
  isPhoneNumberModalOpen: boolean
  selectedID: { id: string; pathname: string; search: string }
  listingsSortSeed?: number
}

const MAX_SIZE = 12

export const getListingsDevsParams = (page: number, params: ParsedQuery<string>) => {
  const {
    price_min,
    price_max,
    deposit_max,
    sort_field,
    is_new_build,
    development,
    locations,
    completion_date,
  } = params

  const sort = sort_field ? sortMapping[sort_field] : null
  const sortValue = sort?.sort_field
  const sort_ascending = sortValue && sort.asc
  const sort_descending = sortValue && !sort.asc

  return {
    offset: page * MAX_SIZE,
    size: MAX_SIZE,
    price_min,
    price_max,
    deposit_max,
    sort_field: sortValue,
    sort_ascending,
    sort_descending,
    is_new_build,
    development,
    locations,
    completion_date,
  }
}

export const initialState: InitialState = {
  filters: [],
  listings: [],
  maxSize: MAX_SIZE,
  page: 0,
  isLoadingFilters: true,
  isLoading: true,
  isLoadingSavedProperties: true,
  total: 0,
  totalSavedListings: 0,
  isPhoneNumberModalOpen: false,
  selectedID: {
    id: '',
    pathname: '',
    search: '',
  },
}

export const getListingsDevs = createAsyncThunk(
  'propertyListing/getListingsDevs',
  (
    { page, params, seed = 0 }: { page: number; params: ParsedQuery<string>; seed?: number },
    { rejectWithValue },
  ) => {
    const listingsDevsParams = getListingsDevsParams(page, params)
    return apiGetListingsDevs({ ...listingsDevsParams, seed })
      .then(({ marketplace_listings, total, count_saved }) => ({
        listings: marketplace_listings,
        total,
        totalListingsSaved: count_saved,
      }))
      .catch(({ status }) => {
        if (status === 403) {
          routes?.navigate('/dashboard', {
            state: {
              listingErrorCode: 403,
            },
            replace: true,
          })
        }
        return rejectWithValue({ code: status })
      })
  },
)

export const getSavedListings = createAsyncThunk(
  'propertyListing/getSavedListings',
  ({ page, params }: { page: number; params: ParsedQuery<string> }, { rejectWithValue }) => {
    const listingsParams = getListingsDevsParams(page, params)
    return apiGetListingsDevs({ ...listingsParams, saved_listings: true })
      .then(({ count_saved, marketplace_listings, total }) => ({
        listings: marketplace_listings,
        totalListingsSaved: count_saved,
        total,
      }))
      .catch(({ status }) => {
        if (status === 403) {
          routes?.navigate('/dashboard', {
            state: {
              listingErrorCode: 403,
            },
            replace: true,
          })
        }
        return rejectWithValue({ code: status })
      })
  },
)

export const getAllSavedListings = createAsyncThunk(
  'propertyListing/getAllSavedListings',
  ({ size }: { size: number }) => {
    return apiGetListingsDevs({ offset: 0, size, saved_listings: true }).then(
      ({ marketplace_listings, count_saved, total }) => ({
        listings: marketplace_listings.filter<MarketplaceListing>(
          (listing) => listing.type === 'LISTING',
        ),
        totalListingsSaved: count_saved,
        total,
      }),
    )
  },
)

export const loadMoreListingsDevs = createAsyncThunk(
  'propertyListing/loadMoreListingsDevs',
  (
    { page, params, seed = 0 }: { page: number; params: ParsedQuery<string>; seed?: number },
    { rejectWithValue },
  ) => {
    const listingsDevsParams = getListingsDevsParams(page, params)
    return apiGetListingsDevs({ ...listingsDevsParams, seed })
      .then(({ marketplace_listings }) => ({
        listings: marketplace_listings,
      }))
      .catch(({ status }) => rejectWithValue({ code: status }))
  },
)

export const loadMoreSavedListings = createAsyncThunk(
  'propertyListing/loadMoreSavedListings',
  ({ page, params }: { page: number; params: ParsedQuery<string> }, { rejectWithValue }) => {
    const listingsParams = getListingsDevsParams(page, params)
    return apiGetListingsDevs({ ...listingsParams, saved_listings: true })
      .then(({ marketplace_listings }) => ({
        listings: marketplace_listings,
      }))
      .catch(({ status }) => rejectWithValue({ code: status }))
  },
)

export const getListingsFilters = createAsyncThunk(
  'propertyListing/getListingsFilters',
  (
    { pathname, params, state }: { pathname: string; params: ParsedQuery<string>; state: any },
    { rejectWithValue },
  ) =>
    apiGetListingsFilters()
      .then(async (filters) => {
        const query = getPropertyListingFilteredQueryString({ params, filters, pathname })
        routes?.navigate(query, { replace: true, state })
        return filters
      })
      .catch(async ({ data }) => {
        const query = getPropertyListingFilteredQueryString({ params, pathname })
        routes?.navigate(query, { replace: true })
        return rejectWithValue(data)
      }),
)

export const saveListing = createAsyncThunk(
  'propertyListing/saveListing',
  ({ listingID }: { listingID: number; isSingleListing?: boolean }) =>
    apiSaveListing({ listingID }),
)

export const removeSavedListing = createAsyncThunk(
  'propertyListing/removeSavedListing',
  ({ listingID }: { listingID: number; isSavedProperties?: boolean }) =>
    apiRemoveSavedListing({ listingID }),
)

export const saveListingDevelopment = createAsyncThunk(
  'propertyListing/saveListingDevelopment',
  ({ developmentID }: { developmentID: number }) => apiSaveDevelopmentListing({ developmentID }),
)

export const removeSavedListingDevelopment = createAsyncThunk(
  'propertyListing/removeSavedListingDevelopment',
  ({ developmentID }: { developmentID: number; isSavedProperties?: boolean }) =>
    apiRemoveSavedDevelopmentListing({ developmentID }),
)

const handleRemoveSavedListing = (
  listings: MarketplaceListing[],
  listingID: number,
  isSavedProperties: boolean,
) => {
  if (isSavedProperties) {
    return listings.filter((listing) => listing.listing?.id !== listingID)
  }

  listings.find((li) => li.listing?.id === listingID).listing.has_user_saved_listing = false

  return listings
}

const handleRemoveSavedDevelopment = (
  listings: MarketplaceListing[],
  developmentID: number,
  isSavedProperties: boolean,
) => {
  if (isSavedProperties) {
    return listings.filter((listing) => listing.development?.id !== developmentID)
  }

  listings.find((li) => li.development?.id === developmentID).development.has_user_saved_listing =
    false

  return listings
}

export const propertyListingSlice = createSlice({
  name: 'propertyListing',
  initialState,
  reducers: {
    resetListings: () => initialState,
    setIsLoadingFiltersFinished: (state) => ({ ...state, isLoadingFilters: false }),
    setIsPhoneNumberModalOpen: (state, action) => ({
      ...state,
      isPhoneNumberModalOpen: action.payload,
    }),
    setTotalSavedListings: (state, action) => ({ ...state, totalSavedListings: action.payload }),
    setSelectedID: (state, action) => ({ ...state, selectedID: action.payload }),
    setSavedDevelopment: (state, action) => {
      state.listings.find(
        (li) => Number(li.development?.id) === Number(action.payload),
      ).development.has_user_saved_listing = true
      state.totalSavedListings += 1
    },
    setRemoveSavedDevelopment: (state, action) => {
      state.listings.find(
        (li) => Number(li.development?.id) === Number(action.payload),
      ).development.has_user_saved_listing = false
      state.totalSavedListings -= 1
    },
    setIsLoadingSavedProperties: (state, action) => {
      state.isLoadingSavedProperties = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListingsDevs.pending, (state, action) => ({
      ...state,
      listings: [],
      page: action.meta.arg.page,
      isLoading: true,
    }))
    builder.addCase(getListingsDevs.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(getListingsDevs.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.listings,
      isLoading: false,
      total: action.payload.total,
      totalSavedListings: action.payload.totalListingsSaved,
      listingsSortSeed: action.meta.arg.seed,
    }))
    builder.addCase(getListingsFilters.rejected, (state) => ({
      ...state,
      isLoadingFilters: false,
    }))
    builder.addCase(getListingsFilters.fulfilled, (state, action) => ({
      ...state,
      filters: action.payload,
      isLoadingFilters: false,
    }))
    builder.addCase(loadMoreListingsDevs.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(loadMoreListingsDevs.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(loadMoreListingsDevs.fulfilled, (state, action) => ({
      ...state,
      listings: state.listings.concat(action.payload.listings),
      page: action.meta.arg.page,
      isLoading: false,
    }))
    builder.addCase(getSavedListings.pending, (state, action) => ({
      ...state,
      listings: [],
      page: action.meta.arg.page,
      isLoadingSavedProperties: true,
      isLoading: true,
    }))
    builder.addCase(getSavedListings.rejected, (state) => ({
      ...state,
      isLoadingSavedProperties: false,
      isLoading: false,
    }))
    builder.addCase(getSavedListings.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.listings,
      isLoadingSavedProperties: false,
      isLoading: false,
      total: action.payload.total,
      totalSavedListings: action.payload.totalListingsSaved,
    }))
    builder.addCase(loadMoreSavedListings.pending, (state) => ({
      ...state,
      isLoadingSavedProperties: true,
      isLoading: true,
    }))
    builder.addCase(loadMoreSavedListings.rejected, (state) => ({
      ...state,
      isLoadingSavedProperties: false,
      isLoading: false,
    }))
    builder.addCase(loadMoreSavedListings.fulfilled, (state, action) => ({
      ...state,
      listings: state.listings.concat(action.payload.listings),
      page: action.meta.arg.page,
      isLoadingSavedProperties: false,
      isLoading: false,
    }))
    builder.addCase(saveListing.fulfilled, (state, action) => {
      const mListing = state.listings.find((li) => li.listing?.id === action.meta.arg.listingID)
      if (mListing?.listing) {
        mListing.listing.has_user_saved_listing = true
        state.totalSavedListings += 1
      }
    })
    builder.addCase(removeSavedListing.fulfilled, (state, action) => {
      state.listings = handleRemoveSavedListing(
        state.listings,
        action.meta.arg.listingID,
        Boolean(action.meta.arg.isSavedProperties),
      )
      state.totalSavedListings -= 1
    })
    builder.addCase(getAllSavedListings.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.listings,
      total: action.payload.total,
      totalSavedListings: action.payload.totalListingsSaved,
      isLoading: false,
    }))
    builder.addCase(getAllSavedListings.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getAllSavedListings.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(saveListingDevelopment.fulfilled, (state, action) => {
      state.listings.find(
        (li) => li.development?.id === action.meta.arg.developmentID,
      ).development.has_user_saved_listing = true
      state.totalSavedListings += 1
    })
    builder.addCase(removeSavedListingDevelopment.fulfilled, (state, action) => {
      state.listings = handleRemoveSavedDevelopment(
        state.listings,
        action.meta.arg.developmentID,
        Boolean(action.meta.arg.isSavedProperties),
      )
      state.totalSavedListings -= 1
    })
  },
})

export const {
  resetListings,
  setIsLoadingFiltersFinished,
  setIsPhoneNumberModalOpen,
  setTotalSavedListings,
  setSelectedID,
  setSavedDevelopment,
  setRemoveSavedDevelopment,
  setIsLoadingSavedProperties,
} = propertyListingSlice.actions

export default propertyListingSlice.reducer
