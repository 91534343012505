import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MarketplaceListing } from 'src/types/PropertyListing'
import { getListingsDevs } from 'src/api/ggAPI'

interface InitialState {
  listings: MarketplaceListing[]
  listingsSeed?: number
  isLoading?: boolean
}

export const initialState: InitialState = {
  listings: [],
  isLoading: true,
}

export interface GetCarouselListings {
  seed?: number
  size: number
  is_new_build?: string
  price_min?: string
  price_max?: string
  sort_field?: string
  sort_descending?: boolean
}

export const getCarouselListings = createAsyncThunk(
  'propertiesCarousel/getCarouselLisitngs',
  ({
    seed = 0,
    size = 12,
    is_new_build,
    price_min,
    price_max,
    sort_field,
    sort_descending,
  }: GetCarouselListings) => {
    return getListingsDevs({
      offset: 0,
      size,
      seed,
      is_new_build,
      price_min,
      price_max,
      sort_field,
      sort_descending,
    }).then((response) => ({
      listings: response?.marketplace_listings || [],
    }))
  },
)

export const propertiesCarouselSlice = createSlice({
  name: 'propertiesCarousel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCarouselListings.pending, (state, action) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getCarouselListings.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.listings,
      listingsSeed: action.meta.arg.seed,
      isLoading: false,
    }))
    builder.addCase(getCarouselListings.rejected, (state, action) => ({
      ...state,
      isLoading: false,
    }))
  },
})

export default propertiesCarouselSlice.reducer
