// max amount of properties allowed
export const MAX_PROPERTIES = 500

export const MAX_SHAREHOLDERS = 8

export const AuthCodeURL = `https://www.gov.uk/guidance/company-authentication-codes-for-online-filing#:~:text=We'll%20send%20your%20code,the%20code%20over%20the%20phone`

export const UTRNumberURL = `https://www.gov.uk/find-utr-number`

export const EMPTY_FIELD = null

export const NOT_REQUIRED = 'Not required'

export const maxContentWidth = '530px'

export const maxReviewRowWidth = '608px'

export const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const shareTypesTab = [
  { value: 'ORDINARY', label: 'Ordinary' },
  { value: 'PREFERRED', label: 'Preferred' },
  { value: 'OTHER', label: 'Other' },
]

export const propertyTypesList = [
  { label: 'Please select', value: EMPTY_FIELD },
  { label: 'Standard buy-to-let', value: 'standard_buy_to_let' },
  { label: 'HMO', value: 'hmo' },
  { label: 'Serviced accommodation', value: 'serviced_accomodation' },
  { label: 'Holiday-let/Air BNB', value: 'holiday_let' },
  { label: 'Multi-Unit-Freehold-Block (MUFB)', value: 'mufb' },
  { label: 'Buy-to-sell (e.g. flipping)', value: 'buy_to_sell' },
]

export const countryList = [
  { label: 'Please select', value: EMPTY_FIELD },
  { label: 'England', value: 'United Kingdom of Great Britain and Northern Ireland' },
  { label: 'Wales', value: 'Wales' },
  { label: 'Scotland', value: 'Scotland' },
]

export const mortgageTypeList = [
  { label: '2 Year Fixed', value: '2_year_fixed' },
  { label: '5 Year Fixed', value: '5_year_fixed' },
  { label: 'Variable rate', value: 'variable' },
]

export const COMPANY_STATUS_REQ_FIELDS = [
  'initial_company_details',
  'payment_details',
  'user_id_check',
  'company_details',
  'property_details',
  'accountant_details',
]
