import { useRef, useEffect } from 'react'
import Big from 'big.js'
import isEqual from 'lodash/isEqual'
import transform from 'lodash/transform'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import querystring, { ParsedQuery } from 'query-string'

import { stripCurrency } from 'src/utils/_functions'
import BooleanString from 'src/types/BooleanString'
import BrowsePropertyQuery from 'src/types/BrowsePropertyQuery'
import {
  DEPOSITS,
  PRICE_RANGE,
  sortMapping,
} from 'src/components-pages/BrowseProperty/Filters/SelectOptions'
import Filter from 'src/types/ListingFilter'
import { Investment } from 'src/types/investments'
import { getJwtToken } from './auth'
import { Address } from 'src/types/Address'
import { CompanyOverview } from 'src/types/Company'

export const showDupPos = (arr, minDups = 2) => {
  let result = []
  const positions = {}
  arr.forEach((value, pos) => {
    positions[value.toLowerCase()] = positions[value.toLowerCase()] || []
    positions[value.toLowerCase()].push(pos)
  })

  Object.keys(positions).forEach((value) => {
    const posArray = positions[value]
    if (posArray.length >= minDups) {
      result = result.concat(posArray)
    }
  })
  return result.sort()
}

export const getOneLineAddress = (address: Address, long = false) => {
  if (!address?.street) {
    return null
  }

  const { premise, street, posttown, postcode } = address
  return premise
    ? `${premise} ${street}, ${posttown}${long && postcode ? `, ${postcode}` : ''}`
    : `${street}, ${posttown}${long && postcode ? `, ${postcode}` : ''}`
}

export const getWholeAddress = (address: Address | undefined) => {
  if (!address?.street) {
    return null
  }
  const { premise, street, thoroughfare, posttown, postcode, country } = address
  return `${premise}, ${street}, ${thoroughfare ? `${thoroughfare},` : ''} ${
    posttown ? `${posttown},` : ''
  } ${postcode ? `${postcode},` : ''} ${country}`
}

export const setCurrentPP = (propertyID) => {
  sessionStorage.setItem('propertyID', propertyID)
}

export const getOrdinal = (n, capitalise = false) =>
  capitalise
    ? ['Zeroth', 'First', 'Second', 'Third', 'Forth', 'Fifth', 'Sixth', 'Seventh', 'Eighth'][n]
    : ['zeroth', 'first', 'second', 'third', 'forth', 'fifth', 'sixth', 'seventh', 'eighth'][n]

/* Get ordinal letters 1st, 2nd, 3rd, 4th, ... */
// ref: https://github.com/dcousens/ordinal.js
export const getOrdinalWithNumber = (i: number) => {
  i = Math.abs(i)
  const cent = i % 100
  if (cent >= 10 && cent <= 20) return `${i}th`
  const dec = i % 10
  if (dec === 1) return `${i}st`
  if (dec === 2) return `${i}nd`
  if (dec === 3) return `${i}rd`
  return `${i}th`
}

export const parseBoolean = (value: BooleanString): boolean =>
  String(value).toLowerCase() === 'true'

export const booleanToString = (value: boolean): string => (value ? 'YES' : 'NO')

export const getAccountancyDefaultValue = (field: boolean | null = null): string =>
  field ? 'true' : field !== null ? 'false' : ''

export const cleanSessionStorage = (): void => {
  if (sessionStorage.getItem('companyID')) {
    sessionStorage.removeItem('companyID')
  }
  if (sessionStorage.getItem('propertyID')) {
    sessionStorage.removeItem('propertyID')
  }
  if (sessionStorage.getItem('step')) {
    sessionStorage.removeItem('step')
  }
  if (sessionStorage.getItem('share-transfer-step')) {
    sessionStorage.removeItem('share-transfer-step')
  }
  if (sessionStorage.getItem('GG_KEY_URI')) {
    sessionStorage.removeItem('GG_KEY_URI')
  }
  if (sessionStorage.getItem('SMSlast4Digits')) {
    sessionStorage.removeItem('SMSlast4Digits')
  }
  if (sessionStorage.getItem('request')) {
    sessionStorage.removeItem('request')
  }

  if (sessionStorage.getItem('mortgageData')) {
    sessionStorage.removeItem('mortgageData')
  }
  if (sessionStorage.getItem('mortgageStep')) {
    sessionStorage.removeItem('mortgageStep')
  }
}

export const penniesToPounds = (amount: string) => {
  return Number(Big(Number(stripCurrency(amount))).div(100))
}

export const formatSortcode = (value: string) => {
  const res = value?.match(/.{1,2}/g) || false
  if (res) {
    return [res[0], '-', res[1], '-', res[2]].join('')
  }
}

interface PPProgress {
  property_address_status: string
  purchase_details_status: string
  shareholder_details_status: string
  tax_questions_status: string
  payment_status: string
  solicitor_details_status: string
  property_agent_status: string
  overall_status: string
}

export const pathToDirectTo = (
  ppProgress: PPProgress,
  nextPageUrl: string,
  isHoldingCo = false,
) => {
  if (!ppProgress) return nextPageUrl
  const progressKeys = Object.keys(ppProgress)
  const key = progressKeys.filter((step: string) => {
    if (['overall_status', 'payment_status'].includes(step)) {
      return false
    }
    return ppProgress[step] === 'NOT_STARTED' || ppProgress[step] === 'INCOMPLETE'
  })
  return key.length ? nextPageUrl : isHoldingCo ? '/holdingco/review' : '/company-design/review'
}

export const composeListingsFiltersQuery = (values: BrowsePropertyQuery) => {
  if (!values) {
    return ''
  }

  let query = ''
  const {
    price_min,
    price_max,
    deposit_min,
    deposit_max,
    is_featured,
    is_randomised,
    is_new_build,
    development,
    has_user_saved_listing,
    locations,
    completion_date,
  } = values

  if (price_min) query += `price_min=${price_min}`

  if (price_max) query += `&price_max=${price_max}`

  if (deposit_min) query += `&deposit_min=${deposit_min}`

  if (deposit_max) query += `&deposit_max=${deposit_max}`

  if (locations) query += `&locations=${locations}`

  if (is_featured) query += `&is_featured=${is_featured}`

  if (is_randomised) query += `&is_randomised=${is_randomised}`

  if (is_new_build != null) {
    query += `&is_new_build=${is_new_build}`
  }

  if (development) {
    query += `&development_id=${development}`
  }

  if (has_user_saved_listing) query += '&saved_listings=true'

  if (completion_date) query += `&completion_date=${completion_date}`

  return query[0] === '&' ? query.substring(1) : query
}

export const getPropertyListingQueryString = (params: ParsedQuery<string>, pathname: string) => {
  const {
    price_min,
    price_max,
    deposit_max,
    locations,
    is_new_build,
    sort_field,
    development,
    completion_date,
  } = params

  const query = querystring.stringify({
    price_min: price_min || undefined,
    price_max: price_max || undefined,
    deposit_max: deposit_max || undefined,
    locations: locations || undefined,
    completion_date: completion_date || undefined,
    is_new_build: is_new_build || undefined,
    sort_field: sort_field || undefined,
    development: development || undefined,
  })

  return query ? `?${query}` : pathname
}

const isParamNotInRange = (value: string | null, minValue: number, maxValue: number) => {
  return Number(value) < minValue || Number(value) > maxValue || Number.isNaN(Number(value))
}

export const getPropertyListingFilteredQueryString = ({
  params,
  filters = [],
  pathname,
}: {
  pathname: string
  params: ParsedQuery<string>
  filters?: Filter[]
}) => {
  const developments = filters.find((o) => o.label === 'Development')

  const { price_min, price_max, deposit_max, sort_field, is_new_build, development } = params
  const newParams = { ...params }

  // don't allow manualy setting just one value in url
  if ((price_min && !price_max) || (!price_min && price_max)) {
    newParams.price_min = null
    newParams.price_max = null
  }
  if (price_min && isParamNotInRange(price_min, PRICE_RANGE.MIN, PRICE_RANGE.MAX)) {
    newParams.price_min = null
    newParams.price_max = null
  }
  if (price_max && isParamNotInRange(price_max, PRICE_RANGE.MIN, PRICE_RANGE.MAX)) {
    newParams.price_min = null
    newParams.price_max = null
  }

  if (deposit_max && isParamNotInRange(deposit_max, DEPOSITS.MIN, DEPOSITS.MAX))
    newParams.deposit_max = null

  if (sort_field) {
    if (!Object.keys(sortMapping).some((o) => o === sort_field)) {
      newParams.sort_field = null
    }
  }

  if (is_new_build && is_new_build !== 'true' && is_new_build !== 'false')
    newParams.is_new_build = null

  if (development && !developments?.values.some((o) => o.value === development))
    newParams.development = null

  return getPropertyListingQueryString(newParams, pathname)
}

export const getDifferencesInObjects = (origObj, newObj) => {
  function getChanges(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] =
          isObject(value) && isObject(origObj[key]) ? getChanges(value, origObj[key]) : value
      }
    })
  }
  return getChanges(newObj, origObj)
}

export function shortenCurrency(num: number, digits = 0) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })

  return item ? (num / item.value).toFixed(digits).replace(rx, '£1') + item.symbol : '0'
}

type Storage = 'localStorage' | 'sessionStorage'

export const getItemFromStorage = (key: string, storage: Storage = 'localStorage') => {
  const item = window[storage].getItem(key)
  let result = null
  try {
    result = item ? JSON.parse(item) : null
  } catch {
    result = item
  }
  return result
}

export const setItemInStorage = <T>(name: string, data: T, storage: Storage = 'localStorage') => {
  window[storage].setItem(name, JSON.stringify(data))
}

/**
 * Gets completion dates from 2 sources (property purchase state and investments)
 * and returns the nearest completion date compared to today.
 */
export function getNearestCompletionDate(
  propertyPurchaseValue: string | null,
  investments: Investment[],
) {
  const datesToCompare = []

  if (propertyPurchaseValue) datesToCompare.push(new Date(propertyPurchaseValue).getTime())

  if (investments.length > 0) {
    const investmentDates = investments.map((investment) => {
      const invesmentCompletionDate = investment.property?.purchase_details?.completion_date
      if (invesmentCompletionDate) return new Date(invesmentCompletionDate).getTime()
      return 0
    })
    const nonZeroInvestmentDates = investmentDates.filter((date) => date !== 0)
    datesToCompare.push(...nonZeroInvestmentDates)
  }

  const target = Date.now()

  let minDistance = Infinity
  let nearestIndex = -1

  datesToCompare.forEach((date, index) => {
    const distance = date - target
    // Dates before today (target) are not considered
    if (distance < minDistance && distance > 0) {
      minDistance = distance
      nearestIndex = index
    }
  })

  return datesToCompare[nearestIndex]
}

export function formatUtmParams(
  source?: string,
  medium?: string,
  campaign?: string,
  content?: string,
  term?: string,
): string {
  const searchParams = new URLSearchParams()
  if (source) searchParams.append('utm_source', source)
  if (medium) searchParams.append('utm_medium', medium)
  if (campaign) searchParams.append('utm_campaign', campaign)
  if (content) searchParams.append('utm_content', content)
  if (term) searchParams.append('utm_term', term)
  return searchParams.toString()
}

export const EMAIL_REGEX =
  /^[A-Za-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[A-Za-z0-9!#$%&*+/=?^_{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/i

export function isValidEmail(email: string): boolean {
  return EMAIL_REGEX.test(email)
}

export const formatBPSPercentageValue = (value: number) => {
  if (!value) {
    return null
  }

  const percentage = Number.isInteger(value) ? value / 100 : (Number(value) / 100).toFixed(2)
  return percentage.toString().includes('.00')
    ? `${percentage.toString().replace('.00', '')}%`
    : `${percentage}%`
}

export const formatPostcode = (value: string) => {
  const valueWithoutSpaces = value.replace(/ /g, '')
  return valueWithoutSpaces.slice(0, -3) + ' ' + value.slice(-3)
}

const shouldThrottleSession = () => !!getJwtToken() || Math.random() <= 0.05 // Throttle 95% of unauthenticated sessions

export const initializeLogRocket = () => {
  if (import.meta.env.MODE === 'production') {
    const urlsToSanitize = ['/token', '/auth/reset_password', '/overview', '/users']
    // Check if the session should be throttled before starting LogRocket
    if (shouldThrottleSession()) {
      LogRocket.init('r0bmjv/gg-customer-staging', {
        network: {
          requestSanitizer: (request) => {
            if (request.headers.Authorization) {
              request.headers.Authorization = ''
            }
            if (urlsToSanitize.find((item) => request.url.toLowerCase().includes(item))) {
              // Scrub out the body
              request.body = null
            }
            return request
          },
          responseSanitizer: (response) => {
            if (urlsToSanitize.find((item) => response.url.toLowerCase().includes(item))) {
              // Scrub out the body
              response.body = null
            }
            return response
          },
        },
      })
      setupLogRocketReact(LogRocket)
    }
  }
}

interface UserProperties {
  [key: string]: any
}

export const identifyWithThrottle = (userId: string, properties: UserProperties): void => {
  try {
    LogRocket.identify(userId, properties)
  } catch (error) {
    console.error('Error identifying user with LogRocket:', error)
  }
}

export const pluralize = (count: number, singular: string, plural: string): string => {
  return `${count} ${count === 1 ? singular : plural}`
}

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isRequestChangeAvailable = (company: CompanyOverview) =>
  Boolean(company.date_of_incorporation) &&
  !company.property_details_change_request_in_progress &&
  !company.change_request_in_progress &&
  !company.subsidiary_companies?.some((subsidiary) => subsidiary.status !== 'COMPLETE') &&
  !company.holding_company_details &&
  !company.is_stock

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function fuzzyMatch(pattern: string, str: string) {
  pattern =
    '.*' +
    pattern
      .split('')
      .map((l) => `${escapeRegExp(l)}.*`)
      .join('')
  const re = new RegExp(pattern, 'i')
  return re.test(str)
}
