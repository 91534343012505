import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  getInvestment as apiGetInvestment,
  deleteProduct as apiDeleteProduct,
  getCapitalGrowthByPostcode as getCapitalGrowth,
} from 'src/api/ggAPI'
import { PRODUCT_TYPES } from 'src/config/constants'
import CapitalGrowth from 'src/types/CapitalGrowth'
import type { Investment } from 'src/types/investments'

export const getInvestment = createAsyncThunk(
  'investments/getInvestment',
  ({ investmentID }: { investmentID: number }) => apiGetInvestment({ investmentID }),
)

export const deleteCompanyProduct = createAsyncThunk(
  'investments/deleteCompanyProduct',
  ({ productID }, { rejectWithValue }) =>
    apiDeleteProduct({ productID, type: PRODUCT_TYPES.COMPANY }).catch(
      ({
        response: {
          data: { error_message },
          status,
        },
      }) => {
        return rejectWithValue({ code: status, message: error_message })
      },
    ),
)

export const getCapitalGrowthByPostcode = createAsyncThunk(
  'investments/getCapitalGrowthByPostcode',
  (postcode: string) => getCapitalGrowth(postcode),
)
interface InvestmentState {
  investment: Investment
  isLoading: boolean
  isNameUpdateLoading: boolean
  capitalGrowthData: Partial<CapitalGrowth>
  propertyDetailsDraft: any
  propertyAgentDraft: any
  filteredCapitalGrowthData: CapitalGrowth['data']
  selectedTimeFrame: number
  selectedTimeFrameUnit: 'years' | 'months'
  capitalGrowthPercentage: number
}

export const initialState: InvestmentState = {
  investment: {} as Investment,
  isLoading: true,
  isNameUpdateLoading: false,
  propertyDetailsDraft: null,
  propertyAgentDraft: null,
  capitalGrowthData: {},
  filteredCapitalGrowthData: [],
  selectedTimeFrame: 5,
  selectedTimeFrameUnit: 'years',
  capitalGrowthPercentage: 0,
}

const investmentSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {
    setPropertyDetailsDraft: (state, action) => ({
      ...state,
      propertyDetailsDraft: action.payload,
    }),
    setPropertyAgentDraft: (state, action) => ({
      ...state,
      propertyAgentDraft: action.payload,
    }),
    resetInvestmentData: () => ({
      ...initialState,
    }),
    setFilteredCapitalGrowthData: (state, action) => ({
      ...state,
      filteredCapitalGrowthData: action.payload,
    }),
    setCapitalGrowthHeaderData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getInvestment.fulfilled, (state, action) => ({
      ...state,
      investment: action.payload,
      isLoading: false,
    }))
    builder.addCase(getInvestment.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(deleteCompanyProduct.fulfilled, (state) => {
      state.investment.company_product = null
    })
    builder.addCase(getCapitalGrowthByPostcode.fulfilled, (state, action) => {
      let capitalGrowthPercentage = initialState.capitalGrowthPercentage

      if (action.payload?.data?.length > 0) {
        const filteredData = action.payload.data.filter(
          (d) =>
            dayjs().startOf('month').diff(dayjs(d.date), state.selectedTimeFrameUnit) <
            state.selectedTimeFrame,
        )
        const firstData = filteredData[0]
        const lastData = filteredData.slice(-1)[0]

        capitalGrowthPercentage = Math.round(
          ((lastData.value - firstData.value) / firstData.value) * 100,
        )
      }

      return {
        ...state,
        capitalGrowthData: action.payload,
        capitalGrowthPercentage,
      }
    })
  },
})

export const {
  setPropertyDetailsDraft,
  setPropertyAgentDraft,
  resetInvestmentData,
  setFilteredCapitalGrowthData,
  setCapitalGrowthHeaderData,
} = investmentSlice.actions

export default investmentSlice.reducer
