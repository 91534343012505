import { useEffect } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

function Redirect({ to, shouldSuffixPathname }: { to: string; shouldSuffixPathname?: boolean }) {
  const params = useParams()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(generatePath(shouldSuffixPathname ? `${to}${pathname}` : to, params), {
      replace: true,
      state,
    })
  })

  return null
}

export default Redirect
