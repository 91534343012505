function formatWithQueryParams(url: string, queryParams?: Record<string, string>): string {
  return `${url}${
    queryParams
      ? `${url.includes('?') ? '&' : '?'}${Object.keys(queryParams)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&')}`
      : ''
  }`
}

function formatUrlWithParam(url: string, entityID?: string | number, subUrl?: string): string {
  return `${url}${entityID ? `/${entityID}` : ''}${subUrl ? `/${subUrl}` : ''}`
}

export function createCompanyUrl(queryParams?: Record<string, string>): string {
  return formatWithQueryParams('/create-company', queryParams)
}

export function dashboardUrl(queryParams?: Record<string, string>): string {
  return formatWithQueryParams('/dashboard', queryParams)
}

export function loginUrl(queryParams?: Record<string, string>): string {
  return formatWithQueryParams('/', queryParams)
}

/**
 * @param companyID ID of onboarding stock company
 * @returns onboarding page url
 */
export function stockCompanyOnboardingUrl(companyID: string | number): string {
  return formatUrlWithParam('/company-onboarding', companyID)
}

/**
 * @param companyID ID of onboarding stock company
 * @returns company details page url of onboarding company
 */
export function stockCompanyCompanyDetailUrl(companyID: string | number): string {
  return formatUrlWithParam('/company-onboarding', companyID, 'company-details')
}

/**
 * @param companyID ID of onboarding stock company
 * @returns payment details page url of onboarding company
 */
export function stockCompanyPaymentDetailUrl(companyID: string | number): string {
  return formatUrlWithParam('/company-onboarding', companyID, 'payment-details')
}

/**
 * @param companyID ID of onboarding stock company
 * @returns KYB page url
 */
export function stockCompanyKYBUrl(companyID: string | number): string {
  return formatUrlWithParam('/company-onboarding', companyID, 'know-your-business')
}

/**
 * @param companyID ID of onboarding stock company
 * @returns KYB Accountant details page url
 */
export function stockCompanyAccountantDetailsUrl(companyID: string | number): string {
  return formatUrlWithParam(
    '/company-onboarding',
    companyID,
    'know-your-business/accountant-details',
  )
}

/**
 * @param companyID ID of onboarding stock company
 * @returns KYB Company details page url
 */
export function stockCompanyDetailsUrl(companyID: string | number): string {
  return formatUrlWithParam('/company-onboarding', companyID, 'know-your-business/company-details')
}

/**
 * @param companyID ID of onboarding stock company
 * @returns KYB Property details page url
 */
export function stockCompanyPropertyDetailsUrl(
  companyID: string | number,
  propertyID: number,
): string {
  return formatUrlWithParam(
    '/company-onboarding',
    companyID,
    `know-your-business/property-details/${propertyID}`,
  )
}

export function accountsSubmissionIntroUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy')
}

/**
 * @param companyID ID of company accountancy submission
 * @returns Accounts submission property information page url
 */
export function accountsSubmissionPropertyInformationUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/property-information')
}

/**
 * @param companyID ID of company accountancy submission
 * @returns Accounts submission completion date page url
 */
export function accountsSubmissionCompletionDateUrl(companyID: string | number): string {
  return formatUrlWithParam(
    '/company',
    companyID,
    'accountancy/property-information/completion-date',
  )
}

/**
 * @param companyID ID of company accountancy submission
 * @returns Accounts submission property valuation page url
 */
export function accountsSubmissionPropertyValuationUrl(companyID: string | number): string {
  return formatUrlWithParam(
    '/company',
    companyID,
    'accountancy/property-information/property-valuation',
  )
}

export function accountsSubmissionCongratulationsUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/complete')
}

/**
 * @param companyID ID of company accountancy submission
 * @returns Accounts submission expenses page url
 */
export function accountsSubmissionExpensesUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/expenses')
}

/** Use only for stock company outside, use accountsSubmissionCashflowsUrl for start of the flow  */
export function accountsSubmissionCategoriseUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/cashflows')
}

/** Not exported intentionally to avoid being used, use this: accountsSubmissionCashflowsUrl */
function accountsSubmissionStockTransactionsUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/upload-transactions')
}

export function accountsSubmissionCashflowsUrl(
  companyID: string | number,
  isStock: boolean,
): string {
  return isStock
    ? accountsSubmissionStockTransactionsUrl(companyID)
    : accountsSubmissionCategoriseUrl(companyID)
}

/**
 * @param companyID ID of company accountancy submission
 * @returns Accounts submission tenants page url
 */
export function accountsSubmissionTenantsUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/property-information/tenants')
}

// Not exported on purpose because it's should be used from accountsSubmissionUploadDocumentsUrl
function flowAccountsSubmissionUploadDocumentsUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/upload-documents')
}

export function accountsSubmissionConfirmationUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/confirmation')
}

export function accountsSubmissionReviewUrl(companyID: string | number): string {
  return formatUrlWithParam('/company', companyID, 'accountancy/review')
}

/**
 * @param referralCode referralCode of the invite
 * @returns Referral invite page url
 */
export function referralCodeInviteUrl(referralCode: string): string {
  return formatUrlWithParam('/invite', referralCode)
}

/**
 * @returns Referral code page url
 */
export function referralCodePageUrl(): string {
  return formatUrlWithParam('/referral')
}

// The function to use only for accountancy of stock company and when previous accounting upload page is visible
// Not exported on purpose because it's should be used from accountsSubmissionUploadDocumentsUrl
function accountsSubmissionPropertyDocumentsUrl(companyID: string | number): string {
  return formatUrlWithParam(
    '/company',
    companyID,
    'accountancy/upload-documents/property-documents',
  )
}

export function accountsSubmissionUploadDocumentsUrl(
  companyID: string | number,
  hasPrevAccountingUploadsPage: boolean,
): string {
  return hasPrevAccountingUploadsPage
    ? accountsSubmissionPropertyDocumentsUrl(companyID)
    : flowAccountsSubmissionUploadDocumentsUrl(companyID)
}

export function accountsSubmissionUploadStockDocumentsUrl(companyID: string | number) {
  return formatUrlWithParam(
    '/company',
    companyID,
    'accountancy/upload-documents/upload-previous-documents',
  )
}

export function todayUrl() {
  return formatUrlWithParam('/today')
}

export function discoverUrl() {
  return formatUrlWithParam('/discover')
}

export function discoverCompanyServicesUrl() {
  return formatUrlWithParam('/discover/company-services')
}

export function discoverFinancialServicesUrl() {
  return formatUrlWithParam('/discover/financial-services')
}

export function discoverPropertyServicesUrl() {
  return formatUrlWithParam('/discover/property-services')
}

export function bookkeepingUrl() {
  return formatUrlWithParam('/bookkeeping')
}

export function profitLossUrl() {
  return formatUrlWithParam('/insights/profit-loss-analysis')
}

export function portfolioCompany(companyID: string | number) {
  return formatUrlWithParam('/portfolio/companies', companyID)
}

export function portfolioCompanySettings(companyID: string | number) {
  return formatUrlWithParam('/portfolio/companies', companyID, 'settings')
}

export function portfolioCompanyDocuments(companyID: string | number) {
  return formatUrlWithParam('/portfolio/companies', companyID, 'documents')
}

export function portfolioCompanyBilling(companyID: string | number) {
  return formatUrlWithParam('/portfolio/companies', companyID, 'billing')
}

export function portfolioTransactions() {
  return formatUrlWithParam('/portfolio/transactions')
}

export function portfolioCompanies() {
  return formatUrlWithParam('/portfolio/companies')
}

export function portfolioCompaniesAllInProgress() {
  return formatUrlWithParam('/portfolio/companies/in-progress')
}

export function portfolioProperties() {
  return formatUrlWithParam('/portfolio/properties')
}

export function portfolioProperty(ppID: string | number) {
  return formatUrlWithParam('/portfolio/properties', ppID)
}

export function portfolioPropertyCompletion(ppID: string | number) {
  return formatUrlWithParam('/portfolio/properties', ppID, 'completion')
}

export function portfolioRentTransactions() {
  return formatUrlWithParam('/portfolio/rent-tracker')
}

export function productMortgagesUrl() {
  return formatUrlWithParam('/products/mortgages')
}

export function portfolioCompanyProduct(companyProductID: string | number) {
  return formatUrlWithParam('/portfolio/company_setup', companyProductID)
}

export function portfolioUrl(queryParams?: Record<string, string>): string {
  return formatWithQueryParams('/portfolio', queryParams)
}

export function portfolioTransactionUrl(queryParams?: Record<string, string>): string {
  return formatWithQueryParams('/portfolio/transactions', queryParams)
}

export function portfolioWallet(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId)
}

export function portfolioWalletTransaction(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'transactions')
}

export function portfolioWalletAddMoney(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'add-money')
}

export function portfolioWalletAddUKMoney(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'add-money/uk-account')
}

export function portfolioWalletAddInternationalMoney(
  walletId: string | number,
  params?: { currency: string },
) {
  return formatUrlWithParam(
    '/portfolio/wallets',
    walletId,
    formatWithQueryParams('add-money/international-account', params),
  )
}

export function portfolioWalletSendMoney(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'payment')
}

export function browsePropertyUrl() {
  return '/discover/browse-property'
}

export function portfolioWalletAccountDetails(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'account-details')
}

export function portfolioWalletRegularPayments(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'regular-payments/all')
}

export function portfolioPotAddMoneyUrl(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'pot/add-money')
}

export function portfolioPotActivityUrl(walletId: string | number) {
  return formatUrlWithParam('/portfolio/wallets', walletId, 'pot/activity')
}

/* User on-boarding - start */
export function userChoose2FAUrl() {
  return formatUrlWithParam('/onboarding/choose-2FA')
}

export function userOnboardingUrl() {
  return formatUrlWithParam('/onboarding/personal-details')
}

/* User on-boarding - end */

export function landlordWalletOnboardingUrl() {
  return formatUrlWithParam('/landlord-onboarding')
}

export function landlordWalletCheckEligibilityUrl() {
  return formatUrlWithParam('/landlord-onboarding/eligibility-check')
}

export function landlordWalletOnboardingPropertyOwnership(id: string | number) {
  return formatUrlWithParam('/landlord-onboarding', id, 'property-ownership')
}

export function landlordWalletOnboardingPropertyAddress(id: string | number) {
  return formatUrlWithParam('/landlord-onboarding', id, 'property-address')
}

export function landlordWalletOnboardingPropertyPurchase(id: string | number) {
  return formatUrlWithParam('/landlord-onboarding', id, 'property-purchase')
}

export function landlordWalletOnboardingPropertyReview(id: string | number) {
  return formatUrlWithParam('/landlord-onboarding', id, 'property-review')
}

export function portfolioAddUrl() {
  return '/portfolio/add'
}

export function portfolioAddCompanyUrl() {
  return '/portfolio/add/company'
}

export function portfolioAddPropertyUrl() {
  return '/portfolio/add/property'
}

export function portfolioPropertyOnboarding({
  onboardingID,
  propertyID,
}: {
  onboardingID: string | number
  propertyID: string | number
}) {
  return `/portfolio/property-onboarding/${onboardingID}/property-details/${propertyID}`
}

export function multiPropertyOverviewUrl(id: number) {
  return formatUrlWithParam('/portfolio/property-onboarding', id)
}

export function multiPropertyPaymentUrl(id: number) {
  return formatUrlWithParam('/portfolio/property-onboarding', id, 'payment')
}

export function multiPropertyLegalSignUrl(id: number) {
  return formatUrlWithParam('/portfolio/property-onboarding', id, 'document')
}

export function setupSignatureUrl() {
  return '/account/setup-signature'
}

export function propertyRequesChangeUrl(id: number) {
  return formatUrlWithParam('/investments', id, 'property-request-change')
}

/* Packages Services Start */
export function packagesCompanyUrl() {
  return '/discover/company'
}

export function packagesStockCompanyUrl() {
  return '/discover/existing-company'
}

export function packagesMortgageUrl() {
  return '/discover/mortgage'
}

export function packagesLettingAndManagementUrl() {
  return '/discover/lettings-and-management'
}

export function packagesPersonalLandlordUrl() {
  return '/discover/landlord'
}

export function packagesCompanyCheckoutUrl() {
  return '/discover/company-checkout'
}
/* Packages Services End */

export function companyDesignReviewUrl() {
  return formatUrlWithParam('/company-design/review')
}

export function companyDesignFeedback() {
  return formatUrlWithParam('/company-design/feedback')
}

export function holdcoDesignFeedback() {
  return formatUrlWithParam('/holdingco/feedback')
}

export function companyDesignCongratulations() {
  return formatUrlWithParam('/congratulations')
}
