import { updatePropertyPurchase } from '../slices/propertyPurchase.slice'
import { updateUser } from '../slices/user.slice'

import { showLoader, hideLoader } from '../slices/loader.slice'
import {
  fetchAllChangeRequestDocuments,
  fetchAllDocuments,
  fetchDocument,
  signDocument,
} from '../slices/documentsToSign.slice'
import { getNotificationCount } from '../slices/inbox.slice'
import {
  getAllListings,
  getFilteredListings,
  getListingInvestmentsCalculation,
} from '../slices/listingProperties.slice'
import { getAreaGuides } from '../slices/propertyReports.slice'

const loaderMiddleware = (store) => (next) => (action) => {
  if (action) {
    const { dispatch } = store
    switch (action.type) {
      case updatePropertyPurchase.pending.type:
      case updateUser.pending.type:
      case fetchDocument.pending.type:
      case fetchAllDocuments.pending.type:
      case fetchAllChangeRequestDocuments.pending.type:
      case signDocument.pending.type:
      case getAllListings.pending.type:
      case getFilteredListings.pending.type:
      case getListingInvestmentsCalculation.pending.type:
      case getAreaGuides.pending.type:
        next(action)
        dispatch(showLoader())
        break
      case updatePropertyPurchase.fulfilled.type:
      case updateUser.fulfilled.type:
      case updateUser.rejected.type:
      case fetchDocument.fulfilled.type:
      case fetchDocument.rejected.type:
      case fetchAllDocuments.fulfilled.type:
      case fetchAllDocuments.rejected.type:
      case fetchAllChangeRequestDocuments.fulfilled.type:
      case fetchAllChangeRequestDocuments.rejected.type:
      case signDocument.fulfilled.type:
      case signDocument.rejected.type:
      case getNotificationCount.fulfilled.type:
      case getAllListings.fulfilled.type:
      case getAllListings.rejected.type:
      case getFilteredListings.fulfilled.type:
      case getListingInvestmentsCalculation.fulfilled.type:
      case getListingInvestmentsCalculation.rejected.type:
      case getAreaGuides.fulfilled.type:
      case getAreaGuides.rejected.type:
        next(action)
        dispatch(hideLoader())
        break
      default:
        next(action)
    }
  }
}

export default loaderMiddleware
