import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ParsedQuery } from 'query-string'
import Big from 'big.js'
import {
  getSinglePropertyListing as apiGetSinglePropertyListing,
  getListingInvestmentsCalculation as apiGetListingInvestmentsCalculation,
  getOtherPropertyListings as apiGetOtherPropertyListings,
  saveListing as apiSaveListing,
  removeSavedListing as apiRemoveSavedListing,
  getListings as apiGetListings,
  getListingMeetTeam as apiGetListingMeetTeam,
} from 'src/api/ggAPI'
import ListingInvestmentsCalculationQuery from 'src/types/ListingInvestmentsCalculationQuery'
import MeetTeamData from 'src/types/MeetTeamData'
import { ListingProperty } from 'src/types/ListingProperty'
import PropertyListing from 'src/types/PropertyListing'
import { getListingsDevsParams } from './propertyListing.slice'

interface InitialState {
  listings: PropertyListing[]
  page: number
  isLoading: boolean
  totalListings: number
  listingDetails: ListingProperty
  investmentsCalculation: {
    total_return_in_cents: number
    total_investment_in_cents: number
    annual_return_percentage: number
    gross_yield_percentage: number
    first_year_rental_income_pre_tax_in_cents: number
    reservation_fee_in_cents: number
    stamp_duty_in_cents: number
    mortgage_arrangement_fees_in_cents: number
    valuation_fee_in_cents: number
    furniture_pack_cost_in_cents: number
    legal_fees_in_cents: number
    additional_gg_fees_in_cents: number
    mortgage_broker_fee_in_cents: number
    deposit_settlement_in_cents: number
    deposit_in_cents?: number
  }
  investmentsCalculationParams: {
    time_period_in_years: number
    starting_ltv_percentage_in_bps: number
    price_in_cents: number
    rental_income_in_cents: number
    rental_growth_forecast_in_bps: number
    capital_growth_forecast_in_bps: number
    has_updated_params?: boolean
  }
  otherListings: {
    list: PropertyListing[]
    total: number
    isLoading: boolean
  }
  meetListings: {
    teamList: MeetTeamData[]
    isLoading: boolean
  }
}

export const getPropertyListingDetails = createAsyncThunk(
  'listingDetails/getPropertyListingDetails',
  ({ listingID, shareToken }: { listingID: string; shareToken: string }, { rejectWithValue }) =>
    apiGetSinglePropertyListing({ listingID, shareToken }).catch(({ response: { status } }) =>
      rejectWithValue({ code: status }),
    ),
)

export const getListingInvestmentsCalculation = createAsyncThunk(
  'listingDetails/getListingInvestmentsCalculation',
  ({ listingID, params }: { listingID: number; params: ListingInvestmentsCalculationQuery }) =>
    apiGetListingInvestmentsCalculation({ listingID, params }),
)

export const getOtherPropertyListings = createAsyncThunk(
  'listingDetails/getOtherPropertyListings',
  apiGetOtherPropertyListings,
)

export const getListingMeetTeam = createAsyncThunk(
  'listingDetails/getListingMeetTeam',
  apiGetListingMeetTeam,
)

export const saveListing = createAsyncThunk(
  'listingDetails/saveListing',
  ({ listingID }: { listingID: number; isSingleListing?: boolean; isEnquiryPage?: boolean }) =>
    apiSaveListing({ listingID }),
)

export const removeSavedListing = createAsyncThunk(
  'listingDetails/removeSavedListing',
  ({ listingID }: { listingID: number }) => {
    return apiRemoveSavedListing({ listingID })
  },
)

export const getListings = createAsyncThunk(
  'listingDetails/getListings',
  ({
    page,
    params,
    saved_listings,
  }: {
    page: number
    params: ParsedQuery<string>
    saved_listings?: boolean
  }) => {
    const listingsParams = getListingsDevsParams(page, params)
    return apiGetListings({ ...listingsParams, saved_listings }).then((response) => ({
      listings: response?.data,
      totalListingsSaved: response?.count_saved ?? 0,
      total: response?.total ?? 0,
    }))
  },
)

export const loadMoreListings = createAsyncThunk(
  'listingDetails/loadMoreListings',
  ({
    page,
    params,
    saved_listings,
  }: {
    page: number
    params: ParsedQuery<string>
    saved_listings?: boolean
  }) => {
    const listingsParams = getListingsDevsParams(page, params)
    return apiGetListings({ ...listingsParams, saved_listings }).then((response) => ({
      listings: response.data,
      total: response?.total ?? 0,
    }))
  },
)

export const initialState: InitialState = {
  isLoading: true,
  listingDetails: {},
  investmentsCalculation: {
    total_return_in_cents: 0,
    total_investment_in_cents: 0,
    annual_return_percentage: 0,
    gross_yield_percentage: 0,
    first_year_rental_income_pre_tax_in_cents: 0,
    reservation_fee_in_cents: 0,
    stamp_duty_in_cents: 0,
    mortgage_arrangement_fees_in_cents: 0,
    valuation_fee_in_cents: 0,
    furniture_pack_cost_in_cents: 0,
    legal_fees_in_cents: 0,
    additional_gg_fees_in_cents: 0,
    mortgage_broker_fee_in_cents: 0,
    deposit_settlement_in_cents: 0,
  },
  investmentsCalculationParams: {
    time_period_in_years: 10,
    starting_ltv_percentage_in_bps: 0,
    price_in_cents: 0,
    rental_income_in_cents: 0,
    rental_growth_forecast_in_bps: 0,
    capital_growth_forecast_in_bps: 0,
    has_updated_params: false,
  },
  otherListings: {
    list: [],
    total: 0,
    isLoading: true,
  },
  listings: [],
  meetListings: {
    teamList: [],
    isLoading: false,
  },
  totalListings: 0,
  page: 0,
}

const listingDetailsSlice = createSlice({
  name: 'listingDetails',
  initialState,
  reducers: {
    clearInvestmentsCalculation: (state) => ({
      ...state,
      investmentsCalculation: initialState.investmentsCalculation,
    }),
    setInvestmentsCalculationParams: (state, action) => ({
      ...state,
      investmentsCalculationParams: action.payload,
    }),
    clearInvestmentsCalculationParams: (state) => ({
      ...state,
      investmentsCalculationParams: initialState.investmentsCalculationParams,
    }),
    unsetOtherListings: (state) => ({
      ...state,
      otherListings: { isLoading: false, total: 0, list: [] },
    }),
    clearListingDetails: (state) => ({
      ...initialState,
      listings: state.listings,
      totalListings: state.totalListings,
      page: state.page,
    }),
    clearPrevNextNavigationListings: () => ({
      ...initialState,
    }),
    setPropertyListingDetails: (state, action) => ({
      ...state,
      listingDetails: action.payload,
    }),
    setPropertyUserRequestedContact: (state, action) => ({
      ...state,
      listingDetails: {
        ...state.listingDetails,
        has_user_requested_contact: action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyListingDetails.fulfilled, (state, action) => {
        state.listingDetails = action.payload
        state.isLoading = false
      })
      .addCase(getListingInvestmentsCalculation.fulfilled, (state, action) => {
        const {
          income: { gross_yield_percentage },
          property_analysis: {
            irr,
            return_on_investment_percentage,
            total_investment_in_cents,
            first_year_rental_income_pre_tax_in_cents,
          },
          payments: {
            reservation_fee_in_cents,
            stamp_duty_in_cents,
            mortgage_arrangement_fees_in_cents,
            valuation_fee_in_cents,
            furniture_pack_cost_in_cents,
            legal_fees_in_cents,
            additional_gg_fees_in_cents,
            mortgage_broker_fee_in_cents,
            deposit_settlement_in_cents,
            deposit_in_cents,
          },
        } = action.payload

        return {
          ...state,
          investmentsCalculation: {
            total_return_in_cents:
              new Big(total_investment_in_cents * return_on_investment_percentage).toNumber() +
              total_investment_in_cents,
            total_investment_in_cents,
            annual_return_percentage: irr,
            gross_yield_percentage,
            first_year_rental_income_pre_tax_in_cents,
            reservation_fee_in_cents,
            stamp_duty_in_cents,
            mortgage_arrangement_fees_in_cents,
            valuation_fee_in_cents,
            furniture_pack_cost_in_cents,
            legal_fees_in_cents,
            additional_gg_fees_in_cents,
            mortgage_broker_fee_in_cents,
            deposit_settlement_in_cents,
            deposit_in_cents,
          },
        }
      })
      .addCase(getOtherPropertyListings.fulfilled, (state, action) => {
        const { listings, total } = action.payload
        return {
          ...state,
          otherListings: {
            list: listings,
            total,
            isLoading: false,
          },
        }
      })
      .addCase(saveListing.fulfilled, (state) => ({
        ...state,
        listingDetails: {
          ...state.listingDetails,
          has_user_saved_listing: true,
        },
      }))
      .addCase(removeSavedListing.fulfilled, (state) => ({
        ...state,
        listingDetails: {
          ...state.listingDetails,
          has_user_saved_listing: false,
        },
      }))
      .addCase(getListings.pending, (state, action) => ({
        ...state,
        listings: [],
        page: action.meta.arg.page,
      }))
      .addCase(getListings.fulfilled, (state, action) => ({
        ...state,
        listings: action.payload.listings,
        totalListings: action.payload.total,
      }))
      .addCase(loadMoreListings.fulfilled, (state, action) => ({
        ...state,
        listings: state.listings.concat(action.payload.listings),
        page: action.meta.arg.page,
        totalListings: action.payload.total,
      }))
      .addCase(getListingMeetTeam.pending, (state) => ({
        ...state,
        meetListings: {
          ...state.meetListings,
          teamList: [],
          isLoading: true,
        },
      }))
      .addCase(getListingMeetTeam.fulfilled, (state, action) => ({
        ...state,
        meetListings: {
          ...state.meetListings,
          teamList: action.payload,
          isLoading: false,
        },
      }))
  },
})

export const {
  clearInvestmentsCalculation,
  setInvestmentsCalculationParams,
  clearInvestmentsCalculationParams,
  unsetOtherListings,
  clearListingDetails,
  clearPrevNextNavigationListings,
  setPropertyListingDetails,
  setPropertyUserRequestedContact,
} = listingDetailsSlice.actions

export default listingDetailsSlice.reducer
