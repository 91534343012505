import { enqueueSnackbar } from 'notistack'

export const DEFAULT_DURATION = 3_000 // 3s

export const showSuccessToast = (msg: string, duration?: number) =>
  enqueueSnackbar(msg, {
    variant: 'success',
    autoHideDuration: duration ?? DEFAULT_DURATION,
  })

export const showWarningToast = (msg: string) => enqueueSnackbar(msg, { variant: 'warning' })

export const showInfoToast = (msg: string) => enqueueSnackbar(msg, { variant: 'info' })

export const showErrorToast = (msg: string) => enqueueSnackbar(msg, { variant: 'error' })
