import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import difference from 'lodash/difference'

import Request from 'src/components-pages/Company/RequestChange/Request'
import { logout } from './user.slice'

const stateInSessionStorage =
  typeof window !== 'undefined' ? sessionStorage.getItem('request') : null

export const initialState: Request = stateInSessionStorage
  ? JSON.parse(stateInSessionStorage)
  : {
      isPrePurchase: false,
      reasons: [],
      newShareholdersCount: 0,
      new_users: [],
      shareTransfers: [],
      updatedShareholders: [],
      directors: [],
    }

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setRequest: (state, action: PayloadAction<Request>) => {
      const { reasons, newShareholdersCount } = action.payload
      const hasChangedReasons =
        state.reasons.length &&
        (difference(reasons, state.reasons).length ||
          difference(state.reasons, reasons).length ||
          newShareholdersCount !== state.newShareholdersCount)

      const newState = hasChangedReasons
        ? { ...initialState, reasons, newShareholdersCount }
        : action.payload

      sessionStorage.setItem('request', JSON.stringify(newState))
      return newState
    },

    clearRequest() {
      sessionStorage.removeItem('request')
      sessionStorage.removeItem('share-transfer-step')
      return {
        isPrePurchase: false,
        reasons: [],
        newShareholdersCount: 0,
        new_users: [],
        shareTransfers: [],
        updatedShareholders: [],
        directors: [],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      sessionStorage.removeItem('request')
      return initialState
    })
    builder.addCase(logout.rejected, () => {
      sessionStorage.removeItem('request')
      return initialState
    })
  },
})

export const { setRequest, clearRequest } = requestSlice.actions

export default requestSlice.reducer
