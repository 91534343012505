import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import {
  getCompanyOverview as apiGetCompanyOverview,
  submitPPChangeRequest as apiSubmitPPChangeRequest,
} from 'src/api/ggAPI'
import { PPChangeRequestChanges } from 'src/types/PPChangeRequest'

interface InitialState {
  arePurchaseDetailsChanged: boolean
  isPropertyAddressChanged: boolean
  currentState: { property_address: any; property_details: any }
  changes: { property_address: any; purchase_details: any }
  isLoading: boolean
}

export const initialState: InitialState = {
  arePurchaseDetailsChanged: false,
  isPropertyAddressChanged: false,
  currentState: {
    property_address: null,
    property_details: null,
  },
  changes: { property_address: null, purchase_details: null },
  isLoading: false,
}

export const getCompanyOverview = createAsyncThunk(
  'ppChangeRequest/getCompanyOverview',
  async (companyID) => apiGetCompanyOverview(companyID),
)

export const submitPPChangeRequest = createAsyncThunk(
  'ppChangeRequest/submitPPChangeRequest',
  ({ ppID, changes }: { ppID: number; changes: PPChangeRequestChanges }) =>
    apiSubmitPPChangeRequest(ppID, changes),
)

const ppChangeRequestSlice = createSlice({
  name: 'ppChangeRequestSlice',
  initialState,
  reducers: {
    savePropertyAddressChanges: (state, action) => ({
      ...state,
      changes: {
        ...state.changes,
        property_address: { ...action.payload },
      },
      isPropertyAddressChanged:
        !isEqual(action.payload?.address, state.currentState?.property_address) ||
        action.payload?.is_new_build !== state.currentState?.is_new_build,
    }),
    savePurchaseDetailsChanges: (state, action) => ({
      ...state,
      changes: {
        ...state.changes,
        purchase_details: { ...action.payload },
      },
      arePurchaseDetailsChanged: !isEqual(action.payload, state.currentState?.property_details),
    }),
    removeChanges: (state, action) => ({
      ...state,
      changes: {
        ...state.changes,
        [action.payload]: null,
      },
      arePurchaseDetailsChanged:
        action.payload === 'purchase_details' ? false : state.arePurchaseDetailsChanged,
      isPropertyAddressChanged:
        action.payload === 'property_address' ? false : state.isPropertyAddressChanged,
    }),
    setPPCurrentState: (state, action) => ({
      ...state,
      currentState: { ...action.payload },
    }),
  },
  extraReducers: {
    [getCompanyOverview.pending.type]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [getCompanyOverview.fulfilled.type]: (state, action) => ({
      ...state,
      currentState: {
        ...action.payload,
        property_details: {
          ...action.payload.property_details,
          price_in_cents: Number(action.payload.property_details.price_in_cents) ?? 0,
        },
      },
      isLoading: false,
    }),
    [submitPPChangeRequest.fulfilled.type]: (state, action) => {
      const {
        arg: { changes },
      } = action.meta
      const { property_address, purchase_details } = changes

      return {
        ...state,
        currentState: {
          ...state.currentState,
          property_address: property_address?.address ?? state.currentState.property_address,
          property_details: purchase_details
            ? {
                price_in_cents: purchase_details.price?.amount_in_cents || 0,
                completion_date: purchase_details.completion_date,
                using_mortgage: purchase_details.using_mortgage,
              }
            : state.currentState.property_details,
        },
        changes: { property_address: null, purchase_details: null },
        arePurchaseDetailsChanged: false,
        isPropertyAddressChanged: false,
      }
    },
  },
})

export const {
  savePropertyAddressChanges,
  savePurchaseDetailsChanges,
  removeChanges,
  setPPCurrentState,
} = ppChangeRequestSlice.actions

export default ppChangeRequestSlice.reducer
