import { combineReducers } from 'redux'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import companySlice from '../slices/company.slice'
import userSlice from '../slices/user.slice'
import inboxSlice from '../slices/inbox.slice'
import walletSlice from '../slices/wallet.slice'
import toastsSlice from '../slices/toasts.slice'
import launchPlanSlice from '../slices/launchPlan.slice'
import loaderSlice from '../slices/loader.slice'
import marketInsightSlice from '../slices/marketInsight.slice'
import menuSlice from '../slices/menu.slice'
import companiesSlice from '../slices/companies.slice'
import documentsSlice from '../slices/documents.slice'
import todosSlice from '../slices/todos.slice'
import formSlice from '../slices/form.slice'
import requestSlice from '../slices/request.slice'
import companyDocumentsSlice from '../slices/companyDocuments.slice'
import accountancySlice from '../slices/accountancy.slice'
import onBoardingSlice from '../slices/onBoarding.slice'
import mortgagesSlice from '../slices/mortgages.slice'
import interestsSlice from '../slices/interests.slice'
import paymentsSlice from '../slices/payments.slice'
import overdueFeesSlice from '../slices/overdueFees.slice'
import payeesSlice from '../slices/payees.slice'
import propertyPurchaseSlice from '../slices/propertyPurchase.slice'
import locationSlice from '../slices/location.slice'
import documentsToSignSlice from '../slices/documentsToSign.slice'
import analyticsSlice from '../slices/analytics.slice'
import servicesSlice from '../slices/services.slice'
import rentTrackerSlice from '../slices/rentTracker.slice'
import listingPropertiesSlice from '../slices/listingProperties.slice'
import propertyPackagesSlice from '../slices/propertyPackages.slice'
import marketplaceSlice from '../slices/marketplace.slice'
import ppChangeRequestSlice from '../slices/ppChangeRequest.slice'
import propertyReportsSlice from '../slices/propertyReports.slice'
import tasksSlice from '../slices/tasks.slice'
import productsSlice from '../slices/products.slice'
import investmentsSlice from '../slices/investments.slice'
import propertyListingSlice from '../slices/propertyListing.slice'
import listingDetailsSlice from '../slices/listingDetails.slice'
import authModalSlice from '../slices/authModal.slice'
import propertiesCarouselSlice from '../slices/propertiesCarousel.slice'
import mortgageSlice from '../slices/mortgage.slice'
import investmentSlice from '../slices/investment.slice'
import developmentSlice from '../slices/developmentDetails.slice'
import valuationSlice from '../slices/valuation.slice'
import potApi from '../slices/pot.slice'
import companyOnboardingSlice from '../slices/companyOnboarding.slice'
import companyOnboardingsSlice from '../slices/companyOnboardings.slice'
import countriesSlice from '../slices/countries.slice'
import referralCodeSlice from '../slices/referralCodes.slice'

const rootReducer = combineReducers({
  accountancySubmission: accountancySlice,
  analytics: analyticsSlice,
  companies: companiesSlice,
  company: companySlice,
  companyDocuments: companyDocumentsSlice,
  documents: documentsSlice,
  documentsToSign: documentsToSignSlice,
  form: formSlice,
  inbox: inboxSlice,
  interests: interestsSlice,
  launchPlan: launchPlanSlice,
  listingProperty: listingPropertiesSlice,
  loader: loaderSlice,
  location: locationSlice,
  marketInsight: marketInsightSlice,
  marketplace: marketplaceSlice,
  menu: menuSlice,
  mortgages: mortgagesSlice,
  onBoarding: onBoardingSlice,
  overdueFees: overdueFeesSlice,
  payees: payeesSlice,
  payments: paymentsSlice,
  ppChangeRequest: ppChangeRequestSlice,
  products: productsSlice,
  propertyPackages: propertyPackagesSlice,
  propertyPurchase: propertyPurchaseSlice,
  propertyReports: propertyReportsSlice,
  rentTracker: rentTrackerSlice,
  request: requestSlice,
  services: servicesSlice,
  toasts: toastsSlice,
  todos: todosSlice,
  user: userSlice,
  wallet: walletSlice,
  tasks: tasksSlice,
  investments: investmentsSlice,
  propertyListing: propertyListingSlice,
  listingDetails: listingDetailsSlice,
  authModal: authModalSlice,
  propertiesCarousel: propertiesCarouselSlice,
  mortgage: mortgageSlice,
  investment: investmentSlice,
  developmentDetails: developmentSlice,
  valuation: valuationSlice,
  [potApi.reducerPath]: potApi.reducer,
  companyOnboarding: companyOnboardingSlice,
  companyOnboardings: companyOnboardingsSlice,
  countries: countriesSlice,
  referralCodes: referralCodeSlice,
})

export type RootState = ReturnType<typeof rootReducer>
type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useTypedDispatch: () => AppDispatch = useDispatch

export default rootReducer
