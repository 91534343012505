type TopParts =
  | 'company_design'
  | 'property_details'
  | 'mortgage_request'
  | 'property_enquiry'
  | 'public_property_enquiry'
  | 'accounts_docs_signed'

export const APP_FLOWS: { [key: string]: TopParts } = {
  COMPANY_DESIGN: 'company_design',
  PROPERTY_DETAILS: 'property_details',
  MORTGAGE_REQUEST: 'mortgage_request',
  PROPERTY_ENQUIRY: 'property_enquiry',
  PUBLIC_PROPERTY_ENQUIRY: 'public_property_enquiry',
  ACCOUNTS_DOCS_SIGNED: 'accounts_docs_signed',
}

export const CTA_CLICK = 'CTA_CLICK'
