import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import dayjs from 'dayjs'
import LogRocket from 'logrocket'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { NON_AUTH_ROUTES } from 'src/config/constants'
import { reauthenticate } from 'src/state/_actions/auth'
import { getUser, logout } from 'src/state/slices/user.slice'
import _middleware from 'src/state/middlewares/_middleware'
import { getAuth, isAuthenticatedUser, isWebSessionUser } from 'src/utils/auth'
import * as ggUrl from 'src/utils/ggUrl'
import rootReducer from './reducers'

import loaderMiddleware from './middlewares/loaderMiddleware'
import toastMiddleware from './middlewares/toastMiddleware'
import { getInvestments } from './slices/investments.slice'
import { getCompanyOnboardings } from './slices/companyOnboardings.slice'
import routes from 'src/pages/AppWrapper/AppRoutes'

import potApi from './slices/pot.slice'

type State = { [key: string]: any }
type Action = { [key: string]: any }

export const middleware = [
  thunk,
  loaderMiddleware,
  toastMiddleware,
  _middleware,
  LogRocket.reduxMiddleware({
    actionSanitizer(action: Action): Action | null {
      if (!getAuth() && action.type === 'user/userLogin') {
        return null
      }
      return action
    },
    stateSanitizer(state: State): State {
      return {
        ...state,
        user: undefined,
      }
    },
  }),
]

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['interests', 'services', 'listingProperty'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(potApi.middleware)
      .concat(middleware),
})

if (typeof window !== 'undefined') {
  const { pathname, searchParams } = new URL(window.location.href)
  const auth = getAuth()
  const isAuthenticated = isAuthenticatedUser()
  const isWebSession = isWebSessionUser()
  const isNonAuthRoute = NON_AUTH_ROUTES.some((route) => route === pathname)

  if (auth && isAuthenticated && pathname !== '/' && !isNonAuthRoute) {
    const minutesSinceLastAuthed = dayjs().diff(auth.timestamp, 'minute')

    if (minutesSinceLastAuthed < 30) {
      store.dispatch(getUser())
      store.dispatch(getInvestments())
      store.dispatch(getCompanyOnboardings())
      store.dispatch(reauthenticate())

      if (pathname === '/') {
        const redirectUrl = searchParams?.get?.('redirect') || '/dashboard'
        routes?.navigate(redirectUrl)
      }
    } else {
      if (!isWebSession) {
        store.dispatch(logout())
      }
      if (pathname !== '/') {
        routes?.navigate(ggUrl.loginUrl())
      }
    }
  }
}

export const persistor = persistStore(store)
export default store
