import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'

export const initialState = {
  allDocuments: [],
  approvalDocuments: [],
  document: {},
  isModalOpen: false,
  isSignButtonDisabled: false,
}

export const fetchDocument = createAsyncThunk('documentsToSign/fetchDocument', api.fetchMarkdown)
export const fetchAllDocuments = createAsyncThunk(
  'documentsToSign/fetchAllDocuments',
  ({ ppID, userID }: { ppID: string; userID: string }) => api.fetchAllMarkdowns(userID, ppID),
)
export const fetchAllChangeRequestDocuments = createAsyncThunk(
  'documentsToSign/fetchAllChangeRequestDocuments',
  ({ changeRequestID, ppID }: { changeRequestID: number; userID: string }) =>
    api.fetchChangeRequestDocs(ppID, changeRequestID),
)
export const fetchPPChangeRequestDocuments = createAsyncThunk(
  'documentsToSign/fetchPPChangeRequestDocuments',
  ({ ppID }: { ppID: string }) => api.getPPChangeRequestDocuments(ppID),
)

export const signDocument = createAsyncThunk('documentsToSign/signDocument', api.signMarkdown)

const documentsToSignSlice = createSlice({
  name: 'documentsToSign',
  initialState,
  reducers: {
    setInitialState: () => initialState,
    closeModal: (state) => ({ ...state, document: {}, isModalOpen: false }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocument.fulfilled, (state, action) => {
      const { title, signed_markdown_text, document_type, id } = action.payload
      return {
        ...state,
        document: {
          modalMarkdown: signed_markdown_text,
          [title]: signed_markdown_text,
          activeMarkdownID: id,
          activeMarkdownTitle: title,
          documentType: document_type,
        },
        isModalOpen: true,
      }
    })
    builder.addCase(fetchAllDocuments.fulfilled, (state, action) => {
      const { markdown_summaries, document_approvals } = action.payload
      return {
        ...state,
        allDocuments:
          markdown_summaries?.map((markdown) => ({
            title: markdown.title,
            imageAltText: markdown.title,
            status: markdown.signed ? 'signed' : 'not_signed',
            markdownID: markdown.id,
          })) ?? [],
        approvalDocuments:
          document_approvals?.map((d) => ({
            ...d,
            status: d.approved ? 'approved' : 'not_approved',
          })) ?? [],
      }
    })
    builder.addCase(fetchAllChangeRequestDocuments.fulfilled, (state, action) => {
      return {
        ...state,
        allDocuments: action.payload.map((markdown) => ({
          title: markdown.title,
          imageAltText: markdown.title,
          status: markdown.signed ? 'signed' : 'not_signed',
          markdownID: markdown.id,
        })),
      }
    })
    builder.addCase(fetchPPChangeRequestDocuments.fulfilled, (state, action) => {
      const { markdown_summaries } = action.payload
      return {
        ...state,
        allDocuments: markdown_summaries.map((markdown) => ({
          title: markdown.title,
          imageAltText: markdown.title,
          status: markdown.signed ? 'signed' : 'not_signed',
          markdownID: markdown.id,
        })),
      }
    })
    builder.addCase(signDocument.pending, (state) => {
      state.isSignButtonDisabled = true
    })
    builder.addCase(signDocument.fulfilled, (state, action) => {
      state.isSignButtonDisabled = false
      state.document.status = 'signed'
      state.allDocuments = state.allDocuments.map((document) => ({
        ...document,
        status:
          document.status === 'signed' || document.markdownID === action.payload.id
            ? 'signed'
            : 'not_signed',
      }))
    })
    builder.addCase(signDocument.rejected, (state) => {
      state.isSignButtonDisabled = false
    })
  },
})

export const { setInitialState, closeModal } = documentsToSignSlice.actions

export default documentsToSignSlice.reducer
