
      if (import.meta.env.MODE === 'production') {
        !(function (a) {
          ;(a.aurycReadyCb = a.aurycReadyCb || []), (a.auryc = a.auryc || [])
          a.aurycEventPropertiesCb = a.aurycEventPropertiesCb || []
          var e = document,
            r = e.createElement('script'),
            t = e.head || e.getElementsByTagName('head')[0],
            c = {
              src: 'https://cdn.auryc.com/9869-Main-prod-heap/container.js',
              'data-cfasync': 'false',
              async: 'true',
              'data-vendor': 'auryc',
              'data-role': 'container',
              charset: 'utf-8',
            }
          for (var d in c) r.setAttribute(d, c[d])
          t.appendChild(r)
          for (
            var n = [
                'track',
                'identify',
                'addFBCustomData',
                'addUserProperties',
                'addSessionProperties',
                'addInternalSessionProperties',
                'getReplayUrl',
                'setFeedbackEnabled',
                'clearUserCookie',
                'addFBSubmitHandler',
                'addFBCancelHandler',
                'addEventProperties',
                'removeEventProperty',
                'removeAllEventProperties',
                'pause',
                'resume',
              ],
              u = function (e) {
                return function () {
                  var r = Array.prototype.slice.call(arguments, 0)
                  if (
                    e === 'addEventProperties' ||
                    e === 'removeEventProperty' ||
                    e === 'removeAllEventProperties'
                  ) {
                    a.aurycEventPropertiesCb.push(function () {
                      a.auryc[e] && a.auryc[e].apply(a.auryc, r)
                    })
                  } else {
                    a.aurycReadyCb.push(function () {
                      a.auryc[e] && a.auryc[e].apply(a.auryc, r)
                    })
                  }
                }
              },
              s = 0;
            s < n.length;
            s++
          )
            a.auryc[n[s]] = u(n[s])
        })(window)
      }
    