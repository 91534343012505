import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createSellValuation,
  getPublicSellValuation,
  requestCallPublicValuation,
} from 'src/api/ggAPI'
import { Valuation, ValuationRequest } from 'src/types/investments'

interface ValuationState {
  internalAreaRequired: boolean
  valuationForm: Partial<ValuationRequest>
  valuation: Valuation | null
  hasSubmittedForm: boolean
}

export const initialState: ValuationState = {
  internalAreaRequired: false,
  valuationForm: {},
  valuation: null,
  hasSubmittedForm: false,
}

export const createValuation = createAsyncThunk(
  'valuation/createSellValutation',
  createSellValuation,
)

export const getPublicValuation = createAsyncThunk(
  'valuation/getPublicValuation',
  getPublicSellValuation,
)

export const requestPublicValuationCall = createAsyncThunk(
  'valuation/requestPublicValuationCall',
  requestCallPublicValuation,
)

const valuationSlice = createSlice({
  name: 'valuation',
  initialState,
  reducers: {
    setValuation: (state, action) => ({
      ...state,
      valuation: action.payload,
    }),
    setValuationForm: (state, action) => ({
      ...state,
      valuationForm: action.payload,
    }),
    setInternalAreaRequired: (state, action) => ({
      ...state,
      internalAreaRequired: action.payload,
    }),
    setRequestedContact: (state, action) => {
      state.valuation!.has_user_requested_contact = action.payload
    },
    resetValuation: () => ({
      ...initialState,
    }),
    setSubmittedForm: (state, action) => ({
      ...state,
      hasSubmittedForm: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(createValuation.fulfilled, (state: any, action: any) => {
      return {
        ...state,
        valuation: action.payload.valutation_request,
      }
    }),
      builder.addCase(getPublicValuation.fulfilled, (state: any, action: any) => {
        return {
          ...state,
          valuation: action.payload,
        }
      })
  },
})

export const {
  setValuation,
  setValuationForm,
  setInternalAreaRequired,
  resetValuation,
  setSubmittedForm,
  setRequestedContact,
} = valuationSlice.actions

export default valuationSlice.reducer
