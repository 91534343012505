import axios from 'axios'

const mutateTimestamp = (timestamp: number | null) =>
  timestamp ? Number(timestamp * 10 ** 9) : null

export const getUser = () =>
  axios.get('v2/users/token/me').then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
  }))

export const resendSMS = () => axios.post('v2/token/resend_sms')

interface NPSResponse {
  should_request: boolean
}

export const shouldUserDoNPS = (step: string): Promise<NPSResponse> =>
  axios.get(`/v3/nps/should_request?step=${step}`)

export interface RegisterInterestRequest {
  userID: number
  interest: 'LANDLORD_INSURANCE' | 'SOLICITOR' | 'LETTINGS_AND_MANAGEMENT'
  comments?: string
  isInterested?: boolean
}

// Old API but in future, `userID` should be removed to avoid impersonation
export const registerInterest = ({
  userID,
  interest,
  comments,
  isInterested = true,
}: RegisterInterestRequest) =>
  axios.post(`/v3/users/${userID}/interests`, {
    interest,
    is_interested: isInterested,
    ...(comments != null ? { comments } : {}),
  })

interface RequestFileUrls {
  files: { filename: string; mimeType: string }[]
}

interface FileResponse {
  filename: string
  signed_url: string
  object_name: string
  headers?: any
}

export const requestFileUrls = ({ files }: RequestFileUrls) =>
  axios.post<FileResponse[]>(`v3/files/signed_url_v4`, {
    files: files.map((file) => ({ filename: file.filename, mime_type: file.mimeType })),
  })
