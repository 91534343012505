import pluralize from 'pluralize'

import { showSuccessToast, showErrorToast } from 'src/utils/toasts'
import {
  cancelDirectDebit,
  sendExchangeCurrencyRequest,
  openInternationalAccount,
} from '../slices/wallet.slice'
import { deleteCompanyDocuments, editCompanyDocuments } from '../slices/companyDocuments.slice'
import {
  deleteExpenses,
  unlinkCompanyDocuments,
  getSignableAccountsDocuments,
} from '../slices/accountancy.slice'
import { submitMortgageApplication } from '../slices/mortgages.slice'
import { updateUser } from '../slices/user.slice'
import { getOverdueFees } from '../slices/overdueFees.slice'
import { editPayee } from '../slices/payees.slice'
import {
  offboardCompany,
  terminateCompany,
  requestValuationCallFailed,
} from '../slices/company.slice'
import { saveListing, saveListingDevelopment } from '../slices/propertyListing.slice'
import { saveListing as saveListingDetails } from '../slices/listingDetails.slice'
import { requestCall } from '../slices/propertyPackages.slice'
import { requestCallMarketplace, requestCallPropertyManagement } from '../slices/marketplace.slice'
import { saveDevelopment } from '../slices/developmentDetails.slice'

let count

const toastMiddleware = () => (next) => (action) => {
  const networkErrorMessages = [
    "Cannot read property 'status' of undefined",
    "Cannot read property 'response' of null",
    "undefined is not an object (evaluating 'e.response.status')",
  ]

  if (networkErrorMessages.includes(action.error?.message)) {
    showErrorToast(
      'There is an issue with your network. Please access GetGround from a different network.',
    )
    next(action)
  }

  switch (action.type) {
    case sendExchangeCurrencyRequest.rejected.type:
      showErrorToast('Failed to send exchange currency request')
      next(action)
      break

    case sendExchangeCurrencyRequest.fulfilled.type:
      showSuccessToast(
        'Conversion in progress. You will receive your funds on the next business day.',
      )
      next(action)
      break

    case updateUser.rejected.type:
      showErrorToast(action.error?.message || 'Failed')
      next(action)
      break

    case cancelDirectDebit.fulfilled.type:
      showSuccessToast('Direct debit cancelled')
      next(action)
      break

    case cancelDirectDebit.rejected.type:
      showErrorToast('Direct debit cancellation failed')
      next(action)
      break

    case deleteCompanyDocuments.fulfilled.type:
      count = action.meta.arg.length
      showSuccessToast(`${pluralize('Document', count)} deleted`)
      next(action)
      break

    case deleteCompanyDocuments.rejected.type:
      count = action.meta.arg.length
      showErrorToast(`Failed to delete ${pluralize('document', count)}`)
      next(action)
      break

    case editCompanyDocuments.fulfilled.type:
      count = action.meta.arg.documents.length
      showSuccessToast(`${pluralize('Document', count)} edited`)
      next(action)
      break

    case editCompanyDocuments.rejected.type:
      count = action.meta.arg.documents.length
      showErrorToast(`Failed to edit ${pluralize('document', count)}`)
      next(action)
      break

    case deleteExpenses.fulfilled.type:
      showSuccessToast('Expense has been removed')
      next(action)
      break

    case deleteExpenses.rejected.type:
      showErrorToast('Expense has not been removed')
      next(action)
      break

    case unlinkCompanyDocuments.fulfilled.type:
      showSuccessToast('Document has been removed')
      next(action)
      break

    case unlinkCompanyDocuments.rejected.type:
      showErrorToast('Document has not been removed')
      next(action)
      break

    case getSignableAccountsDocuments.rejected.type:
      showErrorToast('Failed to fetch documents, please try again')
      next(action)
      break

    case submitMortgageApplication.fulfilled.type:
      showSuccessToast('Questionnaire complete')
      next(action)
      break

    case submitMortgageApplication.rejected.type:
      showErrorToast('Failed to submit questionnaire, please try again')
      next(action)
      break

    case openInternationalAccount.fulfilled.type:
      showSuccessToast('International account opened sucessfully')
      next(action)
      break

    case openInternationalAccount.rejected.type:
      showErrorToast('There was an error opening your international account')
      next(action)
      break

    case getOverdueFees.rejected.type:
      showErrorToast('Failed to load overdue fees')
      next(action)
      break

    case editPayee.fulfilled.type:
      showSuccessToast('Payee sucessfully updated')
      next(action)
      break

    case editPayee.rejected.type:
      showErrorToast('Failed to update payee. Please retry.')
      next(action)
      break

    case terminateCompany.fulfilled.type:
      showSuccessToast('Request to dissolve company successfully registered')
      next(action)
      break

    case terminateCompany.rejected.type:
      showErrorToast('There was an error requesting company dissolution. Please try again.')
      next(action)
      break

    case offboardCompany.fulfilled.type:
      showSuccessToast('Request to offboard company successfully registered')
      next(action)
      break

    case offboardCompany.rejected.type:
      showErrorToast('There was an error requesting company offboarding. Please try again.')
      next(action)
      break

    case requestCall.rejected.type:
      showErrorToast(action?.error?.message || 'Something went wrong requesting to contact')
      next(action)
      break

    case requestCallMarketplace.fulfilled.type: {
      showSuccessToast("We've got your request, and we'll be in touch shortly.")
      next(action)
      break
    }

    case requestCallMarketplace.rejected.type:
      showErrorToast(action?.error?.message || 'Something went wrong requesting to contact')
      next(action)
      break

    case requestCallPropertyManagement.fulfilled.type:
      showSuccessToast("We've got your request, and we'll be in touch shortly.")
      next(action)
      break

    case saveListingDetails.fulfilled.type:
    case saveListing.fulfilled.type:
    case saveListingDevelopment.fulfilled.type:
    case saveDevelopment.fulfilled.type:
      next(action)
      if ('isEnquiryPage' in action.meta.arg) {
        return
      }
      showSuccessToast('Property saved ❤️')
      break

    case requestValuationCallFailed.fulfilled.type:
      showSuccessToast("We've received your request and will be in touch shortly")
      next(action)
      break

    case requestValuationCallFailed.rejected.type:
      showErrorToast('Something went wrong requesting to contact')
      next(action)
      break

    default:
      next(action)
  }
}

export default toastMiddleware
