import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import * as newApi from 'src/api/user/routes'

export const getInterests = createAsyncThunk(
  'interests/getInterests',
  ({ userID }: { userID: number }) => api.getAvailableInterests(userID),
)

/** @deprecated use useRegisterInterest from src/api/user/mutations instead */
export const registerInterest = createAsyncThunk(
  'interests/registerInterest',
  ({ interest, isInterested }, { getState }) =>
    newApi.registerInterest(getState().user.id, interest, isInterested),
)

interface Interest {
  interest: string
  is_interested: boolean
}

interface InterestsProps {
  respondedInterest: Interest[]
}

export const initialState: InterestsProps = {
  respondedInterest: [],
}

const interestsSlice = createSlice({
  name: 'interests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInterests.fulfilled, (state, action) => {
      state.respondedInterest = action.payload || []
    })
    builder.addCase(registerInterest.fulfilled, (state, action) => {
      if (action.meta.arg.interest === 'PROCESS_AGENT' && !action.meta.arg.isInterested) {
        const currentState = [...state.respondedInterest]
        const newState = currentState.filter((interest) => interest !== 'PROCESS_AGENT')
        state.respondedInterest = newState
        return
      }
      state.respondedInterest = [
        ...state.respondedInterest,
        { interest: action.meta.arg.interest, is_interested: action.meta.arg.isInterested },
      ]
    })
  },
})

export default interestsSlice.reducer
