import { createSlice } from '@reduxjs/toolkit'
import { SHOW_TOAST, CLEAR_ALL_TOASTS } from 'src/config/constants'

type Toast = {
  message: string
  status: string
}

const initialState: Toast[] = []

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {},
  extraReducers: {
    [SHOW_TOAST]: (state, action) =>
      state.concat([{ message: action.message, status: action.status }]),
    [CLEAR_ALL_TOASTS]: () => initialState,
  },
})

export default toastsSlice.reducer
