import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Disable automatic refetching on window focus
      retry: 0, // Number of times to retry failed queries
      retryOnMount: false,
    },
  },
})

export { queryClient, QueryClientProvider }
