import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  requestCompanyTermination,
  requestOffboardCompany,
  getCompanyOverview,
  getInvestmentSell,
  getInvestmentValuation,
  getInvestmentValuations,
  createInvestmentValuation,
  createInvestmentSellValuation,
  createInvestmentSell,
  updateInvestmentSell,
  requestCallValuation,
  requestCallValuationFailed,
} from 'src/api/ggAPI'
import { SellProduct } from 'src/types/SellProduct'
import { ValuationRequest } from 'src/types/investments'

export const terminateCompany = createAsyncThunk(
  'company/terminateCompany',
  requestCompanyTermination,
)

export const offboardCompany = createAsyncThunk('company/offboardCompany', requestOffboardCompany)

export const getOverview = createAsyncThunk('company/getOverview', (companyID: number) =>
  getCompanyOverview(companyID),
)

export const getSellProduct = createAsyncThunk('company/getSellProduct', (investmentID: number) =>
  getInvestmentSell(investmentID),
)

export const requestValuationCall = createAsyncThunk(
  'company/requestCallValuation',
  ({ investmentID, sellProductID }: { investmentID: number; sellProductID: number }) =>
    requestCallValuation(investmentID, sellProductID),
)

export const requestValuationCallFailed = createAsyncThunk(
  'company/requestCallValuationFailed',
  ({
    valuationID,
    user_comment,
    valuation_share_id,
  }: {
    valuationID: number | null
    user_comment: string
    valuation_share_id: string | null
  }) => requestCallValuationFailed(valuationID, user_comment, valuation_share_id),
)

export const getValuations = createAsyncThunk('company/getValuation', (investmentID: number) =>
  getInvestmentValuations(investmentID),
)

export const getValuation = createAsyncThunk(
  'company/getValuation',
  ({ investmentID, valuationID }: { investmentID: number; valuationID: number }) =>
    getInvestmentValuation(investmentID, valuationID),
)

export const createValuation = createAsyncThunk(
  'company/createValuation',
  ({ investmentID, data }: { investmentID: number; data: ValuationRequest }, { rejectWithValue }) =>
    createInvestmentValuation(investmentID, data).catch(({ status, response }) =>
      rejectWithValue({ code: status, data: response?.data }),
    ),
)

export const createSellValuation = createAsyncThunk(
  'company/createSellValuation',
  ({ investmentID, data }: { investmentID: number; data: ValuationRequest }, { rejectWithValue }) =>
    createInvestmentSellValuation(investmentID, data).catch(({ status, response }) =>
      rejectWithValue({ code: status, data: response?.data }),
    ),
)

export const createSellProduct = createAsyncThunk(
  'company/createSellProduct',
  ({ investmentID, valuationID }: { investmentID: number; valuationID: number }) =>
    createInvestmentSell(investmentID, valuationID),
)

export const updateSellProduct = createAsyncThunk(
  'company/updateSellProduct',
  ({
    investmentID,
    productID,
    valuationID,
    status,
  }: {
    investmentID: number
    productID: number
    valuationID: number
    status: SellProduct['status']
  }) => updateInvestmentSell(investmentID, productID, valuationID, status),
)

interface InitialState {
  ppID?: number
  companyID?: number
  is_stock?: boolean
  has_completed_professional_clearance?: boolean | null
}

export const initialState: InitialState = {}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    deleteCompany: () => initialState,
    setCompany: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getOverview.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
  },
})

export const { deleteCompany, setCompany } = companySlice.actions

export default companySlice.reducer
