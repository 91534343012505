import { useFlags } from 'launchdarkly-react-client-sdk'
import { lazy } from 'react'
import { Navigate, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { Route } from 'react-router-dom'

import 'src/styles/main.scss'
import AppWrapper from './AppWrapper'
import Redirect from 'src/components/Redirect'
import ProtectedRoutes from './ProtectedRoutes'
const Portfolio = lazy(() => import('src/components-pages/Portfolio'))
const PortfolioProperties = lazy(() => import('src/components-pages/Portfolio/Properties'))
const PortfolioProperty = lazy(() => import('src/components-pages/Portfolio/Property'))
const PortfolioTransactions = lazy(() => import('src/components-pages/Portfolio/Transactions'))
const PortfolioRentTracker = lazy(() => import('src/components-pages/Portfolio/RentTracker'))
const PortfolioCompanies = lazy(() => import('src/components-pages/Portfolio/Companies'))
const PortfolioCompaniesAllInProgress = lazy(
  () => import('src/components-pages/Portfolio/Companies/components/AllInProgressCompanies'),
)
const PortfolioCompany = lazy(() => import('src/components-pages/Portfolio/Company'))
const PortfolioCompanySetup = lazy(() => import('src/components-pages/Portfolio/CompanySetup'))
const PortfolioCompanySettings = lazy(
  () => import('src/components-pages/Portfolio/CompanySettings'),
)
const PortfolioCompanyDocuments = lazy(
  () => import('src/components-pages/Portfolio/CompanyDocuments'),
)
const PortfolioCompanyBilling = lazy(() => import('src/components-pages/Portfolio/CompanyBilling'))
const PortfolioWallets = lazy(() => import('src/components-pages/Portfolio/wallets'))
const PortfolioAllTransactions = lazy(
  () => import('src/components-pages/Portfolio/AllTransactions'),
)
const PortfolioTransactionDetails = lazy(() => import('src/components-pages/Portfolio/Transaction'))
const PortfolioWallet = lazy(() => import('src/components-pages/Portfolio/Wallet'))
const PortfolioWalletProperty = lazy(() => import('src/components-pages/Portfolio/Wallet/Property'))
const PortfolioWalletCompany = lazy(() => import('src/components-pages/Portfolio/Wallet/Company'))
const PortfolioWalletTransactions = lazy(
  () => import('src/components-pages/Portfolio/Wallet/Transactions'),
)
const PortfolioAccountDetails = lazy(
  () => import('src/components-pages/Portfolio/Wallet/AccountDetails'),
)
const PortfolioAccountAddMoney = lazy(
  () => import('src/components-pages/Portfolio/Wallet/AddMoney'),
)
const PortfolioAddMoneyUKAccount = lazy(
  () => import('src/components-pages/Portfolio/Wallet/AddMoney/UKAccount'),
)
const PortfolioAddMoneyInternationalAccount = lazy(
  () => import('src/components-pages/Portfolio/Wallet/AddMoney/InternationalAccount'),
)
const PortfolioSendMoney = lazy(() => import('src/components-pages/Portfolio/Wallet/SendMoney'))
const PortfolioAccountPayment = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/Payment'),
)
const PortfolioAccountPayee = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/Payee'),
)
const PortfolioAccountPayeeList = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/PayeesList'),
)
const PortfolioAccountPaymentDetails = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/PaymentDetails'),
)
const PortfolioAccountPaymentConfirmation = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/PaymentConfirmation'),
)
const PortfolioAccountCompanies = lazy(
  () => import('src/components-pages/Portfolio/Wallet/SendMoney/Companies'),
)

const PortfolioAccountRegularPayments = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments'),
)

const PortfolioAllPaymentPage = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments/AllPaymentPage'),
)

const PortfolioStandingOrders = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments/StandingOrders'),
)

const PortfolioDirectDebit = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments/DirectDebits'),
)

const PortfolioRegularPaymentDetails = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments/RegularPaymentDetails'),
)

const PortfolioEditStandingOrder = lazy(
  () => import('src/components-pages/Portfolio/Wallet/RegularPayments/EditStandingOrder'),
)
const PortfolioEditStandingOrderForm = lazy(
  () =>
    import(
      'src/components-pages/Portfolio/Wallet/RegularPayments/EditStandingOrder/EditStandingOrderForm'
    ),
)

const PortfolioEditStandingOrderConfirm = lazy(
  () =>
    import(
      'src/components-pages/Portfolio/Wallet/RegularPayments/EditStandingOrder/EditStandingOrderConfirm'
    ),
)

const PortfolioPot = lazy(() => import('src/components-pages/Portfolio/Pot/index'))
const PortfolioPotActivity = lazy(() => import('src/components-pages/Portfolio/Pot/Activity'))
const PortfolioPotFAQ = lazy(() => import('src/components-pages/Portfolio/Pot/FAQ'))
const PortfolioPotWithdraw = lazy(() => import('src/components-pages/Portfolio/Pot/WithdrawMoney'))
const PortfolioPotAddMoney = lazy(
  () => import('src/components-pages/Portfolio/Pot/AddPotMoney/AddPotMoney'),
)
const PortfolioPotEditDeposit = lazy(() => import('src/components-pages/Portfolio/Pot/EditDeposit'))

const PortfolioConfirmShareholders = lazy(
  () => import('src/components-pages/Portfolio/Pot/ConfirmShareholders'),
)

const AddToPortfolio = lazy(() => import('src/components-pages/AddToPortfolio'))
const AddCompanyToPortfolio = lazy(() => import('src/components-pages/AddToPortfolio/AddCompany'))
const AddPropertyToPortfolio = lazy(() => import('src/components-pages/AddToPortfolio/AddProperty'))
const UpdateSubscription = lazy(
  () => import('src/components-pages/MultiPropertyOnboarding/UpdateSubscription'),
)
const MultiPropertyDocuments = lazy(
  () => import('src/components-pages/MultiPropertyOnboarding/MultiPropertyDocuments'),
)
const MultiPropertyOnboarding = lazy(() => import('src/components-pages/MultiPropertyOnboarding'))
const MultiPropertyOnboardingPropertyDetails = lazy(
  () => import('src/components-pages/MultiPropertyOnboarding/PropertyDetails'),
)

const Discover = lazy(() => import('src/components-pages/Discover'))
const DiscoverCompanyTab = lazy(() => import('src/components-pages/Discover/CompanyTab'))
const DiscoverPropertyTab = lazy(() => import('src/components-pages/Discover/PropertyTab'))
const DiscoverFinanceTab = lazy(() => import('src/components-pages/Discover/FinanceTab'))

const Today = lazy(() => import('src/components-pages/Today/Today'))
const TodayPage = lazy(() => import('src/components-pages/Today/TodayPage/TodayPage'))
const AllTasksPage = lazy(() => import('src/components-pages/Today/AllTasksPage/AllTasksPage'))

const BookkeepingPage = lazy(() => import('src/components-pages/Bookkeeping/BookkeepingPage'))
const RecommendationsPage = lazy(
  () => import('src/components-pages/Recommendations/RecommendationsPage'),
)

const Insights = lazy(() => import('src/components-pages/Insights'))
const ProfitAndLossAnalysis = lazy(
  () => import('src/components-pages/Insights/components/ProfitAndLossAnalysis'),
)

const UploadTransactions = lazy(
  () => import('src/components-pages/Company/Accountancy/steps/UploadTransactions'),
)

const PrivateMortgageLayout = lazy(
  () => import('src/components-pages/MortgageLayouts/PrivateMortgageLayout'),
)
const PublicMortgageLayout = lazy(
  () => import('src/components-pages/MortgageLayouts/PublicMortgageLayout'),
)
const MortgageCalculator = lazy(() => import('src/components-pages/MortgageCalculator'))
const MortgageSelectedLenders = lazy(
  () => import('src/components-pages/MortgageSelectedLenders/MortgageSelectedLenders'),
)
const MortgageQuote = lazy(() => import('src/components-pages/MortgageQuote'))
const PropertyDetailsReview = lazy(
  () =>
    import(
      'src/components-pages/Investment/Tabs/PropertyTab/PropertyDetailsReview/PropertyDetailsReview'
    ),
)
const RequestChangeProperty = lazy(
  () =>
    import(
      'src/components-pages/Investment/Tabs/PropertyTab/RequestChangeProperty/RequestChangeProperty'
    ),
)
const RequestChangePropertyReview = lazy(
  () =>
    import(
      'src/components-pages/Investment/Tabs/PropertyTab/RequestChangePropertyReview/RequestChangePropertyReview'
    ),
)
const DevelopmentDetails = lazy(
  () => import('src/components-pages/DevelopmentDetails/DevelopmentDetails'),
)
const ReferralInvite = lazy(() => import('src/components-pages/ReferralInvite'))
const ReferralCode = lazy(() => import('src/components-pages/ReferralCode'))
const ClientSignUp = lazy(() => import('src/components-pages/ClientSignUp'))
const CreateSignature = lazy(() => import('src/components-pages/Account/CreateSignature'))
const DrawSignature = lazy(() => import('src/components-pages/Account/DrawSignature'))
const SignatureSetup = lazy(() => import('src/components-pages/Account/SignatureSetup'))
const SignatureSetupNew = lazy(() => import('src/components-pages/Account/SignatureSetupNew'))
const UploadSignature = lazy(() => import('src/components-pages/Account/UploadSignature'))
const Payment = lazy(() => import('src/components-pages/CompanyDesign/Payment'))
const PropertyAddress = lazy(() => import('src/components-pages/CompanyDesign/PropertyAddress'))
const PurchaseDetails = lazy(() => import('src/components-pages/CompanyDesign/PurchaseDetails'))
const ShareholderDetails = lazy(
  () => import('src/components-pages/CompanyDesign/ShareholderDetails'),
)
const SolicitorDetails = lazy(() => import('src/components-pages/CompanyDesign/SolicitorDetails'))
const TaxQuestions = lazy(() => import('src/components-pages/CompanyDesign/TaxQuestions'))
const CompanyName = lazy(() => import('src/components-pages/CompanyDesign/CompanyName'))
const PropertyAgent = lazy(() => import('src/components-pages/CompanyDesign/PropertyAgent'))
const CompanyDesignCongratulations = lazy(
  () =>
    import(
      'src/components-pages/CompanyDesign/CompanyDesignCongratulations/CompanyDesignCongratulations'
    ),
)
const Lettings = lazy(() => import('src/components-pages/CompanyDesign/Lettings'))
const Insurance = lazy(() => import('src/components-pages/CompanyDesign/Insurance'))
const PropertyCompletion = lazy(() => import('src/components-pages/PropertyCompletion'))

const CompanyDesignReview = lazy(() => import('src/components-pages/CompanyDesign/Review'))
const Dashboard = lazy(() => import('src/components-pages/Dashboard'))
const RentTracker = lazy(() => import('src/components-pages/RentTracker'))
const RequestChange = lazy(() => import('src/components-pages/Company/RequestChange/RequestChange'))
const PropertyAddressForm = lazy(
  () => import('src/components-pages/PPChangeRequest/PropertyAddressForm'),
)
const PurchaseDetailsForm = lazy(
  () => import('src/components-pages/PPChangeRequest/PurchaseDetailsForm'),
)
const Accountancy = lazy(() => import('src/components-pages/Company/Accountancy'))
const Intro = lazy(() => import('src/components-pages/Company/Accountancy/steps/Intro'))
const PropertyInformation = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/PropertyInformation/PropertyInformation'
    ),
)
const CompletionDate = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/PropertyInformation/components/CompletionDate/CompletionDate'
    ),
)
const PropertyValuation = lazy(
  () =>
    import('src/components-pages/Company/Accountancy/steps/PropertyInformation/PropertyValuation'),
)
const Tenants = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/PropertyInformation/components/Tenants/Tenants'
    ),
)
const Mortgage = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/PropertyInformation/components/Mortgage/Mortgage'
    ),
)
const Expenses = lazy(() => import('src/components-pages/Company/Accountancy/steps/Expenses'))
const Cashflows = lazy(() => import('src/components-pages/Company/Accountancy/steps/Cashflows'))
const Review = lazy(() => import('src/components-pages/Company/Accountancy/steps/Review'))
const AccountancyConfirmation = lazy(
  () => import('src/components-pages/Company/Accountancy/steps/Confirmation'),
)
const AccountingCongratulationsPage = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/Congratulations/AccountingCongratulations'
    ),
)
const Documents = lazy(() => import('src/components-pages/Documents'))
const Prompt = lazy(() => import('src/components-pages/Documents/Prompt/Prompt'))
const AcceptanceOfRole = lazy(() => import('src/components-pages/onboarding/AcceptanceOfRole'))
const DeclineRole = lazy(
  () => import('src/components-pages/onboarding/AcceptanceOfRole/DeclineRole'),
)
const Choose2FA = lazy(() => import('src/components-pages/onboarding/Choose2FA'))
const CreateAccount = lazy(() => import('src/components-pages/onboarding/CreateAccount'))
const IdCheck = lazy(() => import('src/components-pages/onboarding/IdCheck'))
const PersonalDetails = lazy(() => import('src/components-pages/onboarding/PersonalDetails'))
const SmsSetup = lazy(() => import('src/components-pages/onboarding/SmsSetup'))
const EnterEmail = lazy(() => import('src/components-pages/forgot-password/EnterEmail'))
const ResetPassword = lazy(() => import('src/components-pages/forgot-password/ResetPassword'))
const QRVerify = lazy(() => import('src/components-pages/QRVerify'))
const Sms = lazy(() => import('src/components-pages/Sms'))
const VerifyEmail = lazy(() => import('src/components-pages/VerifyEmail'))
const UploadDocumentsList = lazy(
  () =>
    import('src/components-pages/Company/Accountancy/steps/UploadDocuments/UploadDocumentsList'),
)
const StockUploadPreviousDocuments = lazy(
  () =>
    import(
      'src/components-pages/Company/Accountancy/steps/UploadDocuments/StockUploadPreviousDocuments'
    ),
)
const SellOrderForm = lazy(() => import('src/components-pages/Investment/Sell/OrderForm'))
const SignAccountsDocuments = lazy(() => import('src/components-pages/SignAccountsDocuments'))
const SourceOfFunds = lazy(
  () => import('src/components-pages/onboarding/AcceptanceOfRole/SourceOfFunds'),
)
const OnboardingFeedback = lazy(() => import('src/components-pages/onboarding/Feedback'))
const DocumentSignFeedback = lazy(() => import('src/components-pages/Documents/Feedback'))
const MembersApproval = lazy(() => import('src/components-pages/Documents/MembersApproval'))
const DirectorsApproval = lazy(() => import('src/components-pages/Documents/DirectorsApproval'))

const SelectionScreen = lazy(() => import('src/components-pages/HoldingCo/SelectionScreen'))
const HoldingCo = lazy(() => import('src/components-pages/HoldingCo/'))
const HoldingCoSelect = lazy(() => import('src/components-pages/HoldingCo/HoldingCoSelect'))
const HoldingCoShareholders = lazy(
  () => import('src/components-pages/HoldingCo/HoldingCoShareholders'),
)
const HoldingCoPurchaseDetails = lazy(
  () => import('src/components-pages/HoldingCo/HoldingCoPurchaseDetails'),
)
const HoldingCoTaxQuestions = lazy(
  () => import('src/components-pages/HoldingCo/HoldingCoTaxQuestions'),
)
const HoldingCoSolicitorDetails = lazy(
  () => import('src/components-pages/HoldingCo/HoldingCoSolicitorDetails'),
)
const HoldingCoReview = lazy(() => import('src/components-pages/HoldingCo/HoldingCoReview'))
const HoldingCoPayment = lazy(() => import('src/components-pages/HoldingCo/HoldingCoPayment'))
const BrowseProperty = lazy(() => import('src/components-pages/BrowseProperty'))
const PropertyReports = lazy(() => import('src/components-pages/BrowseProperty/PropertyReports'))
const ListingDetails = lazy(() => import('src/components-pages/ListingDetails'))
const GetProperty = lazy(() => import('src/components-pages/GetProperty'))
const SecureUploadPortal = lazy(() => import('src/components-pages/SecureUploadPortal'))
const SignDocuments = lazy(() => import('src/components-pages/PPChangeRequest/SignDocuments'))
const PropertyReport = lazy(
  () => import('src/components-pages/Packages/PropertyReportsTab/components/PropertyReport'),
)

const PublicMarketplace = lazy(() => import('src/components-pages/PublicMarketplace'))
const Listing = lazy(() => import('src/components-pages/PublicMarketplace/Listing'))
const SavedListings = lazy(() => import('src/components-pages/PublicMarketplace/SavedListings'))
const ProductsCompanyLearnMore = lazy(
  () => import('src/components-pages/Products/CompanyLearnMore'),
)
const TokenExpired = lazy(() => import('src/components-pages/TokenExpired'))
const Properties = lazy(() => import('src/components-pages/PublicMarketplace/Properties'))
const Login = lazy(() => import('src/components-pages/Login'))
const NotFound = lazy(() => import('src/components-pages/404'))

const SellProperty = lazy(() => import('src/components-pages/PublicMarketplace/SellProperty'))
const ExploreServices = lazy(() => import('src/components-pages/PublicMarketplace/ExploreServices'))
const PublicValuation = lazy(() => import('src/components-pages/PublicMarketplace/PublicValuation'))

/* Stock company - start */
const CompanyOnboarding = lazy(
  () => import('src/components-pages/CompanyOnboarding/CompanyOnboarding'),
)
const StockOnboardingStart = lazy(
  () => import('src/components-pages/CompanyOnboarding/StockOnboardingStart/StockOnboardingStart'),
)
const StockInitialCompanyDetails = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingStart/components/StockInitialCompanyDetails/'
    ),
)
const StockOnboardingContinue = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingContinue/StockOnboardingContinue'
    ),
)
const CompanyDetails = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingContinue/components/StockCompanyDetails/StockCompanyDetails'
    ),
)
const PropertyDetails = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingContinue/components/StockPropertyDetails/StockPropertyDetails'
    ),
)
const StockAccountantDetails = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingContinue/components/StockAccountantDetails/StockAccountantDetails'
    ),
)

const StockPaymentDetails = lazy(
  () =>
    import(
      'src/components-pages/CompanyOnboarding/StockOnboardingStart/components/StockPaymentDetails/StockPaymentDetails'
    ),
)

const StockCongratulationsPage = lazy(
  () => import('src/components-pages/CompanyOnboarding/components/OnboardingComplete'),
)
/* Stock company - end */

/* PLL - start */
const LandLordOnboardingWrapper = lazy(
  () => import('src/components-pages/LandLordOnboarding/LandLordOnboardingWrapper'),
)
const LandLordOnboarding = lazy(() => import('src/components-pages/LandLordOnboarding'))
const LandLordCheckAvailability = lazy(
  () => import('src/components-pages/LandLordOnboarding/EligibilityCheck'),
)
const LandLordOnboardingPropertyOwnership = lazy(
  () => import('src/components-pages/LandLordOnboarding/PropertyOwnership'),
)
const LandLordOnboardingUserGroup = lazy(
  () => import('src/components-pages/LandLordOnboarding/UserGroup'),
)
const LandLordOnboardingProperty = lazy(
  () => import('src/components-pages/LandLordOnboarding/Property'),
)
const LandLordOnboardingPropertyAddress = lazy(
  () => import('src/components-pages/LandLordOnboarding/PropertyAddress'),
)
const LandLordOnboardingPropertyPurchase = lazy(
  () => import('src/components-pages/LandLordOnboarding/PropertyPurchase'),
)

const LandLordOnboardingPropertyReview = lazy(
  () => import('src/components-pages/LandLordOnboarding/PropertyReview'),
)
/* PLL - end */

/* Products Packages discover flow - start */
const PackagesCompanyDetails = lazy(
  () => import('src/components-pages/ProductsPackages/Company/PackagesCompanyDetails'),
)
const PackagesStockCompanyDetails = lazy(
  () => import('src/components-pages/ProductsPackages/StockCompany/PackagesStockCompanyDetails'),
)
const PackagesMortgageDetails = lazy(
  () => import('src/components-pages/ProductsPackages/Mortgage/PackagesMortgageDetails'),
)
const PackagesLNMDetails = lazy(
  () => import('src/components-pages/ProductsPackages/LettingsAndManagement/PackagesLNMDetails'),
)
const PackagesCompanyCheckout = lazy(
  () => import('src/components-pages/ProductsPackages/CompanyCheckout'),
)

const PackagesLandlordDetails = lazy(
  () => import('src/components-pages/ProductsPackages/Landlord/PackagesLandlordDetails'),
)

/* Products Packages discover flow - end */

const AccountMigration = lazy(() => import('src/components-pages/AccountMigration'))

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<AppWrapper />}>
      <Route path="*" element={<AppRoutes />} />
    </Route>,
  ),
)

/**
 * Move following routes to above `routes` object when we need support of data APIs
 * Because of having routes shows conditionally based on flags, we are keeping routes below because
 * conditional routes aren't supported when loading routes in router (with data APIs)
 * https://github.com/remix-run/react-router/issues/9422#issuecomment-1302564759
 * https://github.com/remix-run/react-router/issues/9422#issuecomment-1486960784
 */
function AppRoutes() {
  const {
    isWebSessionAccessEnabled,
    personalLandlords,
    marketplacePartnerUpsells,
    packagesCompanyServices,
    packagesCheckout,
    profitAndLossInsight,
  } = useFlags()

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="onboarding/acceptance-of-role" element={<AcceptanceOfRole />} />
      <Route path="onboarding/acceptance-of-role/source-of-funds" element={<SourceOfFunds />} />
      <Route path="onboarding/acceptance-of-role/decline" element={<DeclineRole />} />
      <Route path="onboarding/choose-2FA" element={<Choose2FA />} />
      <Route path="onboarding/feedback" element={<OnboardingFeedback />} />
      <Route path="onboarding/sms-setup" element={<SmsSetup />} />
      <Route path="onboarding/create-account" element={<CreateAccount />} />

      <Route path="invite/:referralCode" element={<ReferralInvite />} />
      <Route path="client-sign-up" element={<ClientSignUp />} />
      <Route path="forgot-password/enter-email" element={<EnterEmail />} />
      <Route path="forgot-password/reset" element={<ResetPassword />} />
      <Route path="qr-verify" element={<QRVerify />} />
      <Route path="sms" element={<Sms />} />
      <Route path="verify_email" element={<VerifyEmail />} />
      <Route path="company-onboarding" element={<Redirect to="/dashboard" />} />
      <Route path="buy-to-let-property-for-sale" element={<PublicMarketplace />}>
        <Route path="" element={<Listing />}>
          <Route index element={<Properties />} />
          <Route path="*" element={<Redirect to="/buy-to-let-property-for-sale" />} />
        </Route>

        <Route path="property-information/:listingID" element={<ListingDetails />} />
        <Route path="development-information/:developmentID" element={<DevelopmentDetails />} />
        <Route path="sell-property" element={<SellProperty />} />
        {marketplacePartnerUpsells && (
          <Route path="explore-services" element={<ExploreServices />} />
        )}
        <Route path="sell-property/:valuationShareID" element={<PublicValuation />} />
        <Route path="*" element={<Redirect to="/buy-to-let-property-for-sale" />} />
      </Route>
      <Route path="/users/:id" element={<Redirect to="/new-path/:id" />} />
      <Route
        path="property-listing/property-information/:listingID"
        element={<Redirect to="/buy-to-let-property-for-sale/property-information/:listingID" />}
      />
      <Route
        path="property-listing/development-information/:developmentID"
        element={
          <Redirect to="/buy-to-let-property-for-sale/development-information/:developmentID" />
        }
      />
      <Route path="token-expired" element={<TokenExpired />} />
      <Route path="compare-limited-company-buy-to-let-mortgages" element={<PublicMortgageLayout />}>
        <Route index element={<MortgageCalculator />} />
        <Route path="selected-mortgages" element={<MortgageSelectedLenders />} />
        <Route path="mortgage-quotes" element={<MortgageQuote />} />
      </Route>

      <Route path="get-property" element={<GetProperty />}>
        <Route path="property-information/:listingID" element={<ListingDetails />} />
        <Route path="development-information/:developmentID" element={<DevelopmentDetails />} />
      </Route>

      {/* All routes below are accessible for web session user */}
      <Route element={<ProtectedRoutes webSessionAccessible={isWebSessionAccessEnabled} />}>
        <Route path="create-company" element={<SelectionScreen />} />
        <Route path="onboarding/personal-details" element={<PersonalDetails />} />
      </Route>

      {/* All routes below are for authenticated users */}
      <Route element={<ProtectedRoutes webSessionAccessible={false} />}>
        <Route path="referral" element={<ReferralCode />} />
        <Route path="account/create-signature" element={<CreateSignature />} />
        <Route path="account/draw-signature" element={<DrawSignature />} />
        <Route path="account/signature-setup" element={<SignatureSetup />} />
        <Route path="account/upload-signature" element={<UploadSignature />} />
        <Route path="account/setup-signature" element={<SignatureSetupNew />} />
        <Route path="holdingco" element={<HoldingCo />}>
          <Route path="select" element={<HoldingCoSelect />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* HoldingCo + HoldingCo SPV company creation flow */}
        <Route path="holdingco/shareholder-details" element={<HoldingCoShareholders />} />
        <Route path="holdingco/purchase-details" element={<HoldingCoPurchaseDetails />} />
        <Route path="holdingco/tax-questions" element={<HoldingCoTaxQuestions />} />
        <Route path="holdingco/solicitor-details" element={<HoldingCoSolicitorDetails />} />
        <Route path="holdingco/review" element={<HoldingCoReview />} />
        <Route path="holdingco/payment" element={<HoldingCoPayment />} />
        {/* Stand-alone SPV company creation flow */}
        <Route path="company-design/payment" element={<Payment />} />
        <Route path="company-design/purchase-details" element={<PurchaseDetails />} />
        <Route path="company-design/shareholder-details" element={<ShareholderDetails />} />
        <Route path="company-design/tax-questions" element={<TaxQuestions />} />
        <Route path="company-design/solicitor-details" element={<SolicitorDetails />} />
        <Route path="company-design/company-name" element={<CompanyName />} />
        <Route path="company-design/review" element={<CompanyDesignReview />} />

        {/* Stand-alone SPV and holdco companies congratulations page */}
        <Route path="congratulations" element={<CompanyDesignCongratulations />} />

        {/* PLL wallet creation flow */}
        {personalLandlords && (
          <>
            <Route path="landlord-onboarding" element={<LandLordOnboardingWrapper />}>
              <Route path="" element={<LandLordOnboarding />} />
              <Route path="eligibility-check" element={<LandLordCheckAvailability />} />
              <Route path=":id" element={<LandLordOnboardingUserGroup />}>
                <Route index element={<Redirect to="property-ownership" />} />
                <Route
                  path="property-ownership"
                  element={<LandLordOnboardingPropertyOwnership />}
                />
                <Route element={<LandLordOnboardingProperty />}>
                  <Route path="property-address" element={<LandLordOnboardingPropertyAddress />} />
                  <Route
                    path="property-purchase"
                    element={<LandLordOnboardingPropertyPurchase />}
                  />
                  <Route path="property-review" element={<LandLordOnboardingPropertyReview />} />
                </Route>
                <Route path="*" element={<Redirect to="property-ownership" />} />
              </Route>
            </Route>
          </>
        )}
        <Route path="secure-upload-portal" element={<SecureUploadPortal />} />
        <Route
          path="companies/:companyID/*"
          element={<Redirect to="/portfolio/companies/:companyID" />}
        />
        <Route path="dashboard" element={<Redirect to="/today" />} />
        <Route path="investments/*" element={<Redirect to="/portfolio" />} />
        <Route
          path="browse-property/*"
          element={<Redirect to="/discover" shouldSuffixPathname />}
        />
        <Route path="today" element={<Today />}>
          <Route index element={<TodayPage />} />
          <Route path="tasks" element={<AllTasksPage />} />
        </Route>

        <Route path="bookkeeping" element={<BookkeepingPage />} />
        {profitAndLossInsight && (
          <Route path="insights" element={<Insights />}>
            <Route index element={<Redirect to="/insights/profit-loss-analysis" />} />
            <Route path="profit-loss-analysis" element={<ProfitAndLossAnalysis />} />
            <Route path="*" element={<Redirect to="/insights/profit-loss-analysis" />} />
          </Route>
        )}
        <Route path="recommendations" element={<RecommendationsPage />} />

        <Route path="portfolio">
          <Route element={<Portfolio />}>
            <Route index element={<Navigate to="transactions" replace />} />
            <Route path="transactions" element={<PortfolioTransactions />} />
            <Route path="companies" element={<PortfolioCompanies />} />
            <Route path="properties" element={<PortfolioProperties />} />
          </Route>

          <Route path="company_setup/:id" element={<PortfolioCompanySetup />} />
          <Route path="companies/:id" element={<PortfolioCompany />} />
          <Route path="companies/in-progress" element={<PortfolioCompaniesAllInProgress />} />
          <Route path="companies/:id/settings" element={<PortfolioCompanySettings />} />
          <Route path="companies/:id/documents" element={<PortfolioCompanyDocuments />} />
          <Route path="companies/:id/billing" element={<PortfolioCompanyBilling />} />
          <Route path="properties/:id" element={<PortfolioProperty />} />
          <Route path="properties/:id/completion" element={<PropertyCompletion />} />
          <Route path="rent-tracker" element={<PortfolioRentTracker />} />

          <Route path="wallets" element={<PortfolioWallets />} />
          <Route path="all-transactions" element={<PortfolioAllTransactions />} />
          <Route path="transactions/:id" element={<PortfolioTransactionDetails />} />

          <Route path="wallets/:id" element={<PortfolioWallet />}>
            <Route index path="transactions" element={<PortfolioWalletTransactions />} />
            <Route path="companies" element={<PortfolioWalletCompany />} />
            <Route path="properties" element={<PortfolioWalletProperty />} />
            <Route path="" element={<Redirect to="transactions" />} />
          </Route>
          <Route
            path="wallets/:id/confirm-shareholders"
            element={<PortfolioConfirmShareholders />}
          />
          <Route path="wallets/:id/all-transactions" element={<PortfolioAllTransactions />} />
          <Route path="wallets/:id/account-details" element={<PortfolioAccountDetails />} />
          <Route path="wallets/:id/add-money" element={<PortfolioAccountAddMoney />} />
          <Route path="wallets/:id/add-money/uk-account" element={<PortfolioAddMoneyUKAccount />} />
          <Route
            path="wallets/:id/add-money/international-account"
            element={<PortfolioAddMoneyInternationalAccount />}
          />
          <Route path="wallets/:id/payment" element={<PortfolioSendMoney />}>
            <Route index path="" element={<PortfolioAccountPayment />} />
            <Route path="payee-details" element={<PortfolioAccountPayee />} />
            <Route path="payee-list" element={<PortfolioAccountPayeeList />} />
            <Route path="payee-details/:payeeID" element={<PortfolioAccountPayee />} />
            <Route path="company-details" element={<PortfolioAccountCompanies />} />
            <Route path="payment-details" element={<PortfolioAccountPaymentDetails />} />
            <Route path="payment-confirmation" element={<PortfolioAccountPaymentConfirmation />} />
          </Route>
          <Route path="wallets/:id/regular-payments" element={<PortfolioAccountRegularPayments />}>
            <Route path="all" element={<PortfolioAllPaymentPage />} />
            <Route path="standing-orders" element={<PortfolioStandingOrders />} />
            <Route path="direct-debits" element={<PortfolioDirectDebit />} />
            <Route path="" element={<Redirect to="all" />} />
          </Route>

          <Route
            path="wallets/:id/regular-payments/:standingOrderID/standing-details"
            element={<PortfolioRegularPaymentDetails />}
          />

          <Route
            path="wallets/:id/regular-payments/:directdebitID/direct-debit-details"
            element={<PortfolioRegularPaymentDetails />}
          />

          <Route
            path="wallets/:id/regular-payments/edit-standing-details/:standingOrderID"
            element={<PortfolioEditStandingOrder />}
          >
            <Route index path="" element={<PortfolioEditStandingOrderForm />} />
            <Route index path="confirmation" element={<PortfolioEditStandingOrderConfirm />} />
          </Route>

          <Route path="add" element={<AddToPortfolio />} />
          <Route path="add/company" element={<AddCompanyToPortfolio />} />
          <Route path="add/property" element={<AddPropertyToPortfolio />} />
          <Route path="property-onboarding/:onboardingID" element={<MultiPropertyOnboarding />} />
          <Route
            path="property-onboarding/:onboardingID/property-details/:propertyID"
            element={<MultiPropertyOnboardingPropertyDetails />}
          />
          <Route
            path="property-onboarding/:onboardingID/payment"
            element={<UpdateSubscription />}
          />
          <Route
            path="property-onboarding/:onboardingID/document"
            element={<MultiPropertyDocuments />}
          />

          <Route path="wallets/:id/pot" element={<PortfolioPot />}>
            <Route path="activity" element={<PortfolioPotActivity />} />
            <Route path="faq" element={<PortfolioPotFAQ />} />
            <Route index element={<Redirect to="activity" />} />
          </Route>
          <Route path="wallets/:id/pot/add-money" element={<PortfolioPotAddMoney />} />
          <Route path="wallets/:id/pot/withdraw" element={<PortfolioPotWithdraw />} />
          <Route
            path="wallets/:id/pot/monthly-deposit/:depositID"
            element={<PortfolioPotEditDeposit />}
          />
        </Route>
        <Route path="discover">
          <Route element={<Discover />}>
            <Route index element={<Navigate to="company-services" replace />} />
            <Route path="company-services" element={<DiscoverCompanyTab />} />
            <Route path="property-services" element={<DiscoverPropertyTab />} />
            <Route path="financial-services" element={<DiscoverFinanceTab />} />
          </Route>
          <Route path="browse-property" element={<BrowseProperty />}>
            <Route path="*" element={<Listing />}>
              <Route index element={<Properties />} />
              <Route path="saved-properties" element={<SavedListings />} />
            </Route>
            {marketplacePartnerUpsells && (
              <Route path="explore-services" element={<ExploreServices />} />
            )}
            <Route path="property-reports" element={<PropertyReports />} />
            <Route path="property-reports/:reportID" element={<PropertyReport />} />
            <Route path="*" element={<Redirect to="/browse-property" />} />
          </Route>

          {packagesCompanyServices && (
            <>
              <Route path="company" element={<PackagesCompanyDetails />} />
              <Route path="existing-company" element={<PackagesStockCompanyDetails />} />
              <Route path="mortgage" element={<PackagesMortgageDetails />} />
              <Route path="landlord" element={<PackagesLandlordDetails />} />
              <Route path="lettings-and-management" element={<PackagesLNMDetails />} />
            </>
          )}
          {packagesCheckout && (
            <Route path="company-checkout" element={<PackagesCompanyCheckout />} />
          )}
        </Route>
        <Route path="switch-account" element={<AccountMigration />} />
        {/* Add property to company */}
        <Route path="investments/:investmentID/property-address" element={<PropertyAddress />} />
        <Route path="investments/:investmentID/insurance" element={<Insurance />} />
        <Route path="investments/:investmentID/property-agent" element={<PropertyAgent />} />
        <Route path="investments/:investmentID/lettings" element={<Lettings />} />
        <Route
          path="investments/:investmentID/property-review"
          element={<PropertyDetailsReview />}
        />
        {/*  */}
        <Route
          path="investments/:investmentID/property-request-change"
          element={<RequestChangeProperty />}
        >
          <Route index element={<RequestChangePropertyReview />} />
          <Route path="property-address" element={<PropertyAddressForm />} />
          <Route path="purchase-details" element={<PurchaseDetailsForm />} />
        </Route>
        <Route path="investments/:investmentID/sell/order-form" element={<SellOrderForm />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="rent-tracker" element={<RentTracker />} />
        <Route path="company/:id/request-change" element={<RequestChange />} />
        <Route path="sign-documents" element={<SignDocuments />} />
        <Route path="company/:id/accountancy" element={<Accountancy />}>
          <Route index element={<Intro />} />
          <Route path="upload-documents/property-documents" element={<UploadDocumentsList />} />
          <Route
            path="upload-documents/upload-previous-documents"
            element={<StockUploadPreviousDocuments />}
          />
          <Route path="upload-documents" element={<UploadDocumentsList />} />
          <Route path="property-information" element={<PropertyInformation />} />
          <Route path="property-information/completion-date" element={<CompletionDate />} />
          <Route path="property-information/property-valuation" element={<PropertyValuation />} />
          <Route path="property-information/tenants" element={<Tenants />} />
          <Route path="property-information/mortgage" element={<Mortgage />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="upload-transactions" element={<UploadTransactions />} />
          <Route path="cashflows" element={<Cashflows />} />
          <Route path="review" element={<Review />} />
          <Route path="confirmation" element={<AccountancyConfirmation />} />
          <Route path="complete" element={<AccountingCongratulationsPage />} />
        </Route>
        <Route
          path="company/:companyID/accounts-documents/:submissionID"
          element={<SignAccountsDocuments />}
        />
        <Route path="documents" element={<Documents />} />
        <Route path="documents/members-approval/:companyID" element={<MembersApproval />} />
        <Route path="documents/directors-approval/:companyID" element={<DirectorsApproval />} />
        <Route path="documents/feedback" element={<DocumentSignFeedback />} />
        <Route path="documents/prompt" element={<Prompt />} />
        <Route path="products/company" element={<ProductsCompanyLearnMore />} />
        <Route path="onboarding/id-check" element={<IdCheck />} />
        <Route path="company-onboarding/:stockCompanyID" element={<CompanyOnboarding />}>
          <Route index element={<StockOnboardingStart />} />
          <Route path="company-details" element={<StockInitialCompanyDetails />} />
          <Route path="payment-details" element={<StockPaymentDetails />} />
          <Route path="know-your-business" element={<StockOnboardingContinue />} />
          <Route
            path="know-your-business/property-details/:propertyID"
            element={<PropertyDetails />}
          />
          <Route path="know-your-business/company-details" element={<CompanyDetails />} />
          <Route
            path="know-your-business/accountant-details"
            element={<StockAccountantDetails />}
          />
          <Route path="complete" element={<StockCongratulationsPage />} />
        </Route>
        <Route path="products/mortgages" element={<PrivateMortgageLayout />}>
          <Route index element={<MortgageCalculator />} />
          <Route path="selected-lenders" element={<MortgageSelectedLenders />} />
          <Route path="quote" element={<MortgageQuote />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default routes
