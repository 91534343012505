export enum InvestmentPotStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export enum PotTransactionStatus {
  MATCHED = 'Matched',
  SETTLED = 'Settled',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
}
export enum PotTransactionType {
  BUY = 'Buy',
  SELL = 'Sell',
  DEPOSIT = 'Deposit',
  WITHDRAWAL = 'Withdrawal',
  FEE = 'Fee',
}

export interface InvestmentPot {
  id: string
  status: InvestmentPotStatus
}

export interface InvestmentPotBalance {
  cash_balance_in_minor_units: number
  holdings_balance_in_minor_units: number
}

export interface InvestmentPotTransaction {
  id?: string
  type: PotTransactionType
  status: PotTransactionStatus
  date: string
  consideration: {
    currency: 'GBP'
    amount: number
  }
}

export enum PotDepositInterval {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export interface RegularPotDeposit {
  id: number
  consideration: {
    currency: string
    amount_in_minor_units: number
  }
  recurrence: {
    interval: PotDepositInterval
    day_of_interval: number
  }
}
