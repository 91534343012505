import { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import Loader from 'src/components/Loader2/Loader'
import routes from './AppWrapper/AppRoutes'

import 'src/styles/main.scss'

function App() {
  return (
    <Suspense fallback={<Loader customLoading />}>
      <RouterProvider router={routes} />
    </Suspense>
  )
}

export default withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID,
  context: {
    kind: 'user',
    key: window?.sessionStorage?.getItem('userID') || 'user-not-logged-in',
    country: window?.sessionStorage?.getItem('userCountry') || 'unknown',
    signup_source: window?.sessionStorage?.getItem('utm_campaign'),
  },
})(App)
