import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const GCLID_STORAGE_KEY = 'gclid'
export const UTM_CAMPAIGN_STORAGE_KEY = 'utm_campaign'
export const UTM_CONTENT_STORAGE_KEY = 'utm_content'
export const UTM_MEDIUM_STORAGE_KEY = 'utm_medium'
export const UTM_SOURCE_STORAGE_KEY = 'utm_source'
export const UTM_TERM_STORAGE_KEY = 'utm_term'
export const START_EXISTING_COMPANY_ONBOARDING = 'startExistingCompanyOnboarding'

const PARAMS_TO_KEYS = {
  gclid: GCLID_STORAGE_KEY,
  utm_campaign: UTM_CAMPAIGN_STORAGE_KEY,
  utm_content: UTM_CONTENT_STORAGE_KEY,
  utm_medium: UTM_MEDIUM_STORAGE_KEY,
  utm_source: UTM_SOURCE_STORAGE_KEY,
  utm_term: UTM_TERM_STORAGE_KEY,
  startExistingCompanyOnboarding: START_EXISTING_COMPANY_ONBOARDING,
}

export function useSaveParams(): void {
  const { search } = useLocation()

  useEffect(() => {
    const params = queryString.parse(search)
    const keys = Object.keys(params)
    Object.keys(PARAMS_TO_KEYS).forEach((key) => {
      if (keys.includes(key) && key in params) {
        sessionStorage.setItem(PARAMS_TO_KEYS[key], String(params[key] ?? ''))
      }
    })
  }, [search])
}
