import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ReferralCodeSliceState } from 'src/types/Referrals'
import {
  getReferralCodes as apiGetReferralCodes,
  postReferralCode as apiPostReferralCode,
  getUserReferredByCode as apiGetUserReferredByCode,
} from 'src/api/ggAPI'

export const initialState: ReferralCodeSliceState = {
  error: null,
  referralCodes: [],
  isLoading: false,
  storedReferralCode: null,
  referredByCode: null,
}

export const postReferralCode = createAsyncThunk('referralCodes/postReferralCode', () =>
  apiPostReferralCode(),
)

export const getReferralCodes = createAsyncThunk('referralCodes/getReferralCodes', () =>
  apiGetReferralCodes(),
)

export const getReferredByCode = createAsyncThunk('referralCodes/getReferredByCode', () =>
  apiGetUserReferredByCode(),
)

const referralCodeSlice = createSlice({
  name: 'referralCodeSlice',
  initialState,
  reducers: {
    setStoredReferralCode: (state, action) => ({
      ...state,
      storedReferralCode: action.payload,
    }),
    setStoredReferralType: (state, action) => ({
      ...state,
      storedReferralType: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferralCodes.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: 'Something went wrong.  Please try again',
      }))
      .addCase(getReferralCodes.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        error: null,
        ...(action?.payload?.referralCodes ? { referralCodes: action.payload.referralCodes } : {}),
      }))
      .addCase(getReferralCodes.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(postReferralCode.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: 'Something went wrong.  Please try again',
      }))
      .addCase(postReferralCode.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          referralCodes: action?.payload?.referralCodes,
        }
      })
      .addCase(postReferralCode.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getReferredByCode.rejected, (state) => ({
        ...state,
        isLoading: false,
        error: 'Something went wrong.  Please try again',
      }))
      .addCase(getReferredByCode.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null,
          referredByCode: action?.payload?.referralCodes ?? [],
        }
      })
      .addCase(getReferredByCode.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
  },
})

export const { setStoredReferralCode, setStoredReferralType } = referralCodeSlice.actions

export default referralCodeSlice.reducer
