import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'

import PropertyPackage from 'src/types/PropertyPackage'

interface InitialState {
  packages: PropertyPackage[]
  firstSectionHeight: number
  secondSectionHeight: number
  isExploreFinancingModalOpen: boolean
  arePackagesExpanded: boolean
  isRequestCallLoading: boolean
}

export const initialState: InitialState = {
  packages: [
    {
      type: 'helping_hand',
      title: 'Helping hand',
      description:
        "You're happy doing some of the work, but could use a little expert guidance along the way.",
      costs: {
        price: 1588,
        worth: 4000,
        payment_parts: {
          parts: 'two',
          price: 250,
        },
      },
      details: {
        offers: [
          'High LTV mortgages',
          'Personal consultation',
          'Property Masterclass',
          'Company set-up fee included',
          'Property support from search to purchase',
          'Mortgage application support',
          'Conveyancing support',
          'Tenanting support',
          'Support for non-UK residents',
          'Free 6-month GetGround subscription',
          'In-depth property analysis',
          'Dedicated Relationship Manager',
        ],
      },
    },
    {
      type: 'peace_of_mind',
      title: 'Peace of mind',
      description:
        "You're a first-timer, a foreign investor, or you'd rather just leave all the hassle to us.",
      costs: {
        price: 5888,
        worth: 9400,
        payment_parts: {
          parts: 'three',
          price: 250,
        },
      },
      details: {
        title: 'Everything in Helping hand, plus',
        offers: [
          'Mortgage broker fees included',
          'Conveyancing fees included',
          'Tenanting fees included',
          '10% furniture discount',
          'Additional 6-months free GetGround subscription (12 months total)',
        ],
      },
    },
  ],
  // These two are to make first and second sections from the package items the same based on the designs from Figma
  // The height of both sections can vary based on the content
  firstSectionHeight: 0,
  secondSectionHeight: 0,
  isExploreFinancingModalOpen: false,
  arePackagesExpanded: false,
  isRequestCallLoading: false,
}

export const requestCall = createAsyncThunk(
  'requestCall/propertyPackages',
  ({ payload }: { payload: any }, { getState }) => {
    const state = getState()
    const userID = state.user.id
    return api.requestCall({ userID, ...payload })
  },
)

export const requestCallPublicEnquiry = createAsyncThunk(
  'publicRequestCall/propertyPackages',
  ({ payload }: { payload: any }) => {
    return api.requestPublicEnquiryCall({ ...payload })
  },
)

const propertyPackagesSlice = createSlice({
  name: 'listingProperties',
  initialState,
  reducers: {
    handlePackagesExpand: (state, action) => ({
      ...state,
      arePackagesExpanded: action.payload,
    }),
    setFirstSectionHeight: (state, action) => ({
      ...state,
      firstSectionHeight:
        action.payload > state.firstSectionHeight ? action.payload : state.firstSectionHeight,
    }),
    setSecondSectionHeight: (state, action) => ({
      ...state,
      secondSectionHeight:
        action.payload > state.secondSectionHeight ? action.payload : state.secondSectionHeight,
    }),
    closeDiscoverPackagesModal: (state) => ({
      ...state,
      isExploreFinancingModalOpen: false,
    }),

    setLoading: (state, action) => ({
      ...state,
      isRequestCallLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestCall.fulfilled, (state) => ({
      ...state,
      isExploreFinancingModalOpen: true,
    }))

    builder.addCase(requestCallPublicEnquiry.fulfilled, (state) => ({
      ...state,
      isExploreFinancingModalOpen: true,
    }))
  },
})

export const {
  handlePackagesExpand,
  setFirstSectionHeight,
  setSecondSectionHeight,
  setLoading,
  closeDiscoverPackagesModal,
} = propertyPackagesSlice.actions

export default propertyPackagesSlice.reducer
