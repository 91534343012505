import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createMortgageQuote as apiCreateMortgageQuote,
  createMortgageProduct as apiCreateMortgageProduct,
  createLendersQuote as apiCreateLendersQuote,
  updateMortgageProduct as apiUpdateMortgageProduct,
  createEquityRelease as apiCreateEquityRelease,
  MortgageProductRequest,
  MortgageQuoteRequest,
  getMortgageDetails as apiGetMortgageDetails,
  deleteMortgageDetails as apiDeleteMortgageDetails,
  EquityReleaseRequest,
  LendersQuoteResponse,
  MortgageQuoteResponse,
  type PublicMortgageProductRequest,
} from 'src/api/ggAPI'
import { MortgageDetails, MortgageDetailsResponse, MortgageFormData } from 'src/types/Mortgage'
import { getInvestment } from './investment.slice'

export const createMortgageQuote = createAsyncThunk(
  'mortgage/createMortgageQuote',
  (request: MortgageQuoteRequest, { rejectWithValue }) =>
    apiCreateMortgageQuote(request).catch(({ status, response }) =>
      rejectWithValue({ code: status, message: response?.data?.error }),
    ),
)

export const createLendersQuote = createAsyncThunk(
  'mortgage/createLendersQuote',
  (request: MortgageQuoteRequest, { rejectWithValue }) =>
    apiCreateLendersQuote(request).catch(({ status, response }) =>
      rejectWithValue({ code: status, message: response?.data?.error }),
    ),
)

export const createMortgageProduct = createAsyncThunk(
  'mortgage/createMortgageProduct',
  (request: MortgageProductRequest | PublicMortgageProductRequest, { rejectWithValue }) =>
    apiCreateMortgageProduct(request).catch(({ status }) => rejectWithValue({ code: status })),
)

export const getMortgageDetails = createAsyncThunk(
  'mortgage/getMortgageDetails',
  ({ investmentID }: { investmentID: number }) => apiGetMortgageDetails({ investmentID }),
)

export const updateMortgageProduct = createAsyncThunk(
  'mortgage/updateMortgageProduct',
  (request: Partial<MortgageDetails>, { rejectWithValue, dispatch }) =>
    apiUpdateMortgageProduct(request)
      .then((response) => {
        dispatch(getInvestment({ investmentID: request.investment_id }))
        return response
      })
      .catch(({ status }) => rejectWithValue({ code: status })),
)

export const deleteMortgageDetails = createAsyncThunk(
  'mortgage/deleteMortgageDetails',
  (investmentID: number, { rejectWithValue, dispatch }) =>
    apiDeleteMortgageDetails({ investmentID })
      .then((response) => {
        dispatch(getInvestment({ investmentID }))
        return response
      })
      .catch(({ status }) => rejectWithValue({ code: status })),
)

export const createEquityRelease = createAsyncThunk(
  'mortgage/createEquityRelease',
  (request: EquityReleaseRequest, { rejectWithValue }) =>
    apiCreateEquityRelease(request).catch(({ status }) => rejectWithValue({ code: status })),
)

interface InitialState {
  data: MortgageFormData | null
  quote: MortgageQuoteResponse['quotes'] | null

  panel: LendersQuoteResponse['quotes'] | null
  isLoading: boolean
  isLoadingPanel: boolean
  mortgageProduct: MortgageDetailsResponse | null
}

export const mortgageSlice = createSlice({
  name: 'mortgage',
  initialState: {
    data: null,
    quote: null,
    panel: null,
    isLoading: true,
    isLoadingPanel: true,
    mortgageProduct: null,
  } as InitialState,
  reducers: {
    setMortgageData: (state, action) => ({
      ...state,
      data: { ...action.payload, shouldCreateQuote: action.payload.shouldCreateQuote ?? true },
    }),
    setShouldCreateQuote: (state, action) => {
      if (state.data) {
        state.data.shouldCreateQuote = action.payload
      }
    },
    clearQuotes: (state) => ({ ...state, quote: null }),
    clearMortgageData: (state) => ({
      ...state,
      data: null,
    }),
    clearMortgageProduct: (state) => ({
      ...state,
      mortgageProduct: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(createMortgageQuote.pending, (state) => ({
      ...state,
      isLoading: true,
    }))

    builder.addCase(createMortgageQuote.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))

    builder.addCase(createMortgageQuote.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      quote: action.payload.quotes,
    }))

    builder.addCase(createLendersQuote.pending, (state) => ({
      ...state,
      isLoadingPanel: true,
    }))
    builder.addCase(createLendersQuote.fulfilled, (state, action) => ({
      ...state,
      isLoadingPanel: false,
      panel: action.payload,
    }))
    builder.addCase(createLendersQuote.rejected, (state) => ({
      ...state,
      isLoadingPanel: false,
    }))
    builder.addCase(getMortgageDetails.fulfilled, (state, action) => ({
      ...state,
      mortgageProduct: action.payload,
    }))
  },
})

export const {
  setMortgageData,
  clearQuotes,
  clearMortgageData,
  clearMortgageProduct,
  setShouldCreateQuote,
} = mortgageSlice.actions

export default mortgageSlice.reducer
