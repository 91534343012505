import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'
import { MarketData } from '../types/MarketData'

export interface Area {
  area: string
  rental_yield: number
  one_year_capital_gain: number
  rental_dwellings: number
}

export const loadAreas = createAsyncThunk('marketInsight/load-areas', () => api.getMarketAreas())
export const saveSelectedAreas = createAsyncThunk(
  'marketInsight/saveSelectedAreas',
  ({ propertyID, marketData }: { propertyID: string; marketData: MarketData }) =>
    api.saveSelectedMarketAreas(propertyID, marketData),
)

export const updateSelectedAreas = createAsyncThunk(
  'marketInsight/updateSelectedAreas',
  ({
    propertyID,
    marketData,
    serviceID,
  }: {
    propertyID: string
    marketData: MarketData
    serviceID: number
  }) => api.updateSelectedMarketAreas(propertyID, marketData, serviceID),
)

export const initialState = {
  currentSortingValue: '',
  areas: [],
  results: [],
  order: '',
  updatedAt: '',
  selectedTags: [],
}

const marketInsight = createSlice({
  name: 'marketInsight',
  initialState,
  reducers: {
    sortResults: (state, action) => {
      const newSortingValue = action.payload
      const isDifferentSortingValue =
        state.currentSortingValue.length > 0 && state.currentSortingValue !== newSortingValue
      let direction = ''
      if (isDifferentSortingValue || !state.order) {
        direction = 'desc'
      } else if (state.order === 'desc') {
        direction = 'asc'
      }

      let sortedData = []

      const initialAreas = [...state.areas]

      if (!direction) {
        sortedData = state.areas
      } else {
        sortedData = initialAreas.sort((a, b) => {
          const valueA = a[newSortingValue] || 0
          const valueB = b[newSortingValue] || 0

          if (direction === 'desc') {
            return valueB - valueA
          }

          return valueA - valueB
        })
      }

      state.order = direction
      state.results = sortedData
      state.currentSortingValue = newSortingValue
    },

    searchResults: (state, action) => {
      const searchTerm = action.payload.toLowerCase()

      const filteredData = state.areas.filter((item) => {
        const areaName = item.area.toLowerCase()

        return areaName.includes(searchTerm)
      })

      state.results = filteredData
    },
    clearData: () => initialState,
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadAreas.fulfilled, (state, action) => {
      state.areas = [...action.payload.data]
      state.results = [...action.payload.data]
      state.updatedAt = action.payload.updated_at
    })
    builder.addCase(saveSelectedAreas.fulfilled, (state, action) => {
      state.successBannerVisible = true
      state.selectedTags = []
    })
  },
})

export const { sortResults, searchResults, setSelectedTags, clearData } = marketInsight.actions

export default marketInsight.reducer
