import { type UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  getExchangeCurrencies,
  getWalletCashflow,
  getWalletInfo,
  getWallets,
  getUserAnalytics,
  walletStatement,
  getExchangeRate,
  GetExchangeRateResponse,
  getCategorySuggestions,
  getWalletMigrationStatus,
  type MigrationStatusResponse,
} from './routes'

interface WalletRequestBase {
  walletID: number
}

export const walletKeys = {
  wallet: (walletID: number) => ['wallet', walletID] as const,
  wallet_exchange_currencies: (walletID: number) =>
    ['wallet', walletID, 'exchangeCurrencies'] as const,
  wallet_cashflow: (walletID: number) => ['wallet', walletID, 'cashflow'] as const,
  wallets: ['wallets'] as const,
  wallet_statement: (walletID: number) => [...walletKeys.wallet(walletID), 'statement'] as const,
  wallet_user_balance: ['wallet', 'userBalance'] as const,
  wallet_company_accounts: ['wallet', 'companyAccounts'] as const,
  wallet_user_analytics: ['wallet', 'userAnalytics'] as const,
  wallet_exchange_rate: (currencyPair: string) =>
    ['wallet', 'exchange_rate', currencyPair] as const,
  wallet_migration: ['wallets', 'migration'] as const,
  wallet_pending_conversions: (companyID: number) =>
    ['wallet', companyID, 'pending_conversions'] as const,
  wallet_category_suggestions: (companyID: number) =>
    [...walletKeys.wallet(companyID), 'category_suggestions'] as const,
}

export const useGetWalletInfo = ({ walletID }: WalletRequestBase) =>
  useQuery({
    queryKey: walletKeys.wallet(walletID),
    queryFn: () => getWalletInfo(walletID),
    enabled: !!walletID,
    staleTime: 10 * 1000,
  })

export const useGetExchangeCurrencies = ({ walletID }: WalletRequestBase) =>
  useQuery({
    queryKey: walletKeys.wallet_exchange_currencies(walletID),
    queryFn: () => getExchangeCurrencies(walletID),
    enabled: !!walletID,
  })

export const useGetWalletCashflow = ({ walletID }: WalletRequestBase) =>
  useQuery({
    queryKey: walletKeys.wallet_cashflow(walletID),
    queryFn: () => getWalletCashflow(walletID),
    enabled: !!walletID,
    retry: false,
  })

export const useGetWallets = () =>
  useQuery({
    queryKey: walletKeys.wallets,
    queryFn: () => getWallets(),
    staleTime: 10 * 1000,
  })

export const useGetWalletStatement = (walletID: number, query: string) =>
  useQuery({
    queryKey: walletKeys.wallet_statement(walletID),
    queryFn: () => walletStatement(walletID, query),
    enabled: false,
  })

export const useGetUserAnalytics = () =>
  useQuery({
    queryKey: walletKeys.wallet_user_analytics,
    queryFn: () => getUserAnalytics(),
  })

export const useGetExchangeRate = (
  currencyPair: string,
  options?: UseQueryOptions<GetExchangeRateResponse>,
) =>
  useQuery({
    queryKey: walletKeys.wallet_exchange_rate(currencyPair),
    queryFn: () => getExchangeRate(currencyPair),
    enabled: !!currencyPair,
    ...options,
  })

export const useGetCategorySuggestions = (walletId?: number) =>
  useQuery({
    queryKey: ['categorySuggestions', walletId],
    queryFn: () => getCategorySuggestions(walletId),
    enabled: !!walletId,
  })

export const useGetMigrationStatus = (options?: UseQueryOptions<MigrationStatusResponse>) =>
  useQuery({
    queryKey: walletKeys.wallet_migration,
    queryFn: () => getWalletMigrationStatus(),
    staleTime: 10 * 1000,
    ...options,
  })
