import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import flatMapDeep from 'lodash/flatMapDeep'
import flattenDeep from 'lodash/flattenDeep'

import * as api from 'src/api/ggAPI'

import { MonthlyTransaction, Transaction } from 'src/types/Transactions'
import { DirectDebit } from '../types/DirectDebit.model'

export const getExchangeRate = createAsyncThunk('wallet/getExchangeRate', api.getExchangeRate)

export const sendExchangeCurrencyRequest = createAsyncThunk(
  'wallet/sendExchangeCurrencyRequest',
  ({
    companyID,
    amount,
    currencyPair,
  }: {
    companyID: string
    amount: number
    currencyPair: string
  }) => api.sendExchangeCurrencyRequest(companyID, amount, currencyPair),
)

export const getDirectDebits = createAsyncThunk(
  'wallet/getDirectDebits',
  ({ companyID }: { companyID: number }) => api.getDirectDebits(companyID),
)

export const cancelDirectDebit = createAsyncThunk(
  'wallet/cancelDirectDebit',
  ({ companyID, directDebitID }: { companyID: number; directDebitID: number }) =>
    api.cancelDirectDebit(companyID, directDebitID),
)

export const getWalletTransactions = createAsyncThunk(
  'wallet/getWalletTransactions',
  ({ companyID, filters = {} }: { companyID: number; filters: any }) =>
    api.getWalletTransactions(companyID, filters),
)

export const getUncategorisedWalletTransactions = createAsyncThunk(
  'wallet/getUncategorisedWalletTransactions',
  ({ companyID }: { companyID: number }) =>
    api.getWalletTransactions(companyID, {
      pagination: false,
      categories: 'UNCATEGORISED|null',
    }),
)

export const getWalletDetails = createAsyncThunk(
  'wallet/getWalletDetails',
  ({ companyID }: { companyID: number }) => api.getWalletInfo(companyID),
)

export const openInternationalAccount = createAsyncThunk(
  'wallet/openInternationalAccount',
  ({ companyID }: { companyID: number }) => api.openInternationalAccount(companyID),
)

interface TransactionPayload {
  companyID: number
  transactionID: number
  data: any
  categoryKey?: string | null
  attachment?: string | null
}

export const updateTransaction = createAsyncThunk(
  'wallet/updateTransaction',
  ({ companyID, transactionID, data }: TransactionPayload) =>
    api.updateTransaction(companyID, transactionID, data),
)

export const getPendingConversions = createAsyncThunk(
  'wallet/getPendingConversions',
  ({ companyID }: { companyID: number }) => api.getPendingConversions(companyID),
)

export const shouldDoNps = createAsyncThunk('wallet/shouldDoNps', ({ step }: { step: string }) =>
  api.shouldUserDoNPS(step),
)

const flattenTxs = <T extends MonthlyTransaction, S extends Transaction>(txs: T | T[]): S[] =>
  flattenDeep(
    txs.map((months: T[][]) =>
      flatMapDeep(months)?.map((days) => flatMapDeep(days)?.map((transaction) => transaction)),
    ),
  )

interface WalletInterface {
  exchangeRate: {
    value: number | null
    lastUpdated: string | Date | null
    ggFee: number | null
  }
  directDebits: {
    data: DirectDebit[]
    isLoading: boolean
  }
  transactions: {
    monthly: MonthlyTransaction[]
    pending: Transaction[]
    isLoading: boolean
    page: number
    itemsPerPage: number
    itemCount: number
    filters: {
      categories: string
      endDate: string
      search: string
      startDate: string
      sortAscending: boolean
    }
    uncategorisedTransactions: {
      isLoading: boolean
      data: {
        monthly: MonthlyTransaction[]
      }
    }
    isSummaryVisible: boolean
  }
  details: {
    isLoading: boolean
    internationalDetails: {
      bic: string | null
      iban: string | null
      bankName: string | null
      bankAddress: string | null
    } | null
    accountNumber: string | null
    accountName: string | null
    sortCode: string | null
    provider: string | null
    balance: string | null
    registeredAddress: string | null
    institutionName: string | null
    institutionAddress: string | null
    depositLimit: number | null
    otherBalances: { currency: string; amount: string }[]
    marketOpen: boolean
    creationDate: string | null
    disclaimer: string | null
  }
  isOpeningInternationalAccount: boolean
  shouldDoNps: boolean
}

export const initialState: WalletInterface = {
  exchangeRate: {
    value: null,
    lastUpdated: '',
    ggFee: null,
  },
  directDebits: {
    data: [],
    isLoading: true,
  },
  transactions: {
    monthly: [],
    pending: [],
    isLoading: false,

    page: 0,
    itemsPerPage: 0,
    itemCount: 0,
    filters: {
      categories: '',
      endDate: '',
      search: '',
      startDate: '',
      sortAscending: false,
    },
    uncategorisedTransactions: {
      isLoading: true,
      data: {
        monthly: [],
      },
    },
    isSummaryVisible: false,
  },
  details: {
    internationalDetails: {
      bic: null,
      iban: null,
      bankName: null,
      bankAddress: null,
    },
    accountNumber: null,
    accountName: null,
    sortCode: null,
    provider: null,
    balance: null,
    registeredAddress: null,
    institutionName: null,
    institutionAddress: null,
    depositLimit: null,
    isLoading: true,
    otherBalances: [],
    marketOpen: false,
    creationDate: null,
    disclaimer: null,
  },
  isOpeningInternationalAccount: false,
  shouldDoNps: false,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearExchangeRate: (state) => ({
      ...state,
      exchangeRate: initialState.exchangeRate,
    }),
    setWalletBalance: (state, action) => ({
      ...state,
      details: {
        ...state.details,
        balance: action.payload.balance,
      },
    }),
    setTransactionsFilters: (state, action) => ({
      ...state,
      transactions: {
        ...state.transactions,
        filters: action.payload.filters,
      },
    }),
    clearTransactionsFilters: (state) => ({
      ...state,
      transactions: {
        ...state.transactions,
        filters: initialState.transactions.filters,
      },
    }),
    hideNps: (state) => ({
      ...state,
      shouldDoNps: false,
    }),
    setIsSummaryVisible: (state, action) => ({
      ...state,
      transactions: {
        ...state.transactions,
        isSummaryVisible: action.payload,
      },
    }),
    setTransaction: (state, action) => {
      const transactions = state.transactions.monthly.map((month) => ({
        daily_transactions: month.daily_transactions.map((daily) => ({
          transactions: daily.transactions.map((tx) => {
            if (action.payload.transaction.id === tx.id) {
              return { ...tx, ...action.payload.transaction }
            }
            return tx
          }),
        })),
      }))

      return {
        ...state,
        transactions: {
          ...state.transactions,
          monthly: transactions,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExchangeRate.fulfilled, (state, action) => ({
      ...state,
      exchangeRate: {
        value: action.payload.buy,
        lastUpdated: new Date(),
        ggFee: action.payload.get_ground_takeout_rate,
      },
    })),
      builder.addCase(getExchangeRate.rejected, (state, action) => {
        if (action.meta.arg === 'GBPGBP') {
          return {
            ...state,
            exchangeRate: {
              value: 1,
              lastUpdated: new Date(),
              ggFee: 0,
            },
          }
        }
        return state
      }),
      builder.addCase(getDirectDebits.pending, (state) => ({
        ...state,
        directDebits: {
          ...state.directDebits,
          isLoading: true,
        },
      })),
      builder.addCase(getDirectDebits.fulfilled, (state, action) => ({
        ...state,
        directDebits: {
          data: action.payload,
          isLoading: false,
        },
      })),
      builder.addCase(getDirectDebits.rejected, (state) => ({
        ...state,
        directDebits: {
          ...state.directDebits,
          isLoading: false,
        },
      })),
      builder.addCase(cancelDirectDebit.fulfilled, (state, action) => ({
        ...state,
        directDebits: {
          ...state.directDebits,
          data: state.directDebits.data.filter(
            (directDebit) => directDebit.mandate_id !== action.meta.arg.directDebitID,
          ),
        },
      })),
      builder.addCase(getWalletTransactions.pending, (state, action) => {
        state.transactions.isLoading = (action.meta.arg?.filters?.page || 0) === 0
      }),
      builder.addCase(getWalletTransactions.fulfilled, (state, action) => {
        state.transactions.monthly =
          action.payload.pagination?.page === 1
            ? action.payload.monthly_transactions || []
            : [...state.transactions.monthly, ...action.payload.monthly_transactions]
        state.transactions.page = action.payload.pagination?.page ?? 0
        state.transactions.itemsPerPage = action.payload.pagination?.items_per_page ?? 0
        state.transactions.itemCount = action.payload.pagination?.item_count ?? 0
        state.transactions.isLoading = false
      }),
      builder.addCase(getWalletTransactions.rejected, (state) => {
        state.transactions.isLoading = false
      }),
      builder.addCase(getUncategorisedWalletTransactions.fulfilled, (state, action) => {
        state.transactions.uncategorisedTransactions.data.monthly =
          action.payload?.monthly_transactions || []
        state.transactions.uncategorisedTransactions.isLoading = false
      }),
      builder.addCase(getPendingConversions.fulfilled, (state, action) => ({
        ...state,
        transactions: {
          ...state.transactions,
          pending: action.payload,
        },
      })),
      builder.addCase(getWalletDetails.fulfilled, (state, action) => ({
        ...state,
        details: {
          ...state.details,
          internationalDetails: action.payload.international_details
            ? {
                bic: action.payload.international_details.bic,
                iban: action.payload.international_details.iban,
                bankName: action.payload.international_details.bank_name,
                bankAddress: action.payload.international_details.bank_address,
              }
            : null,
          accountNumber: action.payload.account_number,
          accountName: action.payload.account_name,
          sortCode: action.payload.sort_code,
          provider: action.payload.provider,
          balance: action.payload.available_balance,
          registeredAddress: action.payload.registered_address,
          institutionName: action.payload.institution_name,
          institutionAddress: action.payload.institution_address,
          depositLimit: action.payload.daily_deposit_limit_in_cents,
          marketOpen: action.payload.market_open,
          otherBalances: action.payload.other_balances,
          creationDate: action.payload.provider_creation_time,
          disclaimer: action.payload.disclaimer,
          isLoading: false,
        },
      })),
      builder.addCase(getWalletDetails.pending, (state) => {
        state.details.isLoading = true
      }),
      builder.addCase(getWalletDetails.rejected, (state) => {
        state.details.isLoading = false
      }),
      builder.addCase(openInternationalAccount.pending, (state) => {
        state.isOpeningInternationalAccount = true
      }),
      builder.addCase(openInternationalAccount.fulfilled, (state) => {
        state.isOpeningInternationalAccount = false
      }),
      builder.addCase(openInternationalAccount.rejected, (state) => {
        state.isOpeningInternationalAccount = false
      }),
      builder.addCase(updateTransaction.fulfilled, (state, action) => {
        const { transactionID, data, attachment, categoryKey } = action.meta.arg
        const txn: Transaction = flattenTxs(state.transactions.monthly).find(
          (tx) => tx.id === transactionID,
        )!
        const direction = Number(txn.amount_in_cents) > 0 ? 'INBOUND' : 'OUTBOUND'

        state.transactions.monthly = state.transactions.monthly.map((month) => ({
          daily_transactions: month.daily_transactions.map((daily) => ({
            transactions: daily.transactions.map((tx) => {
              if (tx.id === transactionID) {
                return {
                  ...tx,
                  ...data,
                  attachment: attachment,
                  categoryKey: categoryKey ?? null,
                }
              }
              return tx.provider_reference === txn.provider_reference &&
                ((direction === 'INBOUND' && Number(tx.amount_in_cents) > 0) ||
                  (direction === 'OUTBOUND' && Number(tx.amount_in_cents) < 0))
                ? {
                    ...tx,
                    category: data?.category,
                    subcategory: data?.subcategory,
                    categoryKey: categoryKey,
                    category_additional_info: data?.category_additional_info,
                  }
                : tx
            }),
          })),
        }))
        const filteredUncategorisedTxs =
          state.transactions.uncategorisedTransactions.data.monthly.map((month) => ({
            daily_transactions: month.daily_transactions.map((daily) => ({
              transactions: daily.transactions.filter((tx) =>
                (direction === 'INBOUND' && Number(tx.amount_in_cents) > 0) ||
                (direction === 'OUTBOUND' && Number(tx.amount_in_cents) < 0)
                  ? tx.reference !== txn.reference
                  : tx.provider_reference !== txn.provider_reference,
              ),
            })),
          }))

        state.transactions.uncategorisedTransactions.data.monthly = filteredUncategorisedTxs
      }),
      builder.addCase(shouldDoNps.fulfilled, (state, action) => {
        state.shouldDoNps = action.payload.should_request
      })
  },
})

export const {
  setTransaction,
  clearExchangeRate,
  setWalletBalance,
  setTransactionsFilters,
  clearTransactionsFilters,
  hideNps,
  setIsSummaryVisible,
} = walletSlice.actions

export const selectWallet = (state) => state.wallet

export default walletSlice.reducer
