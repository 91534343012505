import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import Document from 'src/types/Document'
import * as api from 'src/api/ggAPI'

export const getCompanyDocuments = createAsyncThunk(
  'companyDocuments/getDocuments',
  api.getCompanyDocumentsV3,
)

export const getQualyfingAccountingDocs = createAsyncThunk(
  'companyDocuments/getAccountingDocs',
  api.getQualyfingAccountingDocs,
)

export const getCompanyDocumentsCategories = createAsyncThunk(
  'companyDocuments/getDocumentsCategories',
  api.getDocumentsCategories,
)

export const deleteCompanyDocuments = createAsyncThunk(
  'companyDocuments/deleteCompanyDocuments',
  ({ companyID, ids }: { companyID: number; ids: number[] }) => {
    return api.deleteDocuments(companyID, ids)
  },
)

export const editCompanyDocuments = createAsyncThunk(
  'companyDocuments/editCompanyDocuments',
  ({ companyID, documents }: { companyID: number; documents: Document[] }) =>
    api.editDocuments(companyID, documents),
)

interface CompanyDocumentsState {
  documents: Document[]
  qualifyingAccountingDocs: Document[]
  categories: { category: string; category_group: string; label: string }[]
  isLoading: boolean
  error: string | null
}

const pendingState: CompanyDocumentsState = {
  documents: [],
  qualifyingAccountingDocs: [],
  categories: [],
  isLoading: true,
  error: null,
}

const companyDocumentsSlice = createSlice({
  name: 'companyDocuments',
  initialState: pendingState,
  reducers: {
    addCompanyDocuments: (state, action) => {
      const newDocuments = action.payload
      state.documents = [...newDocuments, ...state.documents]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDocuments.pending, () => pendingState)

      .addCase(getCompanyDocuments.fulfilled, (state, action) => {
        const fetchedDocuments = action.payload
        return {
          ...state,
          documents: fetchedDocuments,
          isLoading: false,
        }
      })

      .addCase(getCompanyDocuments.rejected, (state, action) => {
        const error = action.payload?.data.error || 'Failed to load documents.'
        return { ...state, isLoading: false, error }
      })

      // Get a filtered list of documents that can be chosen on the accountancy flow
      .addCase(getQualyfingAccountingDocs.pending, () => pendingState)

      .addCase(getQualyfingAccountingDocs.fulfilled, (state, action) => {
        const fetchedDocuments = action.payload
        return {
          ...state,
          qualifyingAccountingDocs: fetchedDocuments,
          isLoading: false,
        }
      })

      .addCase(getQualyfingAccountingDocs.rejected, (state, action) => {
        const error = action.payload?.data.error || 'Failed to load documents.'
        return { ...state, isLoading: false, error }
      })

      .addCase(getCompanyDocumentsCategories.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          categories: action.payload.file_categories,
        }
      })

      .addCase(deleteCompanyDocuments.fulfilled, (state, action) => {
        const deletedDocuments = action.meta.arg
        state.documents = state.documents.filter(({ id }) => !deletedDocuments.ids.includes(id))
      })

      .addCase(editCompanyDocuments.fulfilled, (state, action) => {
        const editedDocs = action.payload
        const editedIds = editedDocs.map((doc) => doc.id)
        return {
          ...state,
          documents: state.documents.map((doc) =>
            editedIds.includes(doc.id)
              ? editedDocs.find((editedDoc) => editedDoc.id === doc.id) || doc // fallback for types, as .find() can return Document | undefined
              : doc,
          ),
        }
      })
  },
})

export const { addCompanyDocuments } = companyDocumentsSlice.actions

export default companyDocumentsSlice.reducer
