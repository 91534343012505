import axios, { AxiosResponse } from 'axios'

import { MarketData } from 'src/types/MarketData'
import { DirectDebit } from 'src/state/types/DirectDebit.model'
import Document from 'src/types/Document'
import AccountancySubmission from 'src/types/AccountancySubmission'
import AccountancyExpense from 'src/types/AccountancyExpense'
import WalletInfo from 'src/types/WalletInfo'
import PDFSignature from 'src/types/PDFSignature'
import { Address } from 'src/types/Address'
import GGPayment from 'src/types/GGPayment'
import SignableAccountsDoc from 'src/types/SignableAccountsDoc'
import Solicitor from 'src/components-pages/Company/RequestChange/Solicitor'
import { MortgagesState } from 'src/state/types/Mortgages'
import OverdueFee from 'src/types/OverdueFee'
import Payee from 'src/types/Payee'
import StandingOrder from 'src/types/StandingOrder'
import StandingOrderPayload from 'src/types/StandingOrderPayload'
import AssignableAccountancyDoc from 'src/types/AssignableAccountancyDoc'
import AnalyticsFilters from 'src/types/AccountAnalyticsFilters'
import CompanyAccountType from 'src/types/CompanyAccount'
import LaunchPlan from 'src/types/LaunchPlan'
import TerminationReason from 'src/types/TerminationReason'
import Partner from 'src/types/Partner'
import CoBranding from 'src/types/CoBranding'
import ServiceOffered from 'src/types/ServiceOffered'
import PropertyManagement from 'src/types/PropertyManagement'
import { RentalTransactionResponse, RentalTransactionsQuery } from 'src/types/RentTracker'
import { PurchaseType, MortgageDetailsResponse } from 'src/types/Mortgage'
import PropertyListing, { MarketplaceListing } from 'src/types/PropertyListing'
import { ListingProperty } from 'src/types/ListingProperty'
import ListingFilter from 'src/types/ListingFilter'
import MeetTeamData from 'src/types/MeetTeamData'
import GetPropertyInvestment from 'src/types/GetPropertyInvestment'
import GetPropertyInvestmentContact from 'src/types/GetPropertyInvestmentContact'
import { RequestedDocuments, UpdateRequestedDocumentsParams } from 'src/types/RequestedDocuments'
import { PPChangeRequestChanges, ResponsePPChangeRequestSubmition } from 'src/types/PPChangeRequest'
import { TransactionCategory, AllTransactions } from 'src/types/Transactions'
import { CorpTaxDoc, TaskData } from 'src/types/Task'
import { PropertyReportsListItem } from 'src/types/PropertyReports'
import DashboardAnalytics from 'src/types/DashboardAnalytics'
import ListingInvestmentsCalculationQuery from 'src/types/ListingInvestmentsCalculationQuery'
import {
  ProductType,
  Investment,
  Valuation,
  ValuationRequest,
  CreateValuationRequestArgs,
} from 'src/types/investments'
import { getAuth } from 'src/utils/auth'
import { SellProduct } from 'src/types/SellProduct'
import { SubscriptionUpdateRequest } from 'src/types/StandingOrderUpdate'
import CapitalGrowth from 'src/types/CapitalGrowth'
import { EligibleProduct, LenderQuote } from 'src/components-pages/MortgageSelectedLenders/types'
import routes from 'src/pages/AppWrapper/AppRoutes'
import * as ggUrl from 'src/utils/ggUrl'
import User from 'src/types/User'
import WebSessionUser from 'src/types/WebSessionUser'
import { CompanyPricing, DefaultCompanyPricing } from 'src/types/CompanyPricing'
import { ApiReferralCode, GetUserReferralsResponse } from 'src/types/Referrals'
import CompanyMember from 'src/types/CompanyMembers'
import CompanyMemberSubentry from 'src/types/CompanyMemberSubentry'
import CompanyMemberUser from 'src/types/CompanyMemberUser'
import CompanyDirector from 'src/types/CompanyDirector'
import type { PropertySourcingApiArgs } from 'src/components/PropertySourcingModal/types'
import { ApplyDiscountRequest, ApplyDiscountResponse } from 'src/types/DiscountCodes'

const mutateTimestamp = (timestamp: number | null) =>
  timestamp ? Number(timestamp * 10 ** 9) : null

if (import.meta.env.MODE === 'staging') {
  // eslint-disable-next-line no-underscore-dangle
  const apiUrl = window._env_?.VITE_API_URL || import.meta.env.VITE_API_URL
  axios.defaults.baseURL = `${apiUrl}/api/`
} else {
  axios.defaults.baseURL = `${import.meta.env.VITE_API_URL}/api/`
}

axios.interceptors.request.use((config) => {
  const auth = getAuth()
  const token = auth?.token ?? ''

  config.headers = {
    Authorization: config.useAPIKey ? 'avb068cbk2os5ujhodmt' : `Bearer ${token}`,
    ...config.headers,
  }
  return config
})

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorMessagesToLogUserOut = [
      'invalid token',
      'token expired',
      'invalid/malformed auth token',
      'token blacklisted, or error with redis',
      'unauthorised. please provide a valid token',
    ]
    if (
      error.response?.status === 401 &&
      errorMessagesToLogUserOut.some((msg) => error.response?.data?.error?.toLowerCase() === msg) &&
      !error.response.config.url.includes('listings')
    ) {
      routes?.navigate(ggUrl.loginUrl())
    }

    error.status = error?.response?.status
    error.message =
      error?.response?.data?.error ||
      error?.response?.data?.reason ||
      error?.response?.data.error_message
    return Promise.reject(error)
  },
)

// Market insight
export const getMarketAreas = () => axios.get('v3/property_data')

export const saveSelectedMarketAreas = (propertyID: string, marketData: MarketData) =>
  axios.post(`v3/property_purchases/${propertyID}/services/find_property`, marketData)

export const updateSelectedMarketAreas = (
  propertyID: string,
  marketData: MarketData,
  serviceID: number,
) =>
  axios.put(`v3/property_purchases/${propertyID}/services/find_property/${serviceID}`, marketData)

// Payment
export const getPaymentDetails = (ppID: number) =>
  axios.get(`v3/property_purchases/${ppID}/payment_details`)

// Currency cloud consent

export const getCCTermsAgreement = (userID: number, type: string) =>
  axios.get(`v3/users/${userID}/accept_terms/${type}`)

export const postCCTermsAgreement = (userID: number, type: string, ppID: number) =>
  axios.post(`v3/users/${userID}/accept_terms${ppID ? `?property_purchase_id=${ppID}` : ''}`, {
    consent_type: type,
  })

// Property Address

export const requestPropertyDetailsChange = (propertyPurchaseID: number, request: string) =>
  axios.post(`v3/property_purchases/${propertyPurchaseID}/request_property_details_change`, {
    request,
  })

interface Types {
  key: string
  label: string
}

export const getPropertyTypes = (): Promise<Types[]> => axios.get('v3/constants/property_type')
export const getTenureTypes = (): Promise<Types[]> => axios.get('v3/constants/tenure_type')
export const getNoOfBedrooms = (): Promise<Types[]> => axios.get('v3/constants/bedrooms')

// Property Purchase

export const updatePropertyPurchase = ({ propertyID, data }) =>
  axios.put(`v2/property_purchases/${propertyID}`, data)

export const updatePPCompletionDate = (
  propertyID: number,
  edit: { completion_date: string },
): Promise<{ completion_date: string }> =>
  axios.put(`v3/property_purchases/${propertyID}/completion_date`, edit)

export const confirmPayment = (ppID: number, data) =>
  axios.post(`v3/property_purchases/${ppID}/payment/confirm`, data)

export const finalisePropertyPurchase = (ppID: number) =>
  axios.post(`v3/property_purchases/${ppID}/finalise`)

export const markPaymentAsPaid = (ppID: number, data) =>
  axios.post(`v3/property_purchases/${ppID}/payment/paid`, data)

export const submitPPChangeRequest = (
  ppID: number,
  changes: PPChangeRequestChanges,
): Promise<ResponsePPChangeRequestSubmition> =>
  axios.post(`v3/property_purchases/${ppID}/property_details_change_request`, changes)

export const getPPChangeRequest = (ppID: number): Promise<ResponsePPChangeRequestSubmition> =>
  axios.get(`v3/property_purchases/${ppID}/property_details_change_request`)

export const getPPChangeRequestDocuments = (ppID: string) =>
  axios.get(`v3/property_purchases/${ppID}/property_details_change_request/markdown_summaries`)

export const getPPDetails = (ppID: number) => axios.get(`v3/investments/${ppID}`)

// Wallet

export const getExchangeRate = (currencyPair) =>
  axios.get('/v2/wallet/exchange_rate', {
    params: { currency_pair: currencyPair },
  })

export const sendExchangeCurrencyRequest = (companyID, amount, currencyPair) =>
  axios.post(`v2/companies/${companyID}/e-wallet/exchange_currency`, {
    amount_in_major_units: amount,
    currency_pair: currencyPair,
  })

export const declinedPaymentPP = ({ propertyID }: { propertyID: number }) =>
  axios.post(`v3/property_purchases/${propertyID}/payment/declined`)

// Wallets

export const getRentalTransactions = ({
  page,
  items_per_page,
  companies,
}: RentalTransactionsQuery): Promise<RentalTransactionResponse> =>
  axios.get(
    `v3/wallets/transactions_rent?page=${page}&items_per_page=${items_per_page}&companies=${companies.join(
      '&companies=',
    )}`,
  )

// User

export const getAvailableInterests = (userID: number): Promise<string[]> =>
  axios.get(`/v3/users/${userID}/interests/unregistered`)

export const updateUser = ({ userID, data }: { userID: number; data: Partial<User> }) =>
  axios.patch(`/v3/users/${userID}`, data).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
  }))

export const getUserAddress = (): Promise<Address> => axios.get('/v3/users/me/address')
export const createWebSessionUser = (): Promise<WebSessionUser> => axios.post('/v3/web_session')
export const applyDiscountCode = (data: ApplyDiscountRequest) =>
  axios.post<ApplyDiscountResponse>('/v3/discounts/apply', data)

interface Promos {
  created_at?: string
  expiry_date?: string
  type: string
}

export const getUserPromos = (): Promise<Promos[]> => axios.get('/v3/users/me/promos')

export const getNewUserCompanyPricing = (): Promise<DefaultCompanyPricing> =>
  axios.get('/v3/pricing')

export const getCompanyPricing = (): Promise<CompanyPricing[]> => axios.get('/v3/users/me/pricing')

export const getDashboardAnalytics = (): Promise<DashboardAnalytics> =>
  axios.get('/v3/analytics/investments')

export const getRequestedDocuments = (): Promise<RequestedDocuments> =>
  axios.get('/v3/users/me/document_requests')

export const updateRequestedDocuments = ({
  requestID,
  request,
}: UpdateRequestedDocumentsParams): Promise<RequestedDocuments> =>
  axios.patch(`/v3/users/me/document_requests/${requestID}`, request)

export const getRequestedDocumentsUrls = (requestID: number, ...ids: string[]): Promise<DocUrl[]> =>
  axios.get(`/v3/users/me/document_requests/${requestID}/files/signed_url?id=${ids.join(',')}`)

export const deleteRequestedDocument = (requestID: number, fileID: number) =>
  axios.delete(`v3/users/me/document_requests/${requestID}/files/${fileID}`)

interface CrossSellEligibility {
  keys: string[]
}

export const getCrossSellEligibility = () =>
  axios.get<CrossSellEligibility>('/v3/users/me/cross_sell')

// Countries

export const getCountries = () => axios.get('/v3/countries')

// Shareholders

interface AORPayload {
  source_of_funds: {
    source: string
    other_reason: string
  }
}

export interface AORResponse {
  token: string
  key_uri: string
  two_factor_auth_method?: string
}

export const acceptRoleSetPassword = (token: string, payload: AORPayload) =>
  axios.post<AORResponse>('v3/invitations/accept', { token, ...payload }, { useAPIKey: true })

export const rejectRole = (token) =>
  axios.post('v3/invitations/reject', { token }, { useAPIKey: true })

// Account

interface CreateAccountParams {
  emailToPost: string
  password: string
  optIn: boolean
  token: string
  referralCode?: null | string
  signUpCode?: null | string
  redirect_url?: null | string
  webSessionToken?: null | string
}

export const createAccount = ({
  emailToPost,
  password,
  optIn,
  token,
  referralCode = null,
  signUpCode = null,
  redirect_url = null,
  webSessionToken = null,
}: CreateAccountParams) =>
  axios
    .post(
      'v2/users',
      {
        email: emailToPost,
        password,
        receivesMarketing: optIn,
        google_recaptcha_token: token,
        ...(signUpCode ? { signUpCode } : referralCode ? { referralCode } : {}),
        redirect_url,
        web_session_token: webSessionToken,
      },
      { useAPIKey: true },
    )
    .then((res) => ({
      ...res,
      created_at: mutateTimestamp(res.created_at),
      updated_at: mutateTimestamp(res.updated_at),
      deleted_at: mutateTimestamp(res.deleted_at),
    }))

export const getReferralCodeInfo = (referralCode: string): Promise<Partner & CoBranding> =>
  axios.get(`v3/partners?referral_code=${referralCode}`)

export const getSignUpCodeInfo = (signUpCode: string): Promise<Partner & CoBranding> =>
  axios.get(`v3/partners?sign_up_code=${signUpCode}`)

export const updatePersonalDetails = (data) => {
  const { userID } = data
  return axios
    .patch(`v2/users/${userID}`, {
      [data.key]: data.value,
    })
    .then((res) => ({
      ...res,
      created_at: mutateTimestamp(res.created_at),
      updated_at: mutateTimestamp(res.updated_at),
      deleted_at: mutateTimestamp(res.deleted_at),
    }))
}

export const saveSignature = (signatureBlob, userId) => {
  const data = JSON.stringify({
    content: signatureBlob.split(',')[1],
    description: 'signature',
    filename: 'signature',
    title: 'signature',
  })

  return axios.post(`v2/users/${userId}/files`, data).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
  }))
}

export const getDocuments = (userId) => axios.get(`v2/users/${userId}/kyc/files/latest`)

export const getSignature = (userId) => axios.get(`v2/users/${userId}/signature`)

export const getTasks = (): Promise<{ tasks: TaskData[]; upcoming_tasks: TaskData[] }> =>
  axios.get('v3/tasks/dashboard')

// Inbox

export const getInboxItems = () => axios.get('v3/inbox/notifications')

export const getInboxStatus = () => axios.get('v3/inbox/status')

// Overdue fees
export const getOverdueFees = (
  companyID: number,
): Promise<{
  account_balance_in_cents: number
  overdue_fees: OverdueFee[]
  total_due_in_cents: number
}> => axios.get(`v3/companies/${companyID}/overdue_fees`)

export const postOverdueFees = (companyID: number, receivableIDs: number[]) =>
  axios.post(`v3/companies/${companyID}/settle_up`, { accounts_receivable_ids: receivableIDs })

// Subscription details
export const getSubscriptionDetails = (
  companyID: number,
): Promise<{
  due_date: number
  payment_method: string
  amount_in_cents: number
}> => axios.get(`v3/companies/${companyID}/subscription`)

export const getQR = () => axios.get('v2/key_uri')

export const completeOnboarding = (userId, data = {}) =>
  axios.post(`v2/users/${userId}/onboarding/confirm`, data)

export const getUser = () =>
  axios.get('v2/users/token/me').then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
  }))

export const getGGPayments = (companyID: number): Promise<GGPayment[]> =>
  axios.get(`v3/companies/${companyID}/payments`)

// Auth

export const requestResetPassword = (email: string) =>
  axios.post(
    'v2/auth/request_reset_password',
    {
      email,
    },
    { useAPIKey: true },
  )

export const reauthenticate = () => axios.post('v2/auth_reset')

export const setNewPassword = (password, token) =>
  axios.post(
    'v2/auth/reset_password',
    {
      password,
      token,
    },
    { useAPIKey: true },
  )

export const verifyEmail = (verificationCode) =>
  axios.put(
    'v2/users/email/verify',
    JSON.stringify({ email_verification_code: verificationCode }),
    { useAPIKey: true },
  )

interface UnAuthJwt {
  email: string
  password: string
  rememberDeviceToken?: string | null
}

export const unAuthJwt = ({ email, password, rememberDeviceToken }: UnAuthJwt) =>
  axios.post(
    'v2/token',
    { email, password, remember_device_token: rememberDeviceToken },
    { useAPIKey: true },
  )

export const verifyQRToken = (input, rememberDevice = false) =>
  axios.post(
    'v2/token/verify_totp',
    JSON.stringify({ otp_token: input, remember_device: rememberDevice }),
  )

export const login = (token) =>
  axios.post('v2/employees/login', { google_token: token }, { useAPIKey: true })

export const logout = (token) =>
  axios.post('v3/logout', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const getEmployee = () => axios.get('v2/employees/token/me')

export const setupSMS = (phoneNumber) =>
  axios.post('v2/token/setup_sms', { phone_number: phoneNumber })

export const verifySMS = (input, rememberDevice = false) =>
  axios.post(
    'v2/token/verify_sms',
    JSON.stringify({ otp_token: input, remember_device: rememberDevice }),
  )

export const resendSMS = () => axios.post('v2/token/resend_sms')

interface LoginWithGoogle {
  google_token: string
  rememberDeviceToken: string
}

export const loginWithGoogle = ({ google_token, remember_device_token }: LoginWithGoogle) =>
  axios.post('v3/login/google', { google_token, remember_device_token })

export const signUpWithGoogle = (
  google_token: string,
  referralCode: string | null = null,
  webSessionToken: string | null = null,
) =>
  axios.post(
    'v3/signup/google',
    referralCode
      ? { google_token, referral_code: referralCode, web_session_token: webSessionToken }
      : { google_token, web_session_token: webSessionToken },
  )

// Company
export const getCompany = (id) =>
  axios.get(`v2/companies/${id}`).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
    account_balance_in_cents: res.account_balance_in_cents
      ? Number(res.account_balance_in_cents)
      : null,
  }))

export const getCompaniesForUser = (userId) =>
  axios.get(`v2/users/${userId}/companies`).then((res) => ({
    ...res,
    companies: res.companies.map((company) => ({
      ...company,
      created_at: mutateTimestamp(company.created_at),
      updated_at: mutateTimestamp(company.updated_at),
      deleted_at: mutateTimestamp(company.deleted_at),
      account_balance_in_cents: company.account_balance_in_cents
        ? Number(company.account_balance_in_cents)
        : null,
    })),
  }))

export const createWallet = (companyId, provider) =>
  axios.post(`v2/companies/${companyId}/wallet`, {
    company_id: companyId,
    provider,
  })

export const requestCompanyTermination = ({
  companyID,
  reasons,
  additionalInfo,
}: {
  companyID: number
  reasons: TerminationReason[]
  additionalInfo: string
}) =>
  axios.post(`v3/companies/${companyID}/dissolve`, {
    termination_reasons: reasons,
    termination_reason_additional_info: additionalInfo,
  })

export const requestOffboardCompany = ({
  companyID,
  reasons,
  additionalInfo,
}: {
  companyID: number
  reasons: TerminationReason[]
  additionalInfo: string
}) =>
  axios.post(`v3/companies/${companyID}/offboard`, {
    termination_reasons: reasons,
    termination_reason_additional_info: additionalInfo,
  })

// Company > Documents

export const getCompanyDocumentsV3 = (companyID: number): Promise<Document[]> =>
  axios.get(`/v3/companies/${companyID}/files`)

export const getQualyfingAccountingDocs = (companyID: number): Promise<Document[]> =>
  axios.get(
    `/v3/companies/${companyID}/files?has_accounts_submission=false&include_dms2_files=false`,
  )

interface DocUrl {
  signed_url: string
  filename: string
}

export const getDocumentsUrls = (companyID: number, ...ids: string[]): Promise<DocUrl[]> =>
  axios.get(`/v3/companies/${companyID}/files/signed_url?id=${ids.join(',')}`)

export const recordFiles = (
  companyID: number,
  files: {
    name: string
    objectName: string
    size: number
    category: string
    mimeType: string
    document_date?: string
    submissionID?: number
  }[],
) =>
  axios.post(`v3/companies/${companyID}/files`, {
    file_objects: files.map((file) => ({
      filename: file.name,
      object_name: file.objectName,
      file_size_in_bytes: file.size,
      category: file.category,
      mime_type: file.mimeType,
      document_date: file.document_date,
      document_submission_id: file.submissionID,
    })),
  })

export const recordExpense = (
  companyID: number,
  expenses: {
    amount_in_cents: number
    category: string
    currency: string
    description?: string
    merchant_name: string
    document_date: string
    paid_by_user_id: number
    receipt: {
      size: number
      filename: string
      location: string
      mime_type: string
    }
  }[],
) =>
  axios.post(`v3/companies/${companyID}/expenses`, {
    expenses: expenses.map((expense) => ({
      amount_in_cents: expense.amount_in_cents,
      category: expense.category,
      currency: expense.currency,
      description: expense.description,
      merchant_name: expense.merchant_name,
      document_date: expense.document_date,
      paid_by_user_id: expense.paid_by_user_id,
      receipt: {
        file_size_in_bytes: expense.receipt.size,
        filename: expense.receipt.filename,
        location: expense.receipt.location,
        mime_type: expense.receipt.mime_type,
      },
    })),
  })

/** @deprecated use `useRequestFileUrls` from `user/mutations` instead */
export const requestFileUrls = (
  companyID: number,
  files: { filename: string; mimeType: string }[],
) =>
  axios.post(`v3/companies/${companyID}/files/signed_url_v4`, {
    files: files.map((file) => ({ filename: file.filename, mime_type: file.mimeType })),
  })

export const getDocumentsCategories = (): Promise<{
  file_categories: { category: string; category_group: string; label: string }[]
}> => axios.get('v3/constants/file_categories')

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const uploadFile = (signedURL: string, file: any, headers = null) => {
  // used fetch to send a request to a third party
  const formData = new FormData()
  formData.append('key', headers ? headers.key : file.headers.key)
  formData.append('policy', headers ? headers.policy : file.headers.policy)
  formData.append(
    'x-goog-algorithm',
    headers ? headers['x-goog-algorithm'] : file.headers['x-goog-algorithm'],
  )
  formData.append(
    'x-goog-credential',
    headers ? headers['x-goog-credential'] : file.headers['x-goog-credential'],
  )
  formData.append('x-goog-date', headers ? headers['x-goog-date'] : file.headers['x-goog-date'])
  formData.append(
    'x-goog-signature',
    headers ? headers['x-goog-signature'] : file.headers['x-goog-signature'],
  )
  formData.append('content-type', headers ? headers['content-type'] : file.headers['content-type'])
  formData.append('file', file) // ⚠️ FILE NEEDS TO BE THE LAST APPENDED VALUE OTHERWISE IT WILL FAIL ⚠️
  return fetch(signedURL, {
    method: 'POST',
    body: formData,
  })
}

export const deleteDocuments = (companyID: number, ids: number[]) =>
  axios.delete(`v3/companies/${companyID}/files?id=${ids.join(',')}`)

export const editDocuments = (companyID: number, documents: Document[]): Promise<Document[]> => {
  return axios.put(`/v3/companies/${companyID}/files`, {
    file_objects: documents.map((doc) => ({
      id: doc.id,
      title: doc.title,
      category: doc.category,
      document_date: doc.document_date,
      currency: doc.currency,
      description: doc.description,
      expense_category: doc.expense_category,
      merchant_name: doc.merchant_name,
      amount_in_cents: doc.payment_amount,
    })),
  })
}

// Company > Accountancy

export const assignAccountancyDoc = (
  companyID: number,
  data: AssignableAccountancyDoc[],
): Promise<AssignableAccountancyDoc[]> =>
  axios.patch(`v3/companies/${companyID}/files`, { file_patches: data })

export const getAccountancySubmission = (
  submissionID: number,
  companyID: number,
): Promise<AccountancySubmission> =>
  axios.get(`v3/companies/${companyID}/accounts_submissions/${submissionID}`)

export const updateAccountSubmission = (
  companyID: number,
  submissionID: number,
  edit: Partial<AccountancySubmission>,
): Promise<AccountancySubmission> =>
  axios.put(`v3/companies/${companyID}/accounts_submissions/${submissionID}`, edit)

interface MarkCorpTaxAsPaid {
  companyID: number
  submissionID: number
  corp_tax_document?: CorpTaxDoc
}

export const markCorpTaxAsPaid = ({
  companyID,
  submissionID,
  corp_tax_document,
}: MarkCorpTaxAsPaid): Promise<AccountancySubmission> =>
  axios.patch(`v3/companies/${companyID}/accounts_submissions/${submissionID}/mark_as_paid`, {
    corp_tax_document,
  })

export const unlockAccountSubmission = (companyID: number, submissionID: number): Promise<void> =>
  axios.put(`v3/companies/${companyID}/accounts_submissions/${submissionID}/unlock_ownership`)

export const getAccountancyExpenses = (
  companyID: number,
  submissionID: number,
): Promise<AccountancyExpense[]> =>
  axios.get(`v3/companies/${companyID}/accounts_submissions/${submissionID}/expenses`)

export const deleteExpenses = (companyID: number, submissionID: number, ids: number[]) =>
  axios.delete(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/expenses?id=${ids.join(',')}`,
  )

export const unlinkCompanyDocuments = (companyID: number, submissionID: number, ids: string[]) =>
  axios.delete(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/files?id=${ids.join(',')}`,
  )

export const recordExpenses = (
  companyID: number,
  submissionID: number,
  files: Partial<AccountancyExpense>[],
) =>
  axios.post(`v3/companies/${companyID}/accounts_submissions/${submissionID}/expenses`, {
    expenses: files.map((file) => ({
      amount_in_cents: Number(file.amount_in_cents),
      merchant_name: file.merchant_name,
      document_date: file.document_date,
      currency: file.currency,
      category: file.category,
      description: file.description,
      paid_by_user_id: file.paid_by_user_id,
      receipt: {
        file_size_in_bytes: file.receipt.file_size_in_bytes,
        filename: file.receipt.filename,
        location: file.receipt.location,
        mime_type: file.receipt.mime_type,
      },
    })),
  })

export const getExpensesCategories = () => axios.get('v3/constants/expense_categories')

export const getAccountingDocuments = (companyID: number, accountsSubmissionID: number) =>
  axios.get(`v3/companies/${companyID}/accounts_submissions/${accountsSubmissionID}/files`)

export const getCompanyAccounts = (): Promise<{ accounts: CompanyAccountType[] }> =>
  axios.get('v3/users/me/companies/accounts')

export const getAccountPayees = (
  companyID: number,
  { page = 1, itemsPerPage = 10, search = '' },
): Promise<{
  payees: Payee[]
  search: string
  pagination: {
    item_count: number
    page: number
    items_per_page: number
  }
}> =>
  axios.get(
    `v3/companies/${companyID}/account/payees?page=${page}&items_per_page=${itemsPerPage}&search=${search}`,
  )

export const getPayee = (companyID: number, payeeID: number): Promise<Payee> =>
  axios.get(`v3/companies/${companyID}/account/payees/${payeeID}`)

export const editPayee = (companyID: number, payeeID: number, payee: Payee): Promise<Payee> =>
  axios.put(`v3/companies/${companyID}/account/payees/${payeeID}`, payee)

export const getCashflowsCategories = () => axios.get('v3/constants/cash_flow_categories')

interface AccountancyDocument {
  count: number
  label: string
}

export interface OverviewData {
  accounting_period_end_date: string
  accounting_period_start_date: string
  company_id: number
  contract_exchange_date: string
  deadline: string
  documents: AccountancyDocument[]
  has_confirmed_completion_date: true
  has_exchanged_contracts: boolean
  has_paid_reservation_fee: boolean
  has_started_year_with_a_mortgage: boolean
  has_taken_a_new_mortgage_or_refinanced: boolean
  has_finished_paying_a_mortgage: boolean
  id: number
  num_cashflows: number
  num_expenses: number
  submitted_at: string
  user_id: number
}

export const getAccountancyOverview = (companyID: number, submissionID: number) =>
  axios.get<OverviewData>(`v3/companies/${companyID}/accounts_submissions/${submissionID}/overview`)

export const postAccountancySubmission = (companyID: number, submissionID: number) =>
  axios.post(`v3/companies/${companyID}/accounts_submissions/${submissionID}/submit`)

export const getCashflowsTransactions = (companyID: number, startDate: string, endDate: string) =>
  axios.get<AllTransactions>(
    `v3/companies/${companyID}/account/transactions?start_date=${startDate}&end_date=${endDate}&pagination=false&include_fees=true`,
  )

export type Transaction = {
  provider: string
  document_name: string
  date: string
  reference: string
  amount_in_cents: number
}

export type TransactionMapping = Omit<Transaction, 'amount_in_cents'> & {
  amount_in_pounds: number
}

export type ExternalTransaction = Transaction & {
  id: number
  category: string | null
  subcategory: string | null
}

export const getExternalTransactions = (
  companyID: number,
  submissionID: number,
): Promise<{ transactions: Transaction[] }> =>
  axios.get(`v3/companies/${companyID}/accounts_submissions/${submissionID}/transactions/external`)

export const createExternalTransactions = (
  companyID: number,
  submissionID: number,
  data: {
    transactions: Omit<ExternalTransaction, 'category' | 'subcategory' | 'id'>[]
  },
): Promise<{ transactions: ExternalTransaction[] }> =>
  axios.post(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/transactions/external`,
    data,
  )

export const deleteExternalTransactions = (
  companyID: number,
  submissionID: number,
  provider: string,
  document_name: string,
): Promise<void> =>
  axios.delete(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/transactions/external`,
    { data: { provider, document_name } },
  )

export const updateExternalTransactions = (
  companyID: number,
  submissionID: number,
  data: {
    transactions: Pick<ExternalTransaction, 'category' | 'subcategory' | 'id'>[]
  },
): Promise<void> =>
  axios.patch(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/transactions/external`,
    data,
  )

export const updateTransactionCategory = (
  companyID: number,
  data: Pick<TransactionCategory, 'id' | 'category' | 'subcategory'>[],
) => axios.put(`v3/companies/${companyID}/account/transactions`, data)

export const updateTransaction = (
  companyID: number,
  transactionID: number,
  data: TransactionCategory,
) => axios.put(`v3/companies/${companyID}/account/transactions/${transactionID}`, data)

export interface Ubo {
  name: string
  id: number
}

export const getUbosSince = (companyID: number, since: string) =>
  axios.get<{ ubos: Ubo[] }>(`v3/companies/${companyID}/ubos?since=${since}`)

export const getPropertyPurchase = (id) => axios.get(`v2/property_purchases/${id}`)

export const updatePP = (ppID: string, payload) =>
  axios.put(`v2/property_purchases/${ppID}`, payload).then((response) => {
    return {
      ...response,
      purchase_details: {
        ...response.purchase_details,
        price: {
          amount_in_cents: response.purchase_details?.price?.amount_in_cents
            ? Number(response.purchase_details.price.amount_in_cents)
            : null,
        },
      },
    }
  })

export const getCompanyOverview = (companyId) =>
  axios.get(`v2/companies/${companyId}/overview`).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
    filings: res.filings.map((filing) => ({
      ...filing,
      created_at: mutateTimestamp(filing.created_at),
    })),
  }))

// Investments

export const getInvestments = () => axios.get<Investment[]>('v3/investments')

export const getInvestmentValuations = (investmentID: number) =>
  axios.get<Valuation[]>(`v3/investments/${investmentID}/valuations`)

export const getInvestmentValuation = (investmentID: number, valuationID: number) =>
  axios.get<Valuation>(`v3/investments/${investmentID}/valuations/${valuationID}`)

export const createInvestmentValuation = (investmentID: number, data: ValuationRequest) =>
  axios.post<Valuation>(`v3/investments/${investmentID}/valuations`, data)

export const getInvestmentSell = (investmentID: number) =>
  axios
    .get<SellProduct[]>(`v3/investments/${investmentID}/sell_products`)
    .then((products = []): SellProduct | null => products[0] || null)

export const createInvestmentSell = (investmentID: number, valuationID: number) =>
  axios.post<SellProduct>(`v3/investments/${investmentID}/sell_products`, {
    valuation_id: valuationID,
  })

export const requestCallValuation = (investmentID: number, sellProductID: number) =>
  axios.post<SellProduct>(
    `v3/investments/${investmentID}/sell_products/${sellProductID}/contact_requests`,
  )

export const requestCallValuationFailed = (
  valuationID: number | null,
  user_comment: string,
  valuation_share_id: string | null,
) =>
  axios.post('v3/sell_products/contact_request', {
    valuation_id: valuationID,
    user_comment,
    valuation_share_id,
  })

export const requestCallPublicValuation = ({
  valuation_share_id,
}: {
  valuation_share_id: string
}) => axios.post(`v3/sell_products/valuations/${valuation_share_id}/contact_request`)

export const updateInvestmentSell = (
  investmentID: number,
  productID: number,
  valuationID: number,
  status: SellProduct['status'],
) =>
  axios.patch<SellProduct>(`v3/investments/${investmentID}/sell_products/${productID}`, {
    valuation_id: valuationID,
    status,
  })

export const createInvestmentSellValuation = (investmentID: number, data: ValuationRequest) =>
  axios.post<Valuation>(`v3/investments/${investmentID}/sell_products/valuations`, data)

export const getInvestment = ({ investmentID }: { investmentID: number }) =>
  axios.get(`v3/investments/${investmentID}`)

export const updateInvestmentName = ({
  name,
  investmentID,
}: {
  name: string
  investmentID: number
}) => axios.patch(`v3/investments/${investmentID}`, { name })

export const createInvestment = ({ packageId }: { packageId?: number } = {}) =>
  axios.post('v3/investments', { ...(packageId && { package_id: packageId }) })

export const deleteInvestment = ({ investmentID }: { investmentID: number }) =>
  axios.delete(`v3/investments/${investmentID}`)

export const requestInvestmentPhotoUrl = ({ investmentID, photoType }) =>
  axios.post(`v3/investments/${investmentID}/photo/signed_url_v4`, photoType)

export const updateInvestmentPhoto = ({ investmentID, photoInfo }) =>
  axios.post(`v3/investments/${investmentID}/photo`, photoInfo)

export const toggleStreetViewImage = ({ investmentID, is_google_streetview_enabled }) =>
  axios.patch(`/v3/investments/${investmentID}/photo`, { is_google_streetview_enabled })

export const deleteInvestmentImage = ({ investmentID }) =>
  axios.delete(`v3/investments/${investmentID}/photo`)

export const dismissCompleteProduct = ({
  productID,
  type,
}: {
  productID: number
  type: ProductType
}) => axios.patch('v3/products', { product_id: productID, product_type: type, dismissed: true })

export const deleteProduct = ({ productID, type }: { productID: number; type: ProductType }) =>
  axios.delete('v3/products', { data: { product_id: productID, product_type: type } })

export const selectInvestment = (data) => axios.post('v3/products', data)

export const getShareholders = (propertyPurchaseID) =>
  axios.get(`v2/property_purchases/${propertyPurchaseID}/shareholders`)

export const uploadDocument = (companyID, content, description, filename, title) =>
  axios.post(
    `v2/companies/${companyID}/files`,
    JSON.stringify({
      content,
      description,
      filename,
      title,
    }),
  )

export const updateInvestmentSellBanner = ({
  investmentID,
  dismiss_sell_banner,
}: {
  investmentID: number
  dismiss_sell_banner: boolean
}) => axios.patch(`v3/investments/${investmentID}`, { dismiss_sell_banner })

interface TransferFundPayload {
  otp: string
  reference: string
  amount_in_cents: number
  payee: {
    id: number | null
    name: string
    account_number: string
    sort_code: string
    persist: boolean
  }
}

export const transferFunds = (
  companyID: string,
  data: TransferFundPayload,
): Promise<AxiosResponse> => axios.post(`v3/companies/${companyID}/account/transfer_funds`, data)

export const getWalletInfo = (companyID: number): Promise<WalletInfo> =>
  axios.get(`v2/companies/${companyID}/wallet`).then((response) => {
    return {
      ...response,
      available_balance: response.available_balance ? Number(response.available_balance) : null,
      daily_deposit_limit_in_cents: response.daily_deposit_limit_in_cents
        ? Number(response.daily_deposit_limit_in_cents)
        : null,
    }
  })

export const openInternationalAccount = (companyID: number) =>
  axios.post(`v3/companies/${companyID}/add_currency_cloud_account`)

export const getAccountAnalytics = (companyID: number, filters: AnalyticsFilters) =>
  axios.get(
    `v3/companies/${companyID}/account/analytics?start_date=${filters.startDate}&end_date=${filters.endDate}`,
  )

export const getWalletTransactions = (
  companyID: number,
  {
    page = 1,
    itemsPerPage = 10,
    search = '',
    categories = '',
    startDate = '',
    endDate = '',
    pagination = true,
    statuses = '',
    sortAscending = false,
  },
): Promise<AllTransactions> =>
  axios.get(
    pagination
      ? `v3/companies/${companyID}/account/transactions?page=${page}&items_per_page=${itemsPerPage}&search=${search}&category=${categories}&start_date=${startDate}&end_date=${endDate}&statuses=${statuses}&sort_field=date&sort_ascending=${sortAscending}`
      : `v3/companies/${companyID}/account/transactions?pagination=${pagination}&category=${categories}`,
  )

export const getPendingConversions = (companyID: number) =>
  axios.get(`v3/companies/${companyID}/account/pending_conversions`)

export const walletStatement = (companyID, query) =>
  axios.get(`v2/companies/${companyID}/e-wallet/account_statement${query}`)

interface CreateStandingOrderResponse extends Omit<StandingOrderPayload, 'otp'> {
  id: number
  payments_made: number
  next_payment_date: string
  is_editable: boolean
}

export const submitStandingOrder = (
  companyID: number,
  data: StandingOrderPayload,
): Promise<CreateStandingOrderResponse> =>
  axios.post(`v3/companies/${companyID}/account/standing_orders`, data)

export const getStandingOrders = (companyID) =>
  axios.get(`v2/companies/${companyID}/e-wallet/standing_orders`).then((res) => ({
    ...res,
    standing_orders: res.standing_orders?.map((so) => ({
      ...so,
      amount_in_cents: so.amount_in_cents ? Number(so.amount_in_cents) : null,
      total_paid_in_cents: so.total_paid_in_cents ? Number(so.total_paid_in_cents) : null,
    })),
  }))

export const getStandingOrder = (
  companyID: number,
  standingOrderID: number,
): Promise<StandingOrder> =>
  axios
    .get(`v2/companies/${companyID}/e-wallet/standing_orders/${standingOrderID}`)
    .then((res) => ({
      ...res,
      amount_in_cents: res.amount_in_cents ? Number(res.amount_in_cents) : null,
      total_paid_in_cents: res.total_paid_in_cents ? Number(res.total_paid_in_cents) : null,
    }))

export const deleteStandingOrder = (companyID: number, orderID: string) =>
  axios.delete(`v3/companies/${companyID}/account/standing_orders/${orderID}`)

export const updateStandingOrder = (
  companyID: number,
  standingOrderID: number,
  data: Partial<StandingOrderPayload>,
) => axios.put(`v3/companies/${companyID}/account/standing_orders/${standingOrderID}`, data)

export const getDirectDebits = (companyID: number): Promise<DirectDebit[]> =>
  axios.get(`/v3/companies/${companyID}/direct_debits`)

export const cancelDirectDebit = (companyID: number, directDebitID: number) =>
  axios.delete(`/v3/companies/${companyID}/direct_debits/${directDebitID}`)

export const updateSolicitor = (company_id: string, solicitor: Solicitor) =>
  axios.put(`v3/companies/${company_id}/solicitor`, solicitor)

export const sendDocumentsToSolicitor = (companyID: number) =>
  axios.post(`v3/companies/${companyID}/solicitor/send_documents`)

export const updatePropertySolicitor = (property_purchase_id: number, solicitor: Solicitor) =>
  axios.put(`v3/property_purchases/${property_purchase_id}/solicitor`, solicitor)

export const sendPropertyDocumentsToSolicitor = (property_purchase_id: number) =>
  axios.post(`v3/property_purchases/${property_purchase_id}/solicitor/send_documents`)

// Modal

/* Register Members and Directors - Start */

export const getCompanyMembers = (companyID: number): Promise<{ entries: CompanyMember[] }> =>
  axios.get(`v3/companies/${companyID}/registers/members`)

export const getCompanyMemberSubentries = ({
  companyID,
  memberID,
}: {
  companyID: number
  memberID: number
}): Promise<{ subentries: CompanyMemberSubentry[] }> =>
  axios.get(`v3/companies/${companyID}/registers/members/${memberID}/subentries`)

export const getCompanyMemberUser = ({
  companyID,
  memberID,
}: {
  companyID: number
  memberID: number
}): Promise<CompanyMemberUser> =>
  axios.get(`/v3/companies/${companyID}/registers/members/${memberID}/user`)

export const getCompanyDirectors = (companyID: number): Promise<{ directors: CompanyDirector[] }> =>
  axios.get(`v3/companies/${companyID}/registers/directorships`)

export const getCompanyDirectorUser = ({
  companyID,
  directorID,
}: {
  companyID: number
  directorID: number
}): Promise<CompanyMemberUser> =>
  axios.get(`/v3/companies/${companyID}/registers/directorships/${directorID}/user`)

interface UpdateRegisterMemberPayload {
  companyID: number
  payload: {
    registers_approved?: boolean
    directorship_approved?: boolean
  }
}

export const updateRegisterMemberApproval = ({ companyID, payload }: UpdateRegisterMemberPayload) =>
  axios.patch(`v3/companies/${companyID}/registers`, payload)

/* Register Members and Directors - End */

export const fetchAllMarkdowns = (userId: string, ppID: string) =>
  axios.get(`v2/users/${userId}/property_purchases/${ppID}/markdown_summaries`)

export const fetchMarkdown = (id) =>
  axios.get(`v2/markdowns/${id}`).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    signatures: res.signatures.map((signature) => ({
      ...signature,
      created_at: mutateTimestamp(signature.created_at),
      updated_at: mutateTimestamp(signature.updated_at),
      signed_timestamp: mutateTimestamp(signature.signed_timestamp),
    })),
  }))

export const fetchPDF = (id) => axios.get(`v2/pdfs/${id}/download`)

export const signMarkdown = (id) =>
  axios.post(`v2/markdowns/${id}/sign`).then((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    signatures: res.signatures.map((signature) => ({
      ...signature,
      created_at: mutateTimestamp(signature.created_at),
      updated_at: mutateTimestamp(signature.updated_at),
      signed_timestamp: mutateTimestamp(signature.signed_timestamp),
    })),
  }))

export const markdownToPDF = (markdownID) => axios.get(`v2/markdowns/${markdownID}/pdf`)

export const fetchSignableAccountsDocs = (
  companyID: number,
  submissionID: number,
): Promise<SignableAccountsDoc[]> =>
  axios.get(`v3/companies/${companyID}/accounts_submissions/${submissionID}/signable_documents`)

export const getOtherAccountingDocuments = (companyID: number, accountsSubmissionID: number) =>
  axios.get(
    `v3/companies/${companyID}/accounts_submissions/${accountsSubmissionID}/other_accounting_documents`,
  )
interface PDFDocumentContent {
  content: string
}
export const fetchPDFDocument = (id: number): Promise<PDFDocumentContent> =>
  axios.get(`v3/documents/uploaded_pdfs/${id}`)

export const signAccountsDoc = (companyID: number, submissionID: number, documentID: number) =>
  axios.post(
    `v3/companies/${companyID}/accounts_submissions/${submissionID}/signable_documents/${documentID}/sign`,
  )

export const submitSigningAccount = (companyID: number, submissionID: number) =>
  axios.post(`v3/companies/${companyID}/accounts_submissions/${submissionID}/submit_signing`)

interface Signatures {
  signatures: PDFSignature[]
}

export const signPDFDocument = (documentID: number, signatures: Signatures) =>
  axios.post(`v3/documents/uploaded_pdfs/${documentID}/sign`, signatures)

// Onfido

export const confirmOnfido = (userId) => axios.post(`v2/users/${userId}/kyc_applicant/complete`)

export const createOnfidoJWT = (userId) => axios.post(`v2/users/${userId}/kyc_applicant`)

// Payment

export const makeIntent = (property_purchase_id, userId, amount) =>
  axios.post(`v2/users/${userId}/payment/intent`, {
    amount,
    currency: 'GBP',
    property_purchase_id,
  })

interface CreateIntent {
  companyID: number
  receivableIDs: []
}

interface CreateIntentResponse {
  redirect_url: string
}

export const createPayByBankIntent = ({
  companyID,
  receivableIDs,
}: CreateIntent): Promise<CreateIntentResponse> =>
  axios.post(`v3/companies/${companyID}/settle_up/intent`, {
    accounts_receivable_ids: receivableIDs,
  })

interface DeleteIntent {
  companyID: number
  intentID: string
}

export const deletePayByBankIntent = ({ companyID, intentID }: DeleteIntent) =>
  axios.delete(`v3/companies/${companyID}/settle_up/intent/${intentID}`)

interface GetPaymentFeesRequest {
  walletID: number
  accountNumber: string
  sortCode: string
  amountInCents: number
}

interface GetPaymentFeesResponse {
  fee_amount_in_cents: number
}

export const getPaymentFees = ({
  walletID,
  accountNumber: account_number,
  sortCode: sort_code,
  amountInCents: amount_in_cents,
}: GetPaymentFeesRequest): Promise<GetPaymentFeesResponse> => {
  return axios.get(`v3/wallets/${walletID}/transfer_funds/fee_details`, {
    params: { amount_in_cents, account_number, sort_code },
  })
}

// share transfer

export const shareTransfer = (companyID: number, payload) =>
  axios.post(`v3/companies/${companyID}/change_request`, payload)

export const fetchChangeRequestDocs = (companyID: string, changeID: number) =>
  axios.get(`v3/companies/${companyID}/change_requests/${changeID}/documents`)

export const fetchChangeRequest = (companyID: number, changeRequestID: number) =>
  axios.get(`v3/companies/${companyID}/change_requests/${changeRequestID}`)

export const updateUserPropertyPurchase = (
  property_purchase_id: string,
  user_id: string,
  payload,
) => axios.patch(`v3/property_purchases/${property_purchase_id}/users/${user_id}`, payload)

export const fetchEmploymentStatus = (): Promise<string[]> =>
  axios.get('v3/constants/employment_status')

// Company > Mortgages

export const submitMortgageApplication = (
  userID: string,
  mortgageData: MortgagesState,
): Promise<AxiosResponse> =>
  axios.post(`v3/users/${userID}/contact_requests/mortgages`, mortgageData)

export const collectInitialFixedPeriods = (): Promise<Types[]> =>
  axios.get('v3/constants/initial_fixed_periods')

export const collectRepaymentTypes = (): Promise<Types[]> =>
  axios.get('v3/constants/repayment_types')

export const getMortgagePurposes = (): Promise<Types[]> =>
  axios.get('v3/constants/mortgages/purposes')

// source of funds

export const getSourceOfFundsTypes = (): Promise<Types[]> =>
  axios.get('/v3/constants/source_of_funds/invitation')

export const postSourceOfFunds = (payload, token): Promise<Types[]> =>
  axios.post('/v3/source_of_funds', { token, payload })

// NPS

interface Payload {
  step: string
  score: number
  additional_notes: string | null
}

export const postNPS = (payload: Partial<Payload>, skipped = false): Promise<AxiosResponse> =>
  axios.post('/v3/nps', !skipped ? payload : { step: payload.step, skipped })

interface NPSResponse {
  should_request: boolean
}

export const shouldUserDoNPS = (step: string): Promise<NPSResponse> =>
  axios.get(`/v3/nps/should_request?step=${step}`)

// dashboard

export const getNewCompaniesInProgressTodos = () => axios.get('/v3/todos/company_completion')

// Launch Plan

export const getUserLaunchPlan = (): Promise<LaunchPlan> => axios.get('v3/users/me/launch_plan')

export const updateUserLaunchPlan = (launchPlanUpdate: Partial<LaunchPlan>) =>
  axios.put('v3/users/me/launch_plan', launchPlanUpdate)

export const getUserRecommendations = () =>
  axios.get('v3/users/me/questionnaires/explore/recommendations')

// Services

export const getServicesOffered = (): Promise<ServiceOffered[]> => axios.get('v3/services/offered')

export const submitPropertyManagementRequest = (
  properties: PropertyManagement[],
): Promise<AxiosResponse> => axios.post('v3/services/property_management', properties)

export const getPropertyManagementRequest = (): Promise<PropertyManagement[]> =>
  axios.get('v3/services/property_management')

// GetProperty

interface GetListingsResponse {
  data: PropertyListing[]
  total: number
  count_saved: number
}

export const getListings = ({
  offset,
  size,
  price_min,
  price_max,
  deposit_max,
  sort_field,
  sort_ascending,
  sort_descending,
  saved_listings,
  is_new_build,
  development,
  locations,
  completion_date,
}: {
  offset: number
  size: number
  price_min?: string
  price_max?: string
  deposit_max?: string
  sort_field?: string
  sort_ascending?: boolean
  sort_descending?: boolean
  saved_listings?: boolean
  is_new_build?: string
  development?: string
  locations?: string
  completion_date?: string
}): Promise<GetListingsResponse> =>
  axios.get('v3/search/listings', {
    params: {
      size,
      offset,
      price_min: price_min || undefined,
      price_max: price_max || undefined,
      deposit_max: deposit_max || undefined,
      sort_field: sort_field || undefined,
      sort_ascending: sort_ascending || undefined,
      sort_descending: sort_descending || undefined,
      saved_listings: saved_listings || undefined,
      is_new_build: is_new_build || undefined,
      development_id: development || undefined,
      post_town: locations || undefined,
      completion_date: completion_date || undefined,
    },
  })

export const getDevelopments = ({
  offset,
  size,
  price_min,
  price_max,
  deposit_max,
  sort_field,
  sort_ascending,
}: {
  offset: number
  size: number
  price_min?: string
  price_max?: string
  deposit_max?: string
  sort_field?: string
  sort_ascending?: boolean
}) =>
  axios.get('v3/search/public/developments', {
    params: {
      size,
      offset,
      price_min: price_min || undefined,
      price_max: price_max || undefined,
      deposit_max: deposit_max || undefined,
      sort_field: sort_field || undefined,
      sort_ascending: sort_ascending ?? undefined,
    },
  })

interface GetListingsDevsResponse {
  marketplace_listings: MarketplaceListing[]
  total: number
  count_saved: number
}

export const getListingsDevs = ({
  offset,
  size,
  price_min,
  price_max,
  deposit_max,
  sort_field,
  sort_ascending,
  sort_descending,
  saved_listings,
  is_new_build,
  development,
  seed,
  locations,
  completion_date,
}: {
  offset: number
  size: number
  price_min?: string
  price_max?: string
  deposit_max?: string
  sort_field?: string
  sort_ascending?: boolean
  sort_descending?: boolean
  saved_listings?: boolean
  is_new_build?: string
  development?: string
  seed?: number
  locations?: string
  completion_date?: string
}): Promise<GetListingsDevsResponse> =>
  axios.get('v3/search/marketplace', {
    params: {
      size,
      offset,
      price_min: price_min || undefined,
      price_max: price_max || undefined,
      deposit_max: deposit_max || undefined,
      sort_field: sort_field || undefined,
      sort_ascending: sort_ascending || undefined,
      sort_descending: sort_descending || undefined,
      saved_listings: saved_listings || undefined,
      is_new_build: is_new_build ?? undefined,
      development_id: development || undefined,
      seed: seed || undefined,
      post_town: locations || undefined,
      completion_date: completion_date || undefined,
    },
  })

export const getAllListings = ({
  size,
  savedListings,
}: {
  size: number
  savedListings?: boolean
}): Promise<GetListingsResponse> =>
  axios.get('v3/search/listings', {
    params: { size, offset: 0, saved_listings: Boolean(savedListings) },
  })

export const getFilteredListings = (query: string, size: number): Promise<GetListingsResponse> =>
  axios.get(`v3/search/listings?${query}`, { params: { size } })

export const getSinglePropertyListing = ({
  listingID,
  shareToken,
}: {
  listingID: string
  shareToken?: string
}): Promise<ListingProperty> =>
  axios.get(`v3/listings/${listingID}`, { params: { share_token: shareToken } })

export const getListingsFilters = (): Promise<ListingFilter[]> =>
  axios.get('v3/search/listings/filters')

export const getListingMeetTeam = (): Promise<MeetTeamData[]> => axios.get('v3/product_consultants')

export const getOtherPropertyListings = ({
  id,
  limit,
  order,
  orderBy,
}: {
  id: string
  limit: number
  orderBy: string
  order: string
}) =>
  axios.get(`v3/listings?building_development_id=${id}`, {
    params: { limit, sort_field: orderBy, sort_ascending: order === 'asc' },
  })

export const getAllUserListings = ({
  userID,
}: {
  userID: number
}): Promise<{ total: number; listings: ListingProperty[] }> =>
  axios.get(`v3/listings?user_id=${userID}`)

export const saveProductSelectedListings = ({
  productID,
  listings,
}: {
  productID: number
  listings: number[]
}) => axios.post(`v3/search_products/${productID}/select_listings`, listings)

export const requestCall = ({
  listingID,
  developmentID,
  phone_number,
  userID,
  type,
  first_name,
  last_name,
  gclid,
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
  utm_term,
}: {
  listingID?: string
  developmentID?: string
  packageType: string
  phone_number?: string
  userID: string
  type: string
  first_name: string
  last_name: string
  gclid?: string | null
  utm_campaign?: string | null
  utm_content?: string | null
  utm_medium?: string | null
  utm_source?: string | null
  utm_term?: string | null
}) =>
  axios.post(`v3/users/${userID}/contact_requests`, {
    phone_number,
    listing_id: Number(listingID),
    building_development_id: Number(developmentID),
    type,
    first_name,
    last_name,
    gclid,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  })

export const requestPublicEnquiryCall = ({
  listingID,
  developmentID,
  phone_number,
  email,
  first_name,
  last_name,
  type,
  gclid,
  user_comment,
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
  utm_term,
}: {
  listingID?: string
  packageType: string
  phone_number?: string
  developmentID?: string
  userID: string
  email: string
  first_name: string
  last_name: string
  type: string
  gclid?: string | null
  user_comment?: string
  utm_campaign?: string | null
  utm_content?: string | null
  utm_medium?: string | null
  utm_source?: string | null
  utm_term?: string | null
}) =>
  axios.post('v3/marketplace/contact_requests', {
    phone_number,
    listing_id: Number(listingID),
    building_development_id: Number(developmentID),
    type,
    email,
    first_name,
    last_name,
    gclid,
    user_comment,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  })

export const saveGetPropertyInvestmentIntention = ({
  investment,
}: {
  investment: GetPropertyInvestment
}) => axios.post('v3/users/me/investment_intentions', investment)

export const requestGetPropertyInvestmentContact = ({
  userID,
  request,
}: GetPropertyInvestmentContact) => axios.post(`v3/users/${userID}/contact_requests`, request)

export const saveListing = ({ listingID }: { listingID: number }) =>
  axios.post(`v3/listings/${listingID}/save`)

export const removeSavedListing = ({ listingID }: { listingID: number }) =>
  axios.delete(`v3/listings/${listingID}/save`)

export const saveDevelopmentListing = ({ developmentID }: { developmentID: number }) =>
  axios.post(`v3/building_developments/${developmentID}/save`)

export const removeSavedDevelopmentListing = ({ developmentID }: { developmentID: number }) =>
  axios.delete(`v3/building_developments/${developmentID}/save`)

export const shareListingToken = ({ type, listingID }: { type: string; listingID: number }) =>
  axios.post(`v3/${type}/${listingID}/share`)

export const getListingInvestmentsCalculation = ({
  listingID,
  params,
}: {
  listingID: number
  params: ListingInvestmentsCalculationQuery
}) => axios.get(`v3/listings/${listingID}/analysis`, { params })

export const getListingAreaHighlights = ({ listingID }: { listingID: number }) =>
  axios.get(`v3/listings/${listingID}/area_informations`)

// Marketplace

export const requestCallMarketplace = ({
  userID,
  type,
  packageName,
  service,
  phone_number,
}: {
  userID: string
  type: 'package' | 'individual_service' | 'search_support'
  packageName?: string | null
  service?: string
  phone_number?: string
}) =>
  axios.post(`v3/users/${userID}/contact_requests`, {
    type,
    package: packageName,
    service,
    phone_number,
  })

export const requestDocumentRequestsFilesUrls = (
  requestID: number,
  files: { filename: string; mimeType: string }[],
) =>
  axios.post(`v3/users/me/document_requests/${requestID}/signed_url_v4`, {
    files: files.map((file) => ({ filename: file.filename, mime_type: file.mimeType })),
  })

export const requestDocumentRequestsFiles = (
  requestID: number,
  files: {
    name: string
    objectName: string
    size: number
    category: string
    mimeType: string
  }[],
) =>
  axios.post(`v3/users/me/document_requests/${requestID}/files`, {
    file_objects: files.map((file) => ({
      filename: file.name,
      object_name: file.objectName,
      file_size_in_bytes: file.size,
      category: file.category,
      mime_type: file.mimeType,
    })),
  })

export const requestCallPropertyManagement = ({
  userID,
  phone_number,
}: {
  userID: string
  phone_number?: string
}) =>
  axios.post(`v3/users/${userID}/contact_requests/property_management`, {
    phone_number,
  })

export const getPropertyReports = (): Promise<PropertyReportsListItem[]> =>
  axios.get('v3/property_reports')

export const getPropertyReportAnalysis = ({
  reportID,
  query,
}: {
  reportID: number
  query: {
    time_period_in_years: string
    starting_ltv_percentage_in_bps: string
    mortgage_interest_rate_in_bps: string
  }
}) => axios.get(`v3/property_reports/${reportID}/analysis`, { params: query })

export const getSinglePropertyReport = ({ reportID }: { reportID: number }) =>
  axios.get(`v3/property_reports/${reportID}`)

export const getAreaGuides = () => axios.get('v3/constants/area_guides')

// Development

export const getSingleDevelopment = ({
  developmentID,
  shareToken,
}: {
  developmentID: number
  shareToken?: string
}) =>
  axios.get(`v3/building_developments/${developmentID}`, {
    params: shareToken ? { share_token: shareToken } : {},
  })

export const getDevelopmentAreaHighlights = ({ developmentID }: { developmentID: number }) =>
  axios.get(`v3/building_developments/${developmentID}/area_informations`)

export const getDevelopmentBenefits = ({
  developmentID,
  shareToken,
}: {
  developmentID: number
  shareToken?: string
}) =>
  axios.get(`v3/building_developments/${developmentID}/benefits`, {
    params: shareToken ? { share_token: shareToken } : {},
  })

export const getDevelopmentFacilities = ({
  developmentID,
  shareToken,
}: {
  developmentID: number
  shareToken?: string
}) =>
  axios.get(`v3/building_developments/${developmentID}/facilities`, {
    params: shareToken ? { share_token: shareToken } : {},
  })

// Products
export const createSearchProduct = () => axios.post('v3/search_products', {})

export const getProductPaymentDetails = ({ productID }: { productID: number }) =>
  axios.get(`v3/search_products/${productID}/deposit_payment_details`)

export const makeProductPaymentIntent = ({ productID }: { productID: number }) =>
  axios.post(`v3/search_products/${productID}/deposit_payment_intent`)

export const confirmProductPayment = ({ productID }: { productID: number }) =>
  axios.post(`v3/search_products/${productID}/deposit_payment_confirm`)

interface updateProductTypes {
  productID: number
  payload: {
    is_call_booked?: boolean
    is_call_skipped?: boolean
  }
}

export const updateProduct = ({ productID, payload }: updateProductTypes) =>
  axios.patch(`v3/search_products/${productID}`, payload)

interface GetEmergencyBanner {
  message: string
  severity: string
}

export const getEmergencyBanner = (): Promise<GetEmergencyBanner> =>
  axios.get('v3/emergency_banner')

export const createReportSelfServe = ({
  payload,
}: {
  payload: { name: string; is_user_foreign_national: boolean; listing_options: number[] }
}) => axios.post('v3/property_reports', payload)

export const deletePropertyReport = ({ reportId }: { reportId: number }) =>
  axios.delete(`v3/property_reports/${reportId}`)

export interface MortgageQuoteRate {
  broker_logo_url: string
  interest_rate_in_bps: number
  maximum_loan_size_in_cents: number
  maximum_loan_to_value_percent: number
  monthly_interest_payments_in_cents: number
  product_fee_in_cents: number
}

export interface MortgageQuoteRequest {
  deposit_available_in_cents: number
  estimated_monthly_rent_in_cents: number
  estimated_property_value_in_cents: number
  property_purchase_id?: number
  btl_type: string
  purchase_stage: string
  country_id: number
  is_new_build?: string
}

export interface MortgageQuotes {
  quote_id: number
  five_years_fixed: MortgageQuoteRate[]
  two_years_fixed: MortgageQuoteRate[]
  variable_rate: MortgageQuoteRate[]
}

export interface MortgageQuoteResponse {
  quotes: MortgageQuotes
}

export const createMortgageQuote = (
  request: MortgageQuoteRequest,
): Promise<MortgageQuoteResponse> => axios.post('v3/mortgage_products/quote', request)

export interface LendersQuoteResponse {
  quote_id: number
  lenders: LenderQuote<EligibleProduct>[]
}

export const createLendersQuote = (request: MortgageQuoteRequest): Promise<LendersQuoteResponse> =>
  axios.post('v3/mortgage_products/quote/panel', request)

export interface MortgageProductRequest {
  first_name: string
  last_name: string
  phone_number: string
  quote_id: number
}

export interface PublicMortgageProductRequest extends MortgageProductRequest {
  email: string
}

interface MortgageProductResponse {
  property_purchase_id: number
  partner_name: string
  partner_photo_url: string
  broker_name: string
  broker_logo_url: string
  partner_job_title?: string
}

export const createMortgageProduct = (
  request: MortgageProductRequest | PublicMortgageProductRequest,
): Promise<MortgageProductResponse> => axios.post('v3/mortgage_products', request)

export const getLenders = () => axios.get('v3/mortgage_products/lenders')

export const getMortgageDetails = ({
  investmentID,
}: {
  investmentID: number
}): Promise<MortgageDetailsResponse> => axios.get(`v3/investments/${investmentID}/mortgage_details`)
export interface MortgageProductUpdateRequest {
  investment_id: number
  purchase_type: PurchaseType
}

export const updateMortgageProduct = ({
  investment_id,
  ...request
}: MortgageProductUpdateRequest) =>
  axios.put(`v3/investments/${investment_id}/mortgage_details`, request)

export interface EquityReleaseRequest {
  first_name: string
  last_name: string
  phone_number: string
  property_purchase_id: number
}

export const createEquityRelease = (request: EquityReleaseRequest): Promise<void> =>
  axios.post('v3/mortgage_products/quote_equity_release', request)

export const confirmSubscriptionUpdate = (data: SubscriptionUpdateRequest) =>
  axios.post('v3/update_subscription_standing_orders', data)

export const deleteMortgageDetails = ({ investmentID }: { investmentID: number }) =>
  axios.delete(`v3/investments/${investmentID}/mortgage_details`)

export const getCapitalGrowthByPostcode = (postcode: string): Promise<CapitalGrowth> =>
  axios.get(`v3/capital_rental_growth?postcode=${encodeURIComponent(postcode)}`)

export const getCapitalGrowthByDevelopmentId = (id: string): Promise<CapitalGrowth> =>
  axios.get(`v3/building_developments/${encodeURIComponent(id)}/capital_rental_growth`)

export const getCapitalGrowthByListingId = (id: string): Promise<CapitalGrowth> =>
  axios.get(`v3/listings/${encodeURIComponent(id)}/capital_rental_growth`)

interface CanUpdateCreditCardResponse {
  can_create_subscription_update_link?: boolean
}

interface fetchUpdateCreditCardInfoResponse {
  checkout_session_id?: string
}

export const getCanUpdateCreditCard = (companyId: string): Promise<CanUpdateCreditCardResponse> =>
  axios.get(`v3/companies/${companyId}/subscription/stripe_update/can_create`)

export const fetchUpdateCreditCardInfo = (
  companyId: string,
  successUrl?: string,
  cancelUrl?: string,
): Promise<fetchUpdateCreditCardInfoResponse> =>
  axios.post(`v3/companies/${companyId}/subscription/stripe_update`, {
    success_url: successUrl,
    cancel_url: cancelUrl,
  })

interface fetchOneTimePaymentInfoResponse {
  checkout_session_id?: string
}

export const fetchOneTimePaymentInfo = (
  accountsReceivableId: number,
  successUrl?: string | null,
  cancelUrl?: string | null,
): Promise<fetchOneTimePaymentInfoResponse> =>
  axios.post(`v3/accounts_receivables/${accountsReceivableId}/stripe_payment`, {
    success_url: successUrl,
    cancel_url: cancelUrl,
  })
export const createSellValuation = (args: CreateValuationRequestArgs): Promise<Valuation> =>
  axios.post('v3/sell_products/valuations', args)

export const getPublicSellValuation = ({ valuationShareID }: { valuationShareID: string }) =>
  axios.get(`v3/sell_products/valuations/${valuationShareID}`)

export const getReferralCodes = (): Promise<GetUserReferralsResponse> => axios.get(`v3/referrals`)

export const postReferralCode = (): Promise<{ referralCodes: ApiReferralCode[] }> =>
  axios.post(`v3/referrals`, {})

export const getUserReferredByCode = (): Promise<{ referralCodes: ApiReferralCode }> =>
  axios.get(`v3/referrals/user-referred-by`)

export const deleteTransactionFile = (companyID: number, transactionID: number, fieId: string) =>
  axios.delete(
    `v3/companies/${companyID}/account/transactions/${transactionID}/attachments/${fieId}`,
  )

export const sendInsuranceLead = () => axios.post('v3/partners/sendInsuranceLead')

type PublicLeadArgs = {
  email?: string
  phone_number?: string
  first_name?: string
  last_name?: string
}

export const sendPublicInsuranceLead = (args: PublicLeadArgs) =>
  axios.post('v3/partners/sendPublicInsuranceLead', args)

export const sendLettingsAndManagementLead = () =>
  axios.post('v3/partners/sendLettingsAndManagementLead')

export const sendSolicitorLead = (args: { referral_reason?: string }) =>
  axios.post(`v3/partners/sendSolicitorsLead`, args)

export const sendPublicSolicitorLead = (args: PublicLeadArgs & { referral_reason?: string }) =>
  axios.post(`v3/partners/sendPublicSolicitorsLead`, args)

export const checkCompanyNameAvailability = (companyName: string) =>
  axios.post(`v3/companies/check_company_name`, { company_name: companyName })

export const createPropertySourcingRequest = (payload: PropertySourcingApiArgs) =>
  axios.post('v3/marketplace/property_sourcing_request', payload)
