import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTasks as apiGetTasks, fetchOneTimePaymentInfo } from 'src/api/ggAPI'

export const getTasks = createAsyncThunk('tasks/getTodos', () => {
  return apiGetTasks()
})

export const getOneTimePaymentSession = createAsyncThunk(
  'tasks/getOneTimePaymentSession',
  ({
    accountsReceivableId,
    successUrl,
    cancelUrl,
  }: {
    accountsReceivableId: number
    successUrl?: string
    cancelUrl?: string
  }) => fetchOneTimePaymentInfo(accountsReceivableId, successUrl, cancelUrl),
)

export const initialState = {
  tasks: [],
  upcomingTasks: [],
  isLoading: true,
  oneTimePaymentDetails: {
    isLoading: false,
    checkoutSessionId: null,
    error: null,
  },
}

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearTasks: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getTasks.fulfilled, (state, action) => ({
        ...state,
        tasks: action.payload?.tasks ?? [],
        upcomingTasks: action.payload?.upcoming_tasks ?? [],
        isLoading: false,
      }))
      .addCase(getTasks.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(getOneTimePaymentSession.pending, (state) => ({
        ...state,
        oneTimePaymentDetails: {
          ...state.oneTimePaymentDetails,
          isLoading: true,
        },
      }))
      .addCase(getOneTimePaymentSession.fulfilled, (state, action) => ({
        ...state,
        oneTimePaymentDetails: {
          isLoading: false,
          checkoutSessionId: action.payload.checkout_session_id ?? null,
          error: null,
        },
      }))
      .addCase(getOneTimePaymentSession.rejected, (state) => ({
        ...state,
        oneTimePaymentDetails: {
          isLoading: false,
          checkoutSessionId: null,
          error: 'Something went wrong, please try again.',
        },
      }))
  },
})

export const { clearTasks } = tasksSlice.actions

export default tasksSlice.reducer
