import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  pathname: '',
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setPathname: (state, action) => ({ ...state, pathname: action.payload }),
  },
})

export const { setPathname } = locationSlice.actions

export default locationSlice.reducer
