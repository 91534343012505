import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getInvestments as apiGetInvestments,
  updateInvestmentName as apiUpdateInvestmentName,
  dismissCompleteProduct as apiDismissCompleteProduct,
  deleteProduct as apiDeleteProduct,
  deleteInvestment as apiDeleteInvestment,
  selectInvestment as apiSelectInvestment,
  createSearchProduct as apiCreateSearchProduct,
  createInvestment as apiCreateInvestment,
  updateInvestmentSellBanner,
} from 'src/api/ggAPI'
import { PRODUCT_TYPES } from 'src/config/constants'
import { Investment } from 'src/types/investments'

const { SEARCH } = PRODUCT_TYPES

export const getInvestments = createAsyncThunk('investments/getInvestments', () =>
  apiGetInvestments(),
)

export const updateInvestmentName = createAsyncThunk(
  'investments/updateInvestmentName',
  ({ name, investmentID }) => apiUpdateInvestmentName({ name, investmentID }),
)

export const deleteSearchProduct = createAsyncThunk(
  'investments/deleteSearchProduct',
  ({ productID }, { dispatch }) =>
    apiDeleteProduct({ productID, type: SEARCH }).then(async (response) => {
      await dispatch(getInvestments())
      return response
    }),
)

export const deleteInvestment = createAsyncThunk(
  'investments/deleteInvestment',
  ({ investmentID }: { investmentID: number }) => apiDeleteInvestment({ investmentID }),
)

export const dismissCompleteProduct = createAsyncThunk(
  'investments/dismissCompleteProduct',
  ({ productID, type }) => apiDismissCompleteProduct({ productID, type }),
)

export const selectInvestment = createAsyncThunk(
  'investments/selectInvestment',
  ({ product_type, investment_id, is_holding_co, parent_company_id }, thunkAPI) =>
    apiSelectInvestment({ product_type, investment_id, is_holding_co, parent_company_id }).then(
      async (response) => {
        await thunkAPI.dispatch(getInvestments())
        return response
      },
    ),
)

export const createSearchProduct = createAsyncThunk(
  'investment/createSearchProduct',
  (_, thunkAPI) =>
    apiCreateSearchProduct().then(async (response) => {
      await thunkAPI.dispatch(getInvestments())
      return response
    }),
)

export const createInvestment = createAsyncThunk(
  'investment/createInvestment',
  ({ packageId }: { packageId?: number } = {}) => apiCreateInvestment({ packageId }),
)

export const dismissInvestmentSellBanner = createAsyncThunk(
  'investments/dismissInvestmentSellBanner',
  ({ investmentID, dismiss_sell_banner }: { investmentID: number; dismiss_sell_banner: boolean }) =>
    updateInvestmentSellBanner({ investmentID, dismiss_sell_banner }),
)

interface StateType {
  investments: Investment[]
  isLoading: boolean
  isNameUpdateLoading: boolean
}

export const initialState: StateType = {
  investments: [],
  isLoading: true,
  isNameUpdateLoading: false,
}

const investmentsSlice = createSlice({
  name: 'investments',
  initialState,
  reducers: {
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getInvestments.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getInvestments.fulfilled, (state, action) => ({
      ...state,
      investments: [...action.payload],
      isLoading: false,
    }))
    builder.addCase(updateInvestmentName.pending, (state) => ({
      ...state,
      isNameUpdateLoading: true,
    }))
    builder.addCase(updateInvestmentName.rejected, (state) => ({
      ...state,
      isNameUpdateLoading: false,
    }))
    builder.addCase(updateInvestmentName.fulfilled, (state, action) => {
      state.investments.find((o) => o.id === action.meta.arg.investmentID).name =
        action.meta.arg.name
      state.isNameUpdateLoading = false
    })
    builder.addCase(dismissCompleteProduct.fulfilled, (state, action) => {
      state.investments
        .find((o) => o.id === action.meta.arg.investmentID)
        .products.find((p) => p.id === action.meta.arg.productID).is_dismissed = true
    })
    builder.addCase(createInvestment.fulfilled, (state, action) => ({
      ...state,
      investments: [action.payload, ...state.investments],
      isLoading: false,
    }))
    builder.addCase(deleteInvestment.fulfilled, (state, action) => ({
      ...state,
      investments: state.investments.filter((item) => item.id !== action.meta.arg.investmentID),
    }))
  },
})

export const { setIsLoading } = investmentsSlice.actions

export default investmentsSlice.reducer
