import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isOpen: false,
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showMenu: (state) => ({ ...state, isOpen: true }),
    hideMenu: () => initialState,
  },
})

export const { showMenu, hideMenu } = menuSlice.actions

export default menuSlice.reducer
