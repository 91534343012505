const useSessionStorage = () => {
  const getItemFromSessionStorage = (key: string) => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem(key)
    }
  }

  const saveToSessionStorage = (key: string, value: string, stringify = false) => {
    sessionStorage.setItem(key, stringify ? JSON.stringify(value) : value)
  }

  const removeFromSessionStorage = (key: string) => {
    sessionStorage.removeItem(key)
  }

  return {
    getItemFromSessionStorage,
    saveToSessionStorage,
    removeFromSessionStorage,
  }
}

export default useSessionStorage
