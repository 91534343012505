import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  values: {
    firstname: '',
    middlename: '',
    lastname: '',
  },
  errors: {},
  showErrorMessage: false,
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    initForm: (_state, action) => action.payload,
    clearForm: () => initialState,
    clearFormErrors: (state) => ({
      ...state,
      errors: {},
      showErrorMessage: false,
    }),
    setErrors: (state, action) => {
      const { showErrorMessage } = action.payload
      return showErrorMessage
        ? {
            ...state,
            errors: action.payload.errorsList,
            showErrorMessage,
          }
        : {
            ...state,
            errors: action.payload.errorsList,
          }
    },
    setFormErrors: (state, action) => ({
      ...state,
      errors: {
        ...state.errors,
        form: action.payload.error,
        reason: action.payload.reason,
      },
      showErrorMessage: true,
    }),
    updateForm: (state, action) => {
      let newValues
      if (!action.payload.arrayUpdate) {
        newValues = {
          ...state.values,
          [action.payload.key]: action.payload.value,
        }
      } else {
        newValues = { ...state.values }
        newValues[action.payload.key] = action.payload.value
      }

      return {
        ...state,
        values: newValues,
      }
    },
  },
})

export const { initForm, clearForm, clearFormErrors, setErrors, setFormErrors, updateForm } =
  formSlice.actions

export default formSlice.reducer
