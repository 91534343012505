import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getSingleDevelopment as apiGetSingleDevelopment,
  getDevelopmentAreaHighlights as apiGetDevelopmentAreaHighlights,
  getDevelopmentBenefits as apiGetDevelopmentBenefits,
  getDevelopmentFacilities as apiGetDevelopmentFacilities,
  saveDevelopmentListing as apiSaveDevelopment,
  removeSavedDevelopmentListing as apiRemoveSavedDevelopment,
} from 'src/api/ggAPI'
import { BuildingDevelopment } from 'src/types/BuildingDevelopment'
import AreaHighlight from 'src/types/AreaHighlight'
import Benefits from 'src/types/Benefits'
import Facilities from 'src/types/Facilities'

export const getDevelopmentDetails = createAsyncThunk(
  'developmentDetails/getDevelopmentDetails',
  (
    { developmentID, shareToken }: { developmentID: number; shareToken: string },
    { rejectWithValue },
  ) =>
    apiGetSingleDevelopment({ developmentID, shareToken }).catch(({ response: { status } }) =>
      rejectWithValue({ code: status }),
    ),
)

export const getDevelopmentAreaHighlights = createAsyncThunk(
  'developmentDetails/getDevelopmentAreaHighlights',
  ({ developmentID }: { developmentID: number }, { rejectWithValue }) =>
    apiGetDevelopmentAreaHighlights({ developmentID }).catch(({ response: { status } }) =>
      rejectWithValue({ code: status }),
    ),
)

export const getDevelopmentBenefits = createAsyncThunk(
  'developmentDetails/getDevelopmentBenefits',
  ({ developmentID }: { developmentID: number }, { rejectWithValue }) =>
    apiGetDevelopmentBenefits({ developmentID }).catch(({ response: { status } }) =>
      rejectWithValue({ code: status }),
    ),
)

export const getDevelopmentFacilities = createAsyncThunk(
  'developmentDetails/getDevelopmentFacilities',
  ({ developmentID }: { developmentID: number }, { rejectWithValue }) =>
    apiGetDevelopmentFacilities({ developmentID }).catch(({ response: { status } }) =>
      rejectWithValue({ code: status }),
    ),
)

export const saveDevelopment = createAsyncThunk(
  'developmentDetails/saveDevelopment',
  ({ developmentID }: { developmentID: number }) => apiSaveDevelopment({ developmentID }),
)

export const removeSavedDevelopment = createAsyncThunk(
  'developmentDetails/removeSavedDevelopment',
  ({ developmentID }: { developmentID: number }) => apiRemoveSavedDevelopment({ developmentID }),
)

interface InitialState {
  isLoading: boolean
  // TODO: add types
  development: BuildingDevelopment
  areaHighlights: AreaHighlight[]
  developmentAreaHighlightSelected: AreaHighlight | null
  benefits: Benefits[]
  facilities: Facilities[]
}

export const initialState: InitialState = {
  isLoading: true,
  development: {} as BuildingDevelopment,
  benefits: [],
  areaHighlights: [],
  developmentAreaHighlightSelected: null,
  facilities: [],
}

const developmentDetailsSlice = createSlice({
  name: 'developmentSlice',
  initialState,
  reducers: {
    clearDevelopmentDetails: () => ({
      ...initialState,
    }),
    setDevelopmentAreaHighlightSelected: (state, action) => ({
      ...state,
      developmentAreaHighlightSelected: action.payload,
    }),
    setDevelopmentUserRequestContact: (state, action) => ({
      ...state,
      development: {
        ...state.development,
        has_user_requested_contact: action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getDevelopmentDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.development = action.payload
    })
    builder.addCase(getDevelopmentAreaHighlights.fulfilled, (state, action) => {
      if (action.payload?.length) {
        return {
          ...state,
          areaHighlights: action.payload,
          developmentAreaHighlightSelected: action.payload[0],
          isLoading: false,
        }
      }

      return { ...state }
    })
    builder.addCase(getDevelopmentBenefits.fulfilled, (state, action) => {
      if (action.payload?.benefits.length) {
        return {
          ...state,
          benefits: action.payload?.benefits,
          isLoading: false,
        }
      }

      return { ...state }
    })
    builder.addCase(getDevelopmentFacilities.fulfilled, (state, action) => {
      if (action.payload?.facilities?.length) {
        return {
          ...state,
          facilities: action.payload?.facilities,
          isLoading: false,
        }
      }

      return { ...state }
    })
    builder.addCase(saveDevelopment.fulfilled, (state) => {
      state.development.has_user_saved_listing = true
    })
    builder.addCase(removeSavedDevelopment.fulfilled, (state) => {
      state.development.has_user_saved_listing = false
    })
  },
})

export const {
  clearDevelopmentDetails,
  setDevelopmentAreaHighlightSelected,
  setDevelopmentUserRequestContact,
} = developmentDetailsSlice.actions

export default developmentDetailsSlice.reducer
