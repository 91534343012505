import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'

const initialState = {
  employmentStatus: [],
}

export const getEmploymentStatus = createAsyncThunk(
  'onBoarding/getEmploymentStatus',
  api.fetchEmploymentStatus,
)

const onBoardingSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmploymentStatus.fulfilled, (state, action) => ({
      ...state,
      employmentStatus: action.payload,
    }))
  },
})

export default onBoardingSlice.reducer
