import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'
import Payee from 'src/types/Payee'
import CompanyAccount from 'src/types/CompanyAccount'

interface Filters {
  page?: number
  itemsPerPage?: number
  search?: string | undefined
  pageCount?: number
}

export const getAccountPayees = createAsyncThunk(
  'payees/getAccountPayees',
  ({ companyID, filters = {} }: { companyID: number; filters: Filters }) =>
    api.getAccountPayees(companyID, filters),
)

export const getCompanyAccounts = createAsyncThunk(
  'payees/getCompanyAccounts',
  api.getCompanyAccounts,
)

export const getPayee = createAsyncThunk(
  'payees/getPayee',
  ({ companyID, payeeID }: { companyID: number; payeeID: number }) =>
    api.getPayee(companyID, payeeID),
)

export const editPayee = createAsyncThunk(
  'payees/editPayee',
  ({ companyID, payeeID, payee }: { companyID: number; payeeID: number; payee: Payee }) => {
    return api.editPayee(companyID, payeeID, payee)
  },
)

interface PaymentsInterface {
  editablePayee: Payee & { isLoading: boolean }
  previousPayees: {
    data: Payee[]
    isLoading: boolean
    isLoadingMore: boolean
  }
  companyAccounts: {
    data: CompanyAccount[]
    isLoading: boolean
  }
}

const initialState: PaymentsInterface = {
  previousPayees: {
    data: [],
    isLoadingMore: false,
    isLoading: false,
  },
  editablePayee: {
    name: '',
    sort_code: '',
    account_number: '',
    isLoading: false,
  },
  companyAccounts: {
    data: [],
    isLoading: false,
  },
}

const payeesSlice = createSlice({
  name: 'payees',
  initialState,
  reducers: {},
  extraReducers: {
    [getAccountPayees.pending]: (state, action) => ({
      ...state,
      previousPayees: {
        ...state.previousPayees,
        isLoading:
          action.meta.arg.filters.page !== undefined ? action.meta.arg.filters.page === 1 : true,
        isLoadingMore: action.meta.arg.filters.page !== 1,
      },
    }),
    [getAccountPayees.fulfilled]: (state, action) => {
      const { payees, ...payload } = action.payload
      return {
        ...state,
        previousPayees: {
          isLoading: false,
          isLoadingMore: false,
          data: payload.pagination.page === 1 ? payees : [...state.previousPayees.data, ...payees],
          itemCount: payload.pagination.item_count,
          page: payload.pagination.page,
          itemsPerPage: payload.pagination.items_per_page,
          search: payload.search,
        },
      }
    },
    [getAccountPayees.rejected]: (state) => ({
      ...state,
      previousPayees: {
        ...state.previousPayees,
        isLoadingMore: false,
        isLoading: false,
      },
    }),
    [getPayee.pending.type]: (state) => {
      state.editablePayee.isLoading = true
    },
    [getPayee.fulfilled.type]: (state, action) => ({
      ...state,
      editablePayee: {
        ...action.payload,
        isLoading: false,
      },
    }),
    [getPayee.rejected.type]: (state) => {
      state.editablePayee.isLoading = false
    },
    [getCompanyAccounts.pending.type]: (state) => {
      state.companyAccounts.isLoading = true
    },
    [getCompanyAccounts.fulfilled.type]: (state, action) => {
      state.companyAccounts.isLoading = false
      state.companyAccounts.data = action.payload.accounts
    },
    [getCompanyAccounts.rejected.type]: (state) => {
      state.companyAccounts.isLoading = false
    },
  },
})

export default payeesSlice.reducer
