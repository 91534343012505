import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCountries as getCountriesAPI } from 'src/api/ggAPI'
import { Country } from 'src/types/Country'

export const getCountries = createAsyncThunk('countries', () => getCountriesAPI())
interface CountriesState {
  userCountry: Country | null
  isLoading: boolean
  countries: Country[]
}

export const initialState: CountriesState = {
  countries: [],
  isLoading: false,
  userCountry: null,
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setUserCountry: (state, action) => ({
      ...state,
      userCountry: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => ({
      ...state,
      isLoading: true,
    })),
      builder.addCase(getCountries.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        countries: action.payload.countries || [],
      })),
      builder.addCase(getCountries.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
  },
})

export const { setUserCountry } = countriesSlice.actions

export default countriesSlice.reducer
