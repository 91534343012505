export const PRICE_MAX_DEFAULT = 'No max'
export const LOCATION_DEFAULT = 'Any'
export const BUILD_TYPE_DEFAULT = 'Any'
export const DEVELOPMENT_DEFAULT = 'Any'
export const COMPLETION_DATE_DEFAULT = 'Any'

export const SORT_VALUES = {
  FEATURE: 'featured',
  MADE_VISIBLE_AT_DESCENDING: 'made_visible_at_descending',
  PRICE_DESCENDING: 'price_descending',
  PRICE_ASCENDING: 'price_ascending',
  GROSS_YIELD_DESCENDING: 'gross_yield_descending',
  GROSS_YIELD_ASCENDING: 'gross_yield_ascending',
  MINIMUM_DEPOSIT_DESCENDING: 'minimum_deposit_descending',
  MINIMUM_DEPOSIT_ASCENDING: 'minimum_deposit_ascending',
  RENTAL_INCOME_DESCENDING: 'rental_income_descending',
  RENTAL_INCOME_ASCENDING: 'rental_income_ascending',
}

export const sortBy = [
  {
    label: 'Featured',
    value: SORT_VALUES.FEATURE,
  },
  {
    label: 'Newly added',
    value: SORT_VALUES.MADE_VISIBLE_AT_DESCENDING,
  },
  {
    label: 'Price - High to low',
    value: SORT_VALUES.PRICE_DESCENDING,
  },
  {
    label: 'Price - Low to high',
    value: SORT_VALUES.PRICE_ASCENDING,
  },
  {
    label: 'Gross yield - High to low',
    value: SORT_VALUES.GROSS_YIELD_DESCENDING,
  },
  {
    label: 'Gross yield - Low to high',
    value: SORT_VALUES.GROSS_YIELD_ASCENDING,
  },
  {
    label: 'Minimum deposit - High to low',
    value: SORT_VALUES.MINIMUM_DEPOSIT_DESCENDING,
  },
  {
    label: 'Minimum deposit - Low to high',
    value: SORT_VALUES.MINIMUM_DEPOSIT_ASCENDING,
  },
  {
    label: 'Rental income - High to low',
    value: SORT_VALUES.RENTAL_INCOME_DESCENDING,
  },
  {
    label: 'Rental income - Low to high',
    value: SORT_VALUES.RENTAL_INCOME_ASCENDING,
  },
]

export const sortMapping: { [key: string]: { sort_field: string; asc: boolean } | null } = {
  [SORT_VALUES.FEATURE]: null,
  [SORT_VALUES.MADE_VISIBLE_AT_DESCENDING]: { sort_field: 'made_visible_at', asc: false },
  [SORT_VALUES.PRICE_ASCENDING]: { sort_field: 'price_in_cents', asc: true },
  [SORT_VALUES.PRICE_DESCENDING]: { sort_field: 'price_in_cents', asc: false },
  [SORT_VALUES.GROSS_YIELD_ASCENDING]: { sort_field: 'gross_yield', asc: true },
  [SORT_VALUES.GROSS_YIELD_DESCENDING]: { sort_field: 'gross_yield', asc: false },
  [SORT_VALUES.MINIMUM_DEPOSIT_ASCENDING]: {
    sort_field: 'minimum_estimated_deposit_in_cents',
    asc: true,
  },
  [SORT_VALUES.MINIMUM_DEPOSIT_DESCENDING]: {
    sort_field: 'minimum_estimated_deposit_in_cents',
    asc: false,
  },
  [SORT_VALUES.RENTAL_INCOME_ASCENDING]: { sort_field: 'rental_income_in_cents', asc: true },
  [SORT_VALUES.RENTAL_INCOME_DESCENDING]: { sort_field: 'rental_income_in_cents', asc: false },
}

export const sortMappingDev = {
  made_visible_at: 'listing_last_made_visible_at',
  gross_yield: 'max_gross_yield',
  price_in_cents: 'min_active_price_in_cents',
  minimum_deposit_in_cents: 'min_active_deposit_in_cents',
  rental_income_in_cents: 'max_rental_price_in_cents',
}

export const maxPrices = [
  { name: PRICE_MAX_DEFAULT, value: '' },
  { name: '£150,000', value: 15000000 },
  { name: '£200,000', value: 20000000 },
  { name: '£250,000', value: 25000000 },
  { name: '£300,000', value: 30000000 },
  { name: '£350,000', value: 35000000 },
  { name: '£400,000', value: 40000000 },
  { name: '£450,000', value: 45000000 },
  { name: '£500,000', value: 50000000 },
]

export const PRICE_RANGE = {
  MIN: 6000000,
  MAX: 95000000,
  STEP: 500000,
}

export const DEPOSITS = {
  MIN: 2500000,
  MAX: 30000000,
  STEP: 500000,
}

export const BUILD_TYPE = {
  NEW_BUILD: 1,
  SECOND_HAND: 0,
}

export const buildTypes = [
  { name: BUILD_TYPE_DEFAULT, value: '' },
  { name: 'New Build', value: BUILD_TYPE.NEW_BUILD },
  { name: 'Second Hand', value: BUILD_TYPE.SECOND_HAND },
]
