import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import AccountAnalyticsData from 'src/types/AccountAnalytics'
import AnalyticsFilters from 'src/types/AccountAnalyticsFilters'

export const getAccountAnalytics = createAsyncThunk(
  'analytics/getAccountAnalytics',
  ({ companyID, filters }: { companyID: number; filters: AnalyticsFilters }) =>
    api.getAccountAnalytics(companyID, filters),
)

interface AccountAnalyticsState {
  cashflow: {
    isLoading: boolean
    data: AccountAnalyticsData | null
    error: string | null
  }
}

const initialState: AccountAnalyticsState = {
  cashflow: {
    isLoading: true,
    data: null,
    error: null,
  },
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountAnalytics.pending, () => initialState)

      .addCase(getAccountAnalytics.fulfilled, (state, action) => ({
        ...state,
        cashflow: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      }))

      .addCase(getAccountAnalytics.rejected, (state, action) => ({
        ...state,
        cashflow: {
          ...state.cashflow,
          isLoading: false,
          error: action.payload?.data?.error || 'Failed to load analytics. Please try again.',
        },
      }))
  },
})

export default analyticsSlice.reducer
