import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DELETE_USER } from 'src/config/constants'

import * as api from 'src/api/ggAPI'
import { logout } from './user.slice'

export const getNewCompaniesInProgressTodos = createAsyncThunk(
  'todos/newCompanies',
  api.getNewCompaniesInProgressTodos,
)

export const initialState = {
  isLoading: false,
  newCompaniesInProgress: [],
}

const todosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    clearTodos: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNewCompaniesInProgressTodos.pending, (state) => ({
      ...state,
      isLoading: true,
    })),
      builder.addCase(DELETE_USER, () => initialState),
      builder.addCase(logout.fulfilled, () => initialState),
      builder.addCase(logout.rejected, () => initialState),
      builder.addCase(getNewCompaniesInProgressTodos.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        newCompaniesInProgress: action.payload,
      }))
  },
})

export const { clearTodos } = todosSlice.actions

export default todosSlice.reducer
