export enum AccountsStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  SUBMITTED = 'SUBMITTED',
  SIGNATURES_REQUESTED = 'SIGNATURES_REQUESTED',
  SIGNATURES_SUBMITTED = 'SIGNATURES_SUBMITTED',
  COMPLETED = 'COMPLETED',
}

export interface AccountsSubmissionDetails {
  accounting_period_end_date: string
  accounting_period_start_date: string
  accounting_deadline: string
  status: keyof typeof AccountsStatus
}
