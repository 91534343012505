import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'
import {
  updateUserPropertyPurchase,
  updatePropertyPurchase as apiUpdatePropertyPurchase,
  declinedPaymentPP as apiDeclinedPaymentPP,
} from 'src/api/ggAPI'
import { logout } from './user.slice'

interface PaymentPayload {
  payment_method: string
  promo: string
}

export const updatePropertyPurchase = createAsyncThunk(
  'propertyPurchase/update',
  apiUpdatePropertyPurchase,
)

interface GetPropertyPurchaseArgs {
  propertyID: number
  /** `ignoreNPS` is used in middleware file */
  ignoreNPS?: boolean
}

export const getPropertyPurchase = createAsyncThunk(
  'propertyPurchase/get',
  ({ propertyID }: GetPropertyPurchaseArgs) => {
    return api.getPropertyPurchase(propertyID)
  },
)

export const updatePP = createAsyncThunk(
  'propertyPurchase/update',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ppID, data, path, state }) => api.updatePP(ppID, data),
)

export const sendPaymentInfo = createAsyncThunk(
  'propertyPurchase/sendPaymentInfo',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ppID, data, path }) => api.updatePP(ppID, data),
)

export const confirmPayment = createAsyncThunk(
  'propertyPurchase/confirmPayment',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ppID, data, path }: { ppID: number; data: PaymentPayload; path?: string }) =>
    api.confirmPayment(ppID, data),
)

export const finalisePP = createAsyncThunk(
  'propertyPurchase/finalise',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ppID, path }) => api.finalisePropertyPurchase(ppID),
)

export const markPaymentAsPaid = createAsyncThunk(
  'propertyPurchase/markPaymentAsPaid',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ppID, data, path }: { ppID: number; data: PaymentPayload; path?: string }) =>
    api.markPaymentAsPaid(ppID, data),
)

export const recoverPPFromSession = createAsyncThunk(
  'propertyPurchase/recoverPPFromSession',
  () => {
    const propertyIDFromSession = sessionStorage.getItem('propertyID')
    return propertyIDFromSession && api.getPropertyPurchase(propertyIDFromSession)
  },
)

export const updatePurchaseDetails = createAsyncThunk(
  'propertyPurchase/updatePurchaseDetails',
  async (payload, { dispatch, getState }) => {
    const { user, propertyPurchase } = getState()
    if (!payload?.isHoldingCo && !payload?.isSubsidiary) {
      const ppShareholders = propertyPurchase.shareholder_details
      const soleShareholder = {
        collection: [
          {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            allocated_shares: 100,
            is_director: true,
            is_existing_user: true,
          },
        ],
      }
      await apiUpdatePropertyPurchase({
        propertyID: propertyPurchase.id,
        data: {
          shareholder_details:
            ppShareholders.collection.length > 1 ? ppShareholders : soleShareholder,
        },
      })
    }
    await updateUserPropertyPurchase(propertyPurchase.id, user.id, {
      source_of_funds: payload.source_of_funds,
      source_of_funds_other_reason: payload.other_reason,
    })
    dispatch(
      updatePP({
        ppID: propertyPurchase.id,
        data: {
          purchase_details: payload.purchase_details,
          process_status: payload.purchase_details.process_status,
        },
        path: payload.path,
        state: {
          companyType: payload?.isHoldingCo
            ? 'HOLDCO'
            : payload?.isSubsidiary
              ? 'SUBSIDIARY'
              : 'SPV',
        },
      }),
    )
  },
)

export const addPropertyAgentEmail = createAsyncThunk(
  'propertyPurchase/addPropertyAgentEmail',
  async (payload, { dispatch }) => {
    const { propertyID, data } = payload
    return dispatch(updatePropertyPurchase({ propertyID, data }))
  },
)

export const declinedPayment = createAsyncThunk(
  'propertyPurchase/declinedPayment',
  async ({ propertyID }: { propertyID: number }) => {
    return apiDeclinedPaymentPP({ propertyID })
  },
)

export const initialState = null

const propertyPurchaseSlice = createSlice({
  name: 'propertyPurchase',
  initialState,
  reducers: {
    deletePP: () => initialState,
    updateCompletionDate: (state, action) => ({
      ...state,
      purchase_details: { ...state?.purchase_details, completion_date: action.payload },
    }),
    setPPError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    updateUseDirectIncorp: (state, action) => ({
      ...state,
      use_direct_incorporation: action.payload,
    }),
  },
  extraReducers: {
    [updatePropertyPurchase.fulfilled]: (state, action) => ({
      ...action.payload,
    }),
    [getPropertyPurchase.fulfilled]: (state, action) => ({ ...state, ...action.payload }),
    [updatePP.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      error: '',
    }),
    [updatePP.rejected]: (state, action) => ({
      ...state,
      error: action.error.message,
    }),
    [recoverPPFromSession.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [addPropertyAgentEmail.fulfilled]: (state, action) => ({ ...state, ...action.payload }),
    [logout]: () => initialState,
  },
})

export const { deletePP, updateCompletionDate, setPPError, updateUseDirectIncorp } =
  propertyPurchaseSlice.actions

export default propertyPurchaseSlice.reducer
