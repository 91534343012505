import { AccountsStatus } from 'src/types/AccountingDetails'

import { ProductType } from 'src/types/investments'

export const NON_AUTH_ROUTES = [
  '/',
  '/forgot-password/enter-email',
  '/onboarding/create-account/',
  '/buy-to-let-property-for-sale',
  '/compare-limited-company-buy-to-let-mortgages',
  '/create-company',
  '/onboarding/personal-details',
]

export const PRIVACY_POLICY_URL = 'https://www.getground.co.uk/privacy-policy'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_AUTH = 'DELETE_AUTH'

// Cookies
export const WS_FLOW_SIGNUP_IN_PROGRESS = 'ws_flow_company_sign_up_in_progress'

// Toast constants
export const SHOW_TOAST = 'SHOW_TOAST'
export const CLEAR_ALL_TOASTS = 'CLEAR_ALL_TOASTS'

export const labels = {
  full_time: 'Employed full-time',
  part_time: 'Employed part-time',
  self_employed: 'Self-employed',
  unemployed: 'Unemployed',
  pensioner: 'Pensioner',
  student: 'Student',
  other: 'Other',
  '0_to_24999': 'Between £0 and £24,999',
  '25000_to_49999': 'Between £25,000 and £49,999',
  '50000_to_74999': 'Between £50,000 and £74,999',
  '75000_to_99999': 'Between £75,000 and £99,999',
  '100000_and_up': 'Over £100,000',
}

export const PFS_UPPER_TX_LIMIT = 250000
export const MODULR_UPPER_TX_LIMIT = 250000

export const GG_SEARCH_FEE = '0.99%'
export const MINIMUM_GG_SEARCH_FEE = '£1,499'

export const documentHelpInfos = [
  {
    title: 'Currencycloud Terms of Use',
    body: 'This document covers the terms and conditions which govern the use of your Currencycloud account. Currencycloud allows cross border payments and currency conversion services to our customers on the GetGround platform.',
  },
  {
    title: 'Modulr Terms and Conditions',
    body: 'This document sets out the terms and conditions which govern the use of Modulr, our account provider. GetGround acts as the interface for customers to make transfers on the GetGround platform, however, it is Modulr that provides and regulates the company account and its transactions.',
  },
  {
    title: 'Direct Incorporation AoA',
    body: 'These are the Articles of Association of your GetGround company, which is a standard document in company creation. They write out the rules of the company, setting out the rights and responsibilities of the directors, the value of the shares, and how the company is run, governed and administered.',
  },
  {
    title: "Owner's Loan",
    body: 'An Owner’s Loan structures providing property purchase funds to the company. It is set at the property price (in relative proportion to your shareholding) as standard when your company is set up.',
    body1:
      'The Owner’s Loan document can be amended in the event that your property purchase price changes or you find a new property to purchase - we do this as part of the subscription fee for you.',
  },
  {
    title: 'Exchange Minutes',
    body: 'Exchange Minutes outlines that the company will purchase the specified property.',
    body1:
      'The Exchange Minutes document can be amended in the event that you find a new property to purchase - we do this as part of the subscription fee for you.',
  },
  {
    title: 'Share Certificate',
    body: 'The Share Certificate is a document that reflects your ownership of a specified number of shares in the company.',
  },
  {
    title: 'Direct Incorporation Share Certificate',
    body: 'The Share Certificate is a document that reflects your ownership of a specified number of shares in the company.',
  },
  {
    title: 'GetGround Terms',
    body: 'These are the terms and conditions which govern your use of the GetGround platform and which ensure that the companies created at GetGround are used solely for buy-to-let purposes and not for other undertakings, such as trading businesses.',
  },
  {
    title: 'Direct Incorporation SHA - Sole',
    body: 'This is a Shareholders Agreement which sits alongside the Articles of Association. Its purpose is to protect the shareholders’ investments in the company, establish and maintain the relationship between the shareholders, and govern how the company is run.',
    body1:
      'It sets out how to do certain things and how to resolve any dispute should the shareholders of the company have a disagreement. It ensures that it is clear how decisions between shareholders are made about the property investment.',
  },
  {
    title: 'Direct Incorporation SHA - JV',
    body: 'This is a Shareholders Agreement which sits alongside the Articles of Association. Its purpose is to protect the shareholders’ investments in the company, establish and maintain the relationship between the shareholders, and govern how the company is run.',
    body1:
      'It sets out how to do certain things and how to resolve any dispute should the shareholders of the company have a disagreement. It ensures that it is clear how decisions between shareholders are made about the property investment.',
  },
  {
    title: 'Direct Incorporation Resolutions - Sole',
    body: 'This document sets out details of the company, such as the names of the directors, the company secretary details, the registered address of the company and the number of shares held by each shareholder.',
  },
  {
    title: 'Direct Incorporation Resolutions - JV',
    body: 'This document sets out details of the company, such as the names of the directors, the company secretary details, the registered address of the company and the number of shares held by each shareholder.',
  },
  {
    title: 'Certificate of Incorporation',
    body: 'A Certificate of Incorporation is a legal document that shows that you have formed and registered your limited company with Companies House. It shows the name of your company, its registered number, the date it was incorporated, and where the company’s registered office is located.',
  },
]

// Help centre
export const helpEmail = 'gethelp@getground.co.uk'
export const helpPhone = '+44 745815 8234'
export const helpCentreURL = 'https://www.getground.co.uk/en/help'
export const helpSchedulingURL = 'https://calendly.com/getground-support/call'

// GG Webstite
export const ggWebsiteLandingPage = 'https://www.getground.co.uk/'

// Property Information
export const REQUEST_CALL_NUMBER = '+442036087051'
export const REQUEST_CALL_NUMBER_WHATSAPP = '447407837450'

// Currencies

export const TOTAL_CURRENCIES = 31

// HoldingCo

export const HOLDING_CO = 'HOLDCO'
export const SUBSIDIARY = 'SUBSIDIARY'
export const STANDALONE_SPV = 'SPV'
export const HOLDING_CO_SUBSCRIPTION = 'SUBSCRIPTION_HOLDCO'
export const SUBSIDIARY_SUBSCRIPTION = 'SUBSCRIPTION_SUBSIDIARY'
export const HOLDING_CO_SETUP = 'SIGNUP_FEE_HOLDCO'
export const SUBSIDIARY_SETUP = 'SIGNUP_FEE_SUBSIDIARY'

// Requested documents / Secure Upload Portal

export const IDENTIFICATION = 'IDENTIFICATION'
export const SELFIE = 'SELFIE'
export const PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS'
export const SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS'

// For more information on the following company statuses, please see following document:
// https://getground.atlassian.net/wiki/spaces/PROD/pages/746356739/Company+status+explanations+for+direct+incorporation
export const companyStatusCodes = {
  IN_DESIGN: 'IN_DESIGN',
  RESERVED_NAME: 'RESERVED_NAME',
  UNINCORPORATED: 'UNINCORPORATED', // database default
  INCORPORATION_FAILED: 'INCORPORATION_FAILED',
  INCORPORATION_PENDING: 'INCORPORATION_PENDING',
  INCORPORATION_REJECTED: 'INCORPORATION_REJECTED',
  INCORPORATED: 'INCORPORATED',
  ASSOCIATED: 'ASSOCIATED',
  TRANSFER_PENDING: 'TRANSFER_PENDING',
  SIGNING_PENDING: 'SIGNING_PENDING',
  COMPLETE: 'COMPLETE',
  DISSOLVED: 'DISSOLVED', // There are companies in the db with this status, set manually. Company has been dissolved.
  OFF_PLATFORM: 'OFF_PLATFORM', // There are companies in the db with this status, set manually. Company left the platform.
}

// Snowplows AuthModal events
export const PUBLIC_MARKETPLACE_AUTH_EVENTS = {
  eventOnSignUp: { category: 'unauth_property_listings_sign_up_page', action: 'sign_up' },
  eventOnLogin: {
    category: 'unauth_property_listings_sign_in_page',
    action: 'sign_in',
  },
  eventOnSwitchingToLogin: {
    category: 'unauth_property_listings_sign_up_page',
    action: 'change_to_sign_in',
  },
  eventOnSwitchingToSignUp: {
    category: 'unauth_property_listings_sign_in_page',
    action: 'change_to_sign_up',
  },
}

export const SHARED_MENU_AUTH_EVENTS = {
  eventOnSignUp: { category: 'share_listing_menu', action: 'sign_up' },
  eventOnLogin: {
    category: 'share_listing_menu',
    action: 'sign_in',
  },
  eventOnSwitchingToLogin: {
    category: 'share_listing_menu',
    action: 'change_to_sign_in',
  },
  eventOnSwitchingToSignUp: {
    category: 'share_listing_menu',
    action: 'change_to_sign_up',
  },
}

export const SHARED_NAV_AUTH_EVENTS = {
  eventOnSignUp: { category: 'share_listing_nav', action: 'sign_up' },
  eventOnLogin: {
    category: 'share_listing_nav',
    action: 'sign_in',
  },
  eventOnSwitchingToLogin: {
    category: 'share_listing_nav',
    action: 'change_to_sign_in',
  },
  eventOnSwitchingToSignUp: {
    category: 'share_listing_nav',
    action: 'change_to_sign_up',
  },
}

export const SHARED_EXPIRED_TOKEN_AUTH_EVENTS = {
  eventOnSignUp: { category: 'share_listing_token_expired', action: 'sign_up' },
  eventOnLogin: {
    category: 'share_listing_token_expired',
    action: 'sign_in',
  },
  eventOnSwitchingToLogin: {
    category: 'share_listing_token_expired',
    action: 'change_to_sign_in',
  },
  eventOnSwitchingToSignUp: {
    category: 'share_listing_token_expired',
    action: 'change_to_sign_up',
  },
}

export const SHARED_AUTH_EVENTS = {
  eventOnSignUp: { category: 'share_listing', action: 'sign_up' },
  eventOnLogin: {
    category: 'share_listing',
    action: 'sign_in',
  },
  eventOnSwitchingToLogin: {
    category: 'share_listing',
    action: 'change_to_sign_in',
  },
  eventOnSwitchingToSignUp: {
    category: 'share_listing',
    action: 'change_to_sign_up',
  },
}

// Thumbnail types
export const USER_UPLOAD = 'USER_UPLOAD'
export const GOOGLE_STREET_VIEW = 'GOOGLE_STREET_VIEW'

export const ACCOUNTS_STATUS = {
  [AccountsStatus.NOT_STARTED]: 'Not started',
  [AccountsStatus.STARTED]: 'Waiting for user input',
  [AccountsStatus.SUBMITTED]: 'Accounts being prepared',
  [AccountsStatus.SIGNATURES_REQUESTED]: "Waiting for shareholder's signature",
  [AccountsStatus.SIGNATURES_SUBMITTED]: 'Completed',
  [AccountsStatus.COMPLETED]: 'Completed',
}

export const REQUIRED_MESSAGE = 'This field is required'

export const PRODUCT_TYPES: { [key: string]: ProductType } = {
  COMPANY: 'COMPANY',
  SEARCH: 'SEARCH',
  MORTGAGE: 'MORTGAGE',
}

export const PROCESS_STATUS_KEYS = {
  NO_PROPERTY_YET: 'NO_PROPERTY_YET',
  PROPERTY_IN_MIND: 'PROPERTY_IN_MIND',
  MADE_AN_OFFER: 'MADE_AN_OFFER',
}

export const processStatusOption = [
  { text: "I haven't found a property yet", value: PROCESS_STATUS_KEYS.NO_PROPERTY_YET },
  { text: 'I have a property in mind', value: PROCESS_STATUS_KEYS.PROPERTY_IN_MIND },
  { text: 'I have made an offer on the property', value: PROCESS_STATUS_KEYS.MADE_AN_OFFER },
]

export const UK_COUNTRY_ID = 235

export const SERVICES_OFFERED = {
  BTL_MORTGAGES: 'btl_mortgages',
  FIND_PROPERTY: 'find_property',
  PROPERTY_MANAGEMENT: 'property_management',
  INSURANCE: 'insurance',
  FURNITURE: 'furniture',
  SOLICITORS: 'solicitors',
  ACCOUNTANT: 'accountant',
}

export const DEFAULT_INTEREST_RATE = 6

export const USER_EMPLOYMENT_STATUS = {
  full_time: 'Employed full-time',
  part_time: 'Employed part-time',
  self_employed: 'Self-employed',
  unemployed: 'Unemployed',
  pensioner: 'Pensioner',
  student: 'Student',
  other: 'Other',
}

export const INSURANCE_UPSELL_CARD_DISMISSED = 'has_dismissed_insure_invest_upsell'
export const DEFAULT_USER_IDS = [1, 2]
