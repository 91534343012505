import { styled } from '@material-ui/core'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { DEFAULT_DURATION } from 'src/utils/toasts'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: `${theme.ggPalette.success[300]} !important`,
    color: `${theme.ggPalette.success[900]} !important`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    fontWeight: 500,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: `${theme.ggPalette.danger[300]} !important`,
    color: `${theme.ggPalette.danger[900]} !important`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    fontWeight: 500,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: `${theme.ggPalette.warning[300]} !important`,
    color: `${theme.ggPalette.warning[900]} !important`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    fontWeight: 500,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: `${theme.ggPalette.teal[100]} !important`,
    color: `${theme.ggPalette.teal[500]} !important`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    fontWeight: 500,
  },
}))

function SnackbarWrapper() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      maxSnack={2}
      hideIconVariant
      autoHideDuration={DEFAULT_DURATION}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    />
  )
}

export default SnackbarWrapper
