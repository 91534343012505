import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from 'src/api/companyOnboardingAPI'
import { type UpdateCompanyOnboarding } from 'src/api/companyOnboardingAPI'
import { CompanyOnboardingState } from 'src/types/CompanyOnboarding'

export const initialState: CompanyOnboardingState = {
  id: null,
  initial_company_details: {
    company_name: null,
    registration_number: null,
    number_of_properties: null,
    is_company_england_or_wales: null,
    is_vat_registered: null,
    is_owned_by_natural_persons: null,
    has_employees_or_payroll: null,
    has_only_residential_purposes: null,
    status: 'NOT_STARTED',
  },
  payment_details: {
    subscription_fee: null,
    status: 'NOT_STARTED',
  },
  user_id_check: {
    status: 'NOT_STARTED',
  },
  company_details: {
    utr_number: null,
    auth_code: null,
    open_business_account: null,
    has_loans_over_10000_pounds: null,
    user_holds_share_certificates: null,
    user_holds_directors_register: null,
    has_company_been_subject_litigation: null,
    has_company_been_insolvent: null,
    details_company_been_subject_litigation: null,
    details_loans_over_10000_pounds: null,
    details_company_been_insolvent: null,
    beneficiaries: null,
    all_shareholders_over_18: null,
    confirm_shareholders_match_register: null,
    status: 'NOT_STARTED',
  },
  property_details: {
    properties: [],
    status: 'NOT_STARTED',
  },
  accountant_details: {
    has_personal_accountant: null,
    accountant_email: null,
    accountant_name: null,
    confirm_authorise_contact: null,
    status: 'NOT_STARTED',
  },
  isLoading: true,
  hideSave: false,
  adjustments: {
    months_free: 0,
  },
}

export const createCompanyOnboarding = createAsyncThunk(
  'companyOnboarding/createCompanyOnboarding',
  api.createCompanyOnboarding,
)

export const getCompanyOnboarding = createAsyncThunk(
  'companyOnboarding/getCompanyOnboarding',
  ({ stockCompanyID }: { stockCompanyID: number }) => api.getCompanyOnboarding({ stockCompanyID }),
)

export const updateCompanyOnboarding = createAsyncThunk(
  'companyOnboarding/updateCompanyOnboarding',
  ({ stockCompanyID, request }: UpdateCompanyOnboarding) =>
    api.updateCompanyOnboarding({ stockCompanyID, request }),
)

/* In-eligible stock company */
export const updateInEligibleCompany = createAsyncThunk(
  'companyOnboarding/updateInEligibleCompany',
  ({ stockCompanyID }: { stockCompanyID: string }) =>
    api.updateInEligibleCompany({ stockCompanyID }),
)

const companyOnboardingSlice = createSlice({
  name: 'companyOnboarding',
  initialState,
  reducers: {
    clearData: () => initialState,
    toggleSaveButton: (state, action) => {
      state.hideSave = action.payload ?? !state.hideSave
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyOnboarding.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getCompanyOnboarding.fulfilled, (state, action) => ({
        ...action.payload,
        isLoading: false,
      }))
      .addCase(updateCompanyOnboarding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCompanyOnboarding.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateCompanyOnboarding.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
        isLoading: false,
      }))
      .addCase(updateInEligibleCompany.fulfilled, () => ({
        ...initialState,
        isLoading: false,
      }))
  },
})

export const { toggleSaveButton } = companyOnboardingSlice.actions

export default companyOnboardingSlice.reducer
