import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { init, browserTracingIntegration } from '@sentry/react'

import { queryClient, QueryClientProvider } from './utils/reactQueryConfig'
import App from './pages/index'
import store from './state/store'
import { initializeLogRocket } from './utils/functions'
import { ThemeProvider } from '@material-ui/core'
import { defaultTheme as GGTheme } from '@gground/capcom.themes'

init({
  dsn: import.meta.env.VITE_SENTRY_DSN || '',
  integrations: [
    browserTracingIntegration({
      // Control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/.*module-core\.backend\.staging\.getground\.co\.uk\/api/,
        /^https:\/\/.*module-core\.backend\.preview\.getground\.co\.uk\/api/,
        /^https:\/\/.*module-core\.backend\.getground\.co\.uk\/api/,
      ],
    }),
  ],
  environment: import.meta.env.MODE,
  release: `${import.meta.env.VITE_APP_VERSION}`,

  // Performance Monitoring
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE || 0.0),
})

initializeLogRocket()

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={GGTheme}>
          <App />
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,

  document.getElementById('root'),
)
