import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'
import { terminateCompany, offboardCompany } from './company.slice'
import { logout } from './user.slice'
import { getWalletDetails } from './wallet.slice'

export const getCompaniesForUser = createAsyncThunk(
  'companies/getCompaniesForUser',
  async (_arg, { getState }) => {
    const userID = getState().user.id
    return api.getCompaniesForUser(userID)
  },
)

// @TODO: [KW-8] do we need types here? If so which ones?
const initialState: any[] = []

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: (_, action) => action.companies,
    clearCompanies: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCompaniesForUser.fulfilled, (_, action) => action.payload.companies || [])

    builder.addCase(getWalletDetails.fulfilled, (state, action) =>
      state.map((company) =>
        company.company_name === action.payload.account_name
          ? { ...company, account_balance_in_cents: action.payload.available_balance }
          : company,
      ),
    )

    builder.addCase(logout.fulfilled, () => initialState)
    builder.addCase(logout.rejected, () => initialState)

    builder.addCase(terminateCompany.fulfilled, (state, action) =>
      state.map((company) =>
        company.id === action.meta.arg.companyID
          ? { ...company, termination_requested_at: new Date() }
          : company,
      ),
    )

    builder.addCase(offboardCompany.fulfilled, (state, action) =>
      state.map((company) =>
        company.id === action.meta.arg.companyID
          ? { ...company, offboarding_requested_at: new Date() }
          : company,
      ),
    )
  },
})

export const { setCompanies, clearCompanies } = companiesSlice.actions

export default companiesSlice.reducer
