import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import LaunchPlan from 'src/types/LaunchPlan'
import { logout } from './user.slice'

export const getUserLaunchPlan = createAsyncThunk('launchPlan/getUserLaunchPlan', () =>
  api.getUserLaunchPlan(),
)

export const updateUserLaunchPlan = createAsyncThunk(
  'launchPlan/updateUserLaunchPlan',
  (updates: Partial<LaunchPlan>) => api.updateUserLaunchPlan(updates),
)

export const getUserRecommendations = createAsyncThunk('launchPlan/getUserRecommendations', () =>
  api.getUserRecommendations(),
)

export const initialState: LaunchPlan = {
  find_property: {
    status: 'TODO',
    partner: null,
  },
  mortgage: {
    status: 'TODO',
    partner: null,
  },
  has_completed_company: false,
  recommendations: {
    find_property: ['find_property_false'],
    mortgage: ['using_mortgage_false'],
    starter_pack: ['current_btl_knowledge_all-of-it'],
  },
  isLoading: true,
  isCompanyFormationLoading: false,
}

const launchPlanSlice = createSlice({
  name: 'launchPlan',
  initialState,
  reducers: {
    setIsCompanyFormationLoading: (state, action) => ({
      ...state,
      isCompanyFormationLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUserLaunchPlan.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    builder.addCase(updateUserLaunchPlan.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    builder.addCase(getUserRecommendations.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserRecommendations.fulfilled, (state, action) => {
      state.isLoading = false
      if (!Array.isArray(action.payload)) {
        state.recommendations = action.payload
      }
    })
    builder.addCase(logout.fulfilled, () => initialState)
    builder.addCase(logout.rejected, () => initialState)
  },
})

export const { setIsCompanyFormationLoading } = launchPlanSlice.actions

export default launchPlanSlice.reducer
