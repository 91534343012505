import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCompanyOnboardings as fetchCompanyOnboardings } from 'src/api/companyOnboardingAPI'
import { type ShortenedCompanyOnboarding } from 'src/api/companyOnboardingAPI'

interface CompanyOnboardingsState {
  isLoading: boolean
  companyOnboardings: ShortenedCompanyOnboarding[]
}

export const initialState: CompanyOnboardingsState = {
  isLoading: false,
  companyOnboardings: [],
}

export const getCompanyOnboardings = createAsyncThunk(
  'companyOnboarding/fetchCompanyOnboardings',
  fetchCompanyOnboardings,
)

const companyOnboardingsSlice = createSlice({
  name: 'companyOnboardings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyOnboardings.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCompanyOnboardings.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getCompanyOnboardings.fulfilled, (state, action) => ({
      companyOnboardings: action.payload?.company_onboardings,
      isLoading: false,
    }))
  },
})

export default companyOnboardingsSlice.reducer
