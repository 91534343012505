import { Component, ReactNode } from 'react'
import { captureException, captureMessage } from '@sentry/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Alert } from '@material-ui/lab'
import { Box, Paper, Snackbar, Typography } from '@material-ui/core'
import palette from '@gground/capcom.themes'
import { Button } from '@gground/capcom.core'

const isProduction = import.meta.env.MODE === 'production'

interface Props {
  children: ReactNode
}

interface State {
  error: null | Error
  hasError: boolean
  isEmailCopied: boolean
  isLoadingError: boolean
}

function checkLoadingError(message: string) {
  return (
    message.includes('Failed to fetch dynamically imported module') ||
    message.includes('error loading dynamically imported module') ||
    message.includes('Importing a module script failed') ||
    message.includes("'text/html' is not a valid JavaScript MIME type")
  )
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      hasError: false,
      isEmailCopied: false,
      isLoadingError: false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    const isLoadingError = checkLoadingError(error?.message || '')
    // return error states
    return { hasError: true, isLoadingError }
  }

  componentDidCatch(error: Error) {
    // add logs to sentry
    const isLoadingError = checkLoadingError(error?.message || '')
    if (isLoadingError) {
      captureMessage('Error in lazy load. Reloading')
      window.location.reload()
    } else {
      this.setState({ error })
      captureException(error)
    }
  }

  toggleCopyToast = () => {
    this.setState((prev) => ({ isEmailCopied: !prev.isEmailCopied }))
  }

  render() {
    const { error, hasError, isLoadingError, isEmailCopied } = this.state
    const { children } = this.props

    if (hasError) {
      return !isLoadingError ? (
        <Box
          bgcolor={palette.mono.lighter}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          width="100%"
          maxWidth="700px"
          mx="auto"
          paddingX={3}
        >
          <Paper elevation={1}>
            <Box margin={4} color={palette.mono.obsidian}>
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                Oops...
              </Typography>
              <Typography variant="subtitle2">Something went wrong</Typography>
              <Box mt={3} mb={3}>
                <Typography>
                  Please refresh the page. If the problem continues, contact us at&nbsp;
                  <CopyToClipboard text="info@getground.co.uk" onCopy={this.toggleCopyToast}>
                    <Typography
                      display="inline"
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: palette.special.link,
                      }}
                    >
                      info@getground.co.uk
                    </Typography>
                  </CopyToClipboard>
                </Typography>
              </Box>
              <Button id="refresh-btn" onClick={() => window.location.reload()}>
                Refresh Page
              </Button>

              {!isProduction && error ? (
                <Box my={4}>
                  <code style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</code>
                </Box>
              ) : null}
            </Box>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isEmailCopied}
              onClose={this.toggleCopyToast}
              autoHideDuration={1000}
            >
              <Alert severity="success" icon={false}>
                Email copied
              </Alert>
            </Snackbar>
          </Paper>
        </Box>
      ) : null
    }

    return children
  }
}

export default ErrorBoundary
