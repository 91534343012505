import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import ServiceOffered from 'src/types/ServiceOffered'
import PropertyManagement from 'src/types/PropertyManagement'
import { logout } from './user.slice'

interface Services {
  servicesOffered: ServiceOffered[]
  isServicesOfferedLoading: boolean
  propertyManagementRequest: PropertyManagement[]
  isPropertyManagementRequested: boolean
  ppServicesOffered: PPServiceOffered[]
  ppServicesLoading: boolean
}

export const initialState: Services = {
  servicesOffered: [],
  isServicesOfferedLoading: true,
  propertyManagementRequest: [],
  isPropertyManagementRequested: true,
  ppServicesOffered: [],
  ppServicesLoading: true,
}

export const getServicesOffered = createAsyncThunk(
  'services/getServicesOffered',
  api.getServicesOffered,
)

export const submitPropertyManagementRequest = createAsyncThunk(
  'services/submitPropertyManagementRequest',
  ({ properties }: { properties: PropertyManagement[] }) =>
    api.submitPropertyManagementRequest(properties),
)

export const getPropertyManagementRequest = createAsyncThunk(
  'services/getPropertyManagementRequest',
  api.getPropertyManagementRequest,
)

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServicesOffered.fulfilled, (state, action) => {
      state.servicesOffered = action.payload
      state.isServicesOfferedLoading = false
      state.isPropertyManagementRequested = Array.isArray(action.payload)
        ? action.payload.some(({ type }) => type === 'property_management')
        : false
    })
    builder.addCase(getServicesOffered.rejected, (state) => ({
      ...state,
      isServicesOfferedLoading: false,
    }))
    builder.addCase(getPropertyManagementRequest.fulfilled, (state, action) => {
      state.propertyManagementRequest = action.payload || []
    })
    builder.addCase(logout.fulfilled, () => initialState)
    builder.addCase(logout.rejected, () => initialState)
  },
})

export default servicesSlice.reducer
