import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInboxItems, getInboxStatus } from 'src/api/ggAPI'

export const getItems = createAsyncThunk('inbox/getInboxItems', () => getInboxItems())
export const getNotificationCount = createAsyncThunk('inbox/getNotificationCount', () =>
  getInboxStatus(),
)

export const initialState = {
  unseenNotificationCount: 0,
  inboxItems: [],
  isLoading: true,
}

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    resetNotificationCount: (state) => {
      state.unseenNotificationCount = 0
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
  },
  extraReducers: {
    [getItems.fulfilled]: (state, action) => {
      state.inboxItems = [...action.payload.notifications]
      state.isLoading = false
    },
    [getItems.pending]: (state) => {
      state.isLoading = true
    },
    [getNotificationCount.fulfilled]: (state, action) => {
      state.unseenNotificationCount = action.payload.unseen
    },
  },
})

export const { resetNotificationCount, stopLoading } = inboxSlice.actions

export default inboxSlice.reducer
