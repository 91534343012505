import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isOpen: false,
  redirectURL: '',
  loginTitle: '',
  signUpTitle: '',
  snowplowEvents: {
    eventOnSignUp: null,
    eventOnLogin: null,
    eventOnSwitchingToLogin: null,
    eventOnSwitchingToSignUp: null,
  },
}

const authModalSlice = createSlice({
  name: 'authModal',
  initialState,
  reducers: {
    handleOpenAuthModal: (state, action) => ({ ...state, ...action.payload, isOpen: true }),
    onClose: () => ({ ...initialState }),
  },
})

export const { handleOpenAuthModal, onClose } = authModalSlice.actions

export default authModalSlice.reducer
