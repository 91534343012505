import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import { DELETE_USER } from 'src/config/constants'
import { logout } from './user.slice'

export const getDocuments = createAsyncThunk('documents/getDocuments', (_, { getState }) =>
  api.getDocuments(getState().user.id),
)

export const saveSignature = createAsyncThunk(
  'documents/saveSignature',
  ({ signature }: { signature: string }, { getState }) =>
    api.saveSignature(signature, getState().user.id),
)

export const getSignature = createAsyncThunk('documents/getSignature', (_, { getState }) =>
  api.getSignature(getState().user.id),
)

// @todo define state type
const initialState = {
  file_signature: null,
  isLoading: false,
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, action) => ({ ...state, ...action.payload }))
    builder.addCase(saveSignature.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(saveSignature.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(saveSignature.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      file_signature: action.meta.arg.signature,
    }))
    builder.addCase(getSignature.fulfilled, (state, action) => ({
      ...state,
      file_signature: action.payload ? action.payload.contents : '',
    }))
    builder.addCase(DELETE_USER, () => initialState)
    builder.addCase(logout.fulfilled, () => initialState)
    builder.addCase(logout.rejected, () => initialState)
  },
})

// export const { showLoader, hideLoader } = documentsSlice.actions

export default documentsSlice.reducer
