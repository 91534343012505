import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  getInvestmentPot,
  getInvestmentPotBalance,
  addMoney,
  withdrawMoney,
  createPot,
  getInvestmentPotTransactions,
  getInvestmentPotPendingTransactions,
  getCompanyShareholders,
  type GetPotTransactionsRequest,
  type GetPotTransactionsResponse,
  getRegularDeposits,
  makeRegularDeposit,
  updateRegularDeposit,
  cancelRegularDeposit,
  getPotPerformance,
  getPotYield,
} from 'src/api/pot/routes'

const wrapAsyncFunction = <T, Args>(asyncFn: (args: Args) => Promise<T>) => {
  return (args: Args) =>
    asyncFn(args)
      .then((data) => ({ data }))
      .catch((error) => ({ error }))
}

const potApi = createApi({
  reducerPath: 'potApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: [
    'Pot',
    'PotBalance',
    'PotTransactions',
    'PotPendingTransactions',
    'RegularDeposits',
    'PotPerformance',
  ],
  refetchOnMountOrArgChange: 30,
  endpoints: (builder) => ({
    getPotYield: builder.query({
      queryFn: wrapAsyncFunction(getPotYield),
      providesTags: ['Pot'],
    }),
    getPot: builder.query({
      queryFn: wrapAsyncFunction(getInvestmentPot),
      providesTags: ['Pot'],
    }),
    getPotBalance: builder.query({
      queryFn: wrapAsyncFunction(getInvestmentPotBalance),
      providesTags: ['PotBalance'],
    }),
    createPot: builder.mutation({
      queryFn: wrapAsyncFunction(createPot),
      invalidatesTags: ['Pot', 'PotBalance'],
    }),
    addPotMoney: builder.mutation({
      queryFn: wrapAsyncFunction(addMoney),
      invalidatesTags: ['Pot', 'PotBalance', 'PotTransactions', 'PotPendingTransactions'],
    }),
    withdrawPotMoney: builder.mutation({
      queryFn: wrapAsyncFunction(withdrawMoney),
      invalidatesTags: ['Pot', 'PotBalance', 'PotTransactions', 'PotPendingTransactions'],
    }),
    getPotTransactions: builder.query<GetPotTransactionsResponse, GetPotTransactionsRequest>({
      /**
       * - https://stackoverflow.com/a/74844699
       * - https://redux-toolkit.js.org/rtk-query/api/createApi#merge
       */
      queryFn: wrapAsyncFunction(getInvestmentPotTransactions),
      providesTags: ['PotTransactions'],
      serializeQueryArgs: ({ endpointName }) => ({ endpointName }),
      merge: (currentCache, newItems, { arg: { after } }) => {
        if (!after) {
          // initial request
          currentCache.transactions = newItems.transactions
          currentCache.after = newItems.after
        } else {
          // load more
          currentCache.transactions.push(...(newItems.transactions ?? []))
          currentCache.after = newItems.after ?? ''
        }
      },
    }),
    getPotPendingTransactions: builder.query({
      queryFn: wrapAsyncFunction(getInvestmentPotPendingTransactions),
      providesTags: ['PotPendingTransactions'],
    }),
    getPotRegularDeposits: builder.query({
      queryFn: wrapAsyncFunction(getRegularDeposits),
      providesTags: ['RegularDeposits'],
    }),
    makeRegularDeposit: builder.mutation({
      queryFn: wrapAsyncFunction(makeRegularDeposit),
      invalidatesTags: ['Pot', 'PotBalance', 'RegularDeposits'],
    }),
    updateRegularDeposit: builder.mutation({
      queryFn: wrapAsyncFunction(updateRegularDeposit),
      invalidatesTags: ['Pot', 'PotBalance', 'RegularDeposits'],
    }),
    cancelRegularDeposit: builder.mutation({
      queryFn: wrapAsyncFunction(cancelRegularDeposit),
      invalidatesTags: ['Pot', 'PotBalance', 'RegularDeposits'],
    }),
    getCompanyShareholders: builder.query({
      queryFn: wrapAsyncFunction(getCompanyShareholders),
    }),
    getPotPerformance: builder.query({
      queryFn: wrapAsyncFunction(getPotPerformance),
      providesTags: ['PotPerformance'],
    }),
  }),
})

export default potApi
export const {
  useGetPotYieldQuery,
  useGetPotQuery,
  useLazyGetPotQuery,
  useGetPotBalanceQuery,
  useLazyGetPotBalanceQuery,
  useAddPotMoneyMutation,
  useWithdrawPotMoneyMutation,
  useCreatePotMutation,
  useGetPotTransactionsQuery,
  useGetPotPendingTransactionsQuery,
  useGetPotRegularDepositsQuery,
  useMakeRegularDepositMutation,
  useUpdateRegularDepositMutation,
  useCancelRegularDepositMutation,
  useGetCompanyShareholdersQuery,
  useGetPotPerformanceQuery,
} = potApi
