import axios from 'axios'
import { CompanyOnboarding } from 'src/types/CompanyOnboarding'

type CreateCompanyOnboardingResponse = { id: number }

/* CREATE COMPANY ONBOARDING */
export const createCompanyOnboarding = () =>
  axios.post<CreateCompanyOnboardingResponse>('v3/company_onboardings')

/* GET COMPANY ONBOARDING */
export const getCompanyOnboarding = ({ stockCompanyID }: { stockCompanyID: number }) =>
  axios.get<CompanyOnboarding>(`v3/company_onboardings/${stockCompanyID}`)

export type UpdateCompanyOnboarding = {
  stockCompanyID: string
  request: Partial<CompanyOnboarding>
}

/* UPDATE COMPANY ONBOARDING */
export const updateCompanyOnboarding = ({ stockCompanyID, request }: UpdateCompanyOnboarding) =>
  axios.patch<CompanyOnboarding>(`v3/company_onboardings/${stockCompanyID}`, request)

/* In-eligible stock company */
export const updateInEligibleCompany = ({ stockCompanyID }: { stockCompanyID: string }) =>
  axios.delete(`v3/company_onboardings/${stockCompanyID}`)

interface StockCompanyMakeIntent {
  stockCompanyID: number
}

interface StockCompanyMakeIntentResponse {
  id: number
  stripe_client_secret: string
  status: string
}

export const stockCompanyMakeIntent = ({
  stockCompanyID,
}: StockCompanyMakeIntent): Promise<StockCompanyMakeIntentResponse> =>
  axios.post(`v3/subscriptions/intent`, {
    type: 'COMPANY_ONBOARDING',
    company_onboarding_id: stockCompanyID,
  })

export const stockCompanyCheckIntent = (
  intentID: number,
): Promise<Omit<StockCompanyMakeIntentResponse, 'stripe_client_secret'>> =>
  axios.put(`v3/subscriptions/intent/${intentID}`)

interface FileResponse {
  filename: string
  signed_url: string
  object_name: string
  headers?: any
}

export const getSignedURL = (files: File[]) =>
  axios.post<FileResponse[]>('v3/files/signed_url_v4', {
    files: files.map(({ name, type }) => ({ filename: name, mime_type: type })),
  })

export interface ShortenedCompanyOnboarding {
  id: number
  company_name: string | null
  created_at: string
}
interface CompanyOnboardings {
  company_onboardings: ShortenedCompanyOnboarding[]
}

export const getCompanyOnboardings = (): Promise<CompanyOnboardings> =>
  axios.get('v3/company_onboardings')
