import { useLDClient } from 'launchdarkly-react-client-sdk'
import useSessionStorage from '../useSessionStorage'
import { uniqueId } from 'lodash'

function useLaunchDarklyIdentify(): () => void {
  const ldClient = useLDClient()
  const { getItemFromSessionStorage } = useSessionStorage()
  const userID = getItemFromSessionStorage('userID')
  const country = getItemFromSessionStorage('userCountry') || 'unknown'

  return () =>
    ldClient?.identify(
      {
        kind: 'user',
        key: userID ?? uniqueId(),
        country,
      },
      undefined,
    )
}

export default useLaunchDarklyIdentify
