/* eslint-disable no-underscore-dangle */
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import { setUserId } from '@snowplow/browser-tracker'
import { getCountries } from 'src/api/ggAPI'
import routes from 'src/pages/AppWrapper/AppRoutes'
import { getCurrentScope } from '@sentry/react'
import { STANDALONE_SPV, PROCESS_STATUS_KEYS } from 'src/config/constants'
import { COMPANY_STATUS_REQ_FIELDS } from 'src/components-pages/CompanyOnboarding/constants'
import {
  authLoginWithGoogle,
  authSignUpWithGoogle,
  completeOnboarding,
  saveQR,
  userUpdate,
  verifyEmail,
  shouldUserDoNPS,
  trackSnowplow,
  unAuthJwt,
  userLogin,
  getUser,
  updateUser,
  setNewPassword,
} from 'src/state/slices/user.slice'
import {
  getPropertyPurchase,
  updatePP,
  updatePurchaseDetails,
  deletePP,
  confirmPayment,
} from 'src/state/slices/propertyPurchase.slice'
import { deleteCompany } from 'src/state/slices/company.slice'
import { updateAccountancySubmission } from 'src/state/slices/accountancy.slice'
import { getWalletDetails, openInternationalAccount } from 'src/state/slices/wallet.slice'
import { saveSelectedAreas, updateSelectedAreas } from 'src/state/slices/marketInsight.slice'
import {
  submitPropertyManagementRequest,
  getPropertyManagementRequest,
} from 'src/state/slices/services.slice'
import useSessionStorage from 'src/hooks/useSessionStorage'
import { saveSMSDigits } from 'src/utils/_functions'
import { APP_FLOWS } from 'src/components/CrossSellingModal/constants'
import { setAuth } from 'src/utils/auth'
import * as ggUrl from 'src/utils/ggUrl'
import { showInfoToast, showErrorToast, showSuccessToast } from 'src/utils/toasts'
import {
  ONBOARDING_REDIRECT_KEY,
  SKIP_ONBOARDING_NPS,
  LANDLORD_ONBOARDING,
} from 'src/components-pages/onboarding/consts'
import { submitMortgageApplication, mortgageUpdate } from '../slices/mortgages.slice'
import { hideLoader, showLoader } from '../slices/loader.slice'
import { getUserLaunchPlan, getUserRecommendations } from '../slices/launchPlan.slice'
import {
  fetchAllChangeRequestDocuments,
  fetchDocument,
  signDocument,
} from '../slices/documentsToSign.slice'
import {
  getAllListings,
  getFilteredListings,
  getListingsFilters,
  updateListingProgress,
  getAllUserListings,
  saveProductSelectedListings,
} from '../slices/listingProperties.slice'
import { getPropertyListingDetails } from '../slices/listingDetails.slice'
import { requestCall, setLoading } from '../slices/propertyPackages.slice'
import { closeModal, requestCallMarketplace } from '../slices/marketplace.slice'
import {
  getSinglePropertyReport,
  getUserPropertyReports,
  deletePropertyReport,
} from '../slices/propertyReports.slice'
import {
  confirmProductPayment,
  getProductPaymentDetails,
  makeProductPaymentIntent,
  updateProduct,
} from '../slices/products.slice'
import { getInvestments } from '../slices/investments.slice'
import { getInvestment } from '../slices/investment.slice'
import {
  getDevelopmentDetails,
  removeSavedDevelopment,
  saveDevelopment,
} from '../slices/developmentDetails.slice'
import { setRemoveSavedDevelopment, setSavedDevelopment } from '../slices/propertyListing.slice'
import { setUserCountry } from '../slices/countries.slice'
import { createCompanyOnboarding } from '../slices/companyOnboarding.slice'
import { getCompanyOnboardings } from '../slices/companyOnboardings.slice'
import { updateCompanyOnboarding } from '../slices/companyOnboarding.slice'
import { identifyWithThrottle } from '../../utils/functions'
import { WS_FLOW_SIGNUP_IN_PROGRESS } from 'src/config/constants'

const { NO_PROPERTY_YET, PROPERTY_IN_MIND, MADE_AN_OFFER } = PROCESS_STATUS_KEYS

const { COMPANY_DESIGN } = APP_FLOWS

const _middleware = (store) => (next) => async (action) => {
  const { dispatch, getState } = store
  const state = getState()
  const accountancyInfo = {
    submissionID: state.accountancySubmission?.id,
    companyID: state.accountancySubmission?.company_id,
    ppID: state.company?.ppID,
  }
  const { invitedShareholder, is_paying, should_setup_2fa } = state.user
  const { newCompaniesInProgress } = state.todos
  const { process_status } = state.propertyPurchase ?? {}
  const isWebSessionSignUpFlowInProgress = Cookies.get(WS_FLOW_SIGNUP_IN_PROGRESS) === 'true'

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getItemFromSessionStorage, removeFromSessionStorage } = useSessionStorage()

  const callUserDetails = () => {
    dispatch(getUser())
      .unwrap()
      .then(({ country: countryID }) => {
        if (countryID) {
          void getCountries().then(({ countries }) => {
            const matchingCountry = countries?.find((country) => country.id === countryID)
            dispatch(setUserCountry(matchingCountry || null))
            sessionStorage.setItem('userCountry', matchingCountry?.country_name?.toString())
          })
        }
      })

    dispatch(getInvestments())
    dispatch(getCompanyOnboardings())
  }

  const redirectTo = () => {
    dispatch(
      trackSnowplow({
        category: 'auth',
        action: 'login_success',
      }),
    )
    const redirect = getItemFromSessionStorage('redirectTo')
    if (redirect) {
      removeFromSessionStorage('redirectTo')
      routes?.navigate(`/${redirect}`)
    } else {
      routes?.navigate(ggUrl.dashboardUrl())
    }
  }

  switch (action.type) {
    case authLoginWithGoogle.fulfilled.type: {
      if (!action?.payload?.user) {
        routes?.navigate('/onboarding/create-account', { state: { accountNotFound: true } })
        dispatch(hideLoader())
        next(action)
        break
      }
      const {
        token,
        user: {
          two_factor_auth_method,
          phone_last_four_digits,
          first_name,
          id,
          email,
          has_pending_two_factor_auth_reset,
        },
      } = action.payload ?? {}
      let authorised

      if (token) {
        setAuth(token)
        authorised = jwtDecode(token)?.authorised
      }

      dispatch(
        userLogin({
          ...action.payload.user,
          qrToken: action.payload.user.key_uri,
          has_pending_two_factor_auth_reset,
        }),
      )
      setUserId(id)
      sessionStorage.setItem('userID', id.toString())
      if (email) {
        const scope = getCurrentScope()
        scope.setUser({
          id,
          email,
        })
        sessionStorage.setItem('user_email', email)
        identifyWithThrottle(id, { email })
        if (window.heap) {
          window.heap.identify(id.toString())
        }
        if (window.auryc) {
          window.auryc.identify(id.toString())
        }
      }

      if (phone_last_four_digits?.length > 0) {
        saveSMSDigits(phone_last_four_digits)
      }

      if (authorised) {
        callUserDetails()
        redirectTo()
      }

      if (!authorised) {
        if (has_pending_two_factor_auth_reset) {
          if (!first_name) {
            callUserDetails()
            redirectTo()
          } else {
            routes?.navigate('/onboarding/choose-2FA')
          }
        } else if (two_factor_auth_method?.length) {
          routes?.navigate(two_factor_auth_method === 'totp' ? '/qr-verify' : '/sms')
        } else {
          callUserDetails()
          redirectTo()
        }
      }

      dispatch(hideLoader())
      next(action)
      break
    }

    case authLoginWithGoogle.rejected.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case authSignUpWithGoogle.fulfilled.type: {
      const {
        token,
        user: { id, email, key_uri },
      } = action.payload
      setAuth(token)
      dispatch(userLogin({ ...action.payload.user }))
      setUserId(id)
      dispatch(getUser())
      dispatch(getInvestments())
      dispatch(getCompanyOnboardings())
      sessionStorage.setItem('userID', action.payload.user.id.toString())
      if (email) {
        const scope = getCurrentScope()
        scope.setUser({
          id,
          email,
        })
        sessionStorage.setItem('user_email', email)
        identifyWithThrottle(id, { email })
        if (window.heap) {
          window.heap.identify(id.toString())
        }
        if (window.auryc) {
          window.auryc.identify(id.toString())
        }
      }

      if (key_uri) {
        dispatch(saveQR(key_uri))
      }
      redirectTo()
      dispatch(hideLoader())
      next(action)
      break
    }

    case authSignUpWithGoogle.rejected.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case completeOnboarding.fulfilled.type: {
      next(action)
      dispatch(userUpdate({ key: 'has_completed_onboarding', value: true }))
      dispatch(getUser())
      dispatch(getInvestments())
      dispatch(getCompanyOnboardings())

      const skipNPS = getItemFromSessionStorage(SKIP_ONBOARDING_NPS)

      if (!skipNPS && (invitedShareholder || newCompaniesInProgress.length)) {
        dispatch(shouldUserDoNPS({ type: 'onboarding' }))
      } else {
        const redirectAfterOnboarding = getItemFromSessionStorage(ONBOARDING_REDIRECT_KEY)
        routes?.navigate(redirectAfterOnboarding)
        removeFromSessionStorage(ONBOARDING_REDIRECT_KEY)
        removeFromSessionStorage(SKIP_ONBOARDING_NPS)
        removeFromSessionStorage(LANDLORD_ONBOARDING)
      }

      break
    }

    case completeOnboarding.rejected.type:
      next(action)
      routes?.navigate(ggUrl.dashboardUrl())
      break

    case getPropertyPurchase.fulfilled.type:
      next(action)
      dispatch(hideLoader())
      if (action.payload.progress?.overall_status === 'COMPLETE' && !action.meta.arg.ignoreNPS) {
        dispatch(
          shouldUserDoNPS({
            type: COMPANY_DESIGN,
            investmentID: action.meta.arg.propertyID,
            nextFinancingFromCompanyDesign: action.meta.arg.nextFinancingFromCompanyDesign,
            isMortgageServiceEnabled: action.meta.arg.isMortgageServiceEnabled,
          }),
        )
      }
      break

    case verifyEmail.fulfilled.type:
      {
        sessionStorage.setItem('userID', action.payload.user_id?.toString())
        if (action.payload?.user?.email) {
          sessionStorage.setItem('user_email', action.payload.user.email)
        }
        Cookies.remove('referralCode')
        setAuth(action.payload.token)
        next(action)
        dispatch(getUser())
        dispatch(getInvestments())
        dispatch(getCompanyOnboardings())
        dispatch(
          trackSnowplow({
            category: 'email_verified_page',
            action: 'email_verified_page',
          }),
        )

        redirectTo()
      }
      break

    case verifyEmail.rejected.type:
      next(action)
      showInfoToast('Please re-enter your password to proceed.')
      routes?.navigate(ggUrl.loginUrl())
      break

    case updatePP.fulfilled.type:
      // case updateCompany.fulfilled.type:
      next(action)
      dispatch(hideLoader())
      if (action.meta.arg.path) {
        routes?.navigate(action.meta.arg.path, {
          state: action.meta.arg.state || { companyType: 'SPV' },
        })
      }
      break

    // case updateCompany.rejected.type:
    case updatePP.rejected.type:
    case confirmPayment.rejected.type:
      next(action)
      dispatch(hideLoader())
      break

    case confirmPayment.fulfilled.type:
      next(action)
      dispatch(hideLoader())
      if (action.meta.arg.path) {
        routes?.navigate(action.meta.arg.path)
      }
      break

    case saveQR.type:
      sessionStorage.setItem('GG_KEY_URI', action.payload)
      next(action)
      break

    case updateAccountancySubmission.fulfilled.type:
      next(action)
      if (action.meta.arg.path) {
        routes?.navigate(action.meta.arg.path, {
          state: accountancyInfo,
        })
      }
      break
    case updatePurchaseDetails.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }
    case openInternationalAccount.fulfilled.type: {
      dispatch(
        getWalletDetails({
          companyID: action.meta.arg.companyID,
        }),
      )
      next(action)
      break
    }

    case submitMortgageApplication.fulfilled.type: {
      next(action)
      routes?.navigate('/mortgage-questionnaire/success')
      break
    }

    case unAuthJwt.fulfilled.type: {
      const {
        token,
        phone_last_four_digits,
        key_uri,
        two_factor_auth_method,
        user_id,
        has_pending_two_factor_auth_reset,
      } = action.payload
      const { email } = action.meta.arg
      const twoFAMethod = two_factor_auth_method
      let authorised

      dispatch(
        userLogin({ ...action.payload, id: user_id, email, has_pending_two_factor_auth_reset }),
      )
      setUserId(user_id)
      const scope = getCurrentScope()
      scope.setUser({
        id: user_id,
        email,
      })
      identifyWithThrottle(user_id, { email })
      if (window.heap) {
        window.heap.identify(user_id.toString())
      }
      if (window.auryc) {
        window.auryc.identify(user_id.toString())
      }

      sessionStorage.setItem('userID', user_id.toString())
      if (email) {
        sessionStorage.setItem('user_email', email)
      }

      if (token) {
        setAuth(token)
        authorised = jwtDecode(token)?.authorised
      }

      if (phone_last_four_digits) {
        saveSMSDigits(phone_last_four_digits)
      }
      if (key_uri) {
        dispatch(saveQR(key_uri))
      }

      if (authorised) {
        callUserDetails()
        redirectTo()
      }

      if (!authorised) {
        if (has_pending_two_factor_auth_reset) {
          if (!action.payload.first_name) {
            callUserDetails()
            redirectTo()
          } else {
            routes?.navigate('/onboarding/choose-2FA')
          }
        } else if (twoFAMethod?.length) {
          routes?.navigate(twoFAMethod === 'totp' ? '/qr-verify' : '/sms')
        } else {
          callUserDetails()
          redirectTo()
        }
      }

      dispatch(hideLoader())
      next(action)
      break
    }

    case unAuthJwt.rejected.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case mortgageUpdate.type: {
      const mortgageData = JSON.parse(sessionStorage.getItem('mortgageData'))
      const newData = { ...mortgageData, ...action.payload }
      sessionStorage.setItem('mortgageData', JSON.stringify(newData))
      next(action)
      break
    }

    case deletePP.type: {
      sessionStorage.removeItem('propertyID')
      next(action)
      break
    }

    case deleteCompany.type: {
      sessionStorage.removeItem('companyID')
      next(action)
      break
    }
    case fetchDocument.rejected.type: {
      showErrorToast('Failed to get document, please try again')
      next(action)
      break
    }
    case fetchAllChangeRequestDocuments.rejected.type: {
      showErrorToast('Failed to collect documents, please try again')
      next(action)
      break
    }

    case signDocument.rejected.type: {
      showErrorToast('Could not sign document. Please reload page and try again.')
      next(action)
      break
    }

    case shouldUserDoNPS.fulfilled.type: {
      const { should_request } = action.payload
      const {
        arg: { type, investmentID },
      } = action.meta
      if (should_request) {
        switch (type) {
          case 'onboarding': {
            if (isWebSessionSignUpFlowInProgress) {
              routes?.navigate('/portfolio/companies')
              Cookies.set(WS_FLOW_SIGNUP_IN_PROGRESS, false)
              return
            }

            if (is_paying) {
              routes?.navigate('/onboarding/feedback')
            } else {
              // redirect non-paying users directly to the company review page
              const companyTodo = newCompaniesInProgress.find(
                (todo) => todo?.type === 'CONTINUE_CREATING_COMPANY',
              )

              if (companyTodo?.property_purchase_id && !isWebSessionSignUpFlowInProgress) {
                sessionStorage.setItem('propertyID', companyTodo?.property_purchase_id?.toString())
                routes?.navigate(
                  companyTodo.company_type === STANDALONE_SPV
                    ? '/company-design/review'
                    : '/holdingco/review',
                )
              } else {
                routes?.navigate(ggUrl.dashboardUrl())
              }
            }
            break
          }
          case 'property_purchase_document_signing':
            routes?.navigate('/documents/feedback')
            break

          default:
            routes?.navigate(ggUrl.dashboardUrl())
            break
        }
      } else {
        switch (type) {
          case 'property_purchase_document_signing':
            routes?.navigate(`/investments/${investmentID}`)
            break

          default:
            routes?.navigate(ggUrl.todayUrl())
        }
      }

      next(action)
      break
    }

    case saveSelectedAreas.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case saveSelectedAreas.fulfilled.type:
    case updateSelectedAreas.fulfilled.type: {
      dispatch(hideLoader())
      dispatch(
        trackSnowplow({
          category: 'get_in_touch',
          action: 'complete',
        }),
      )
      routes?.navigate(`/manage-services/${action.meta.arg.propertyID}`)
      next(action)
      break
    }

    case saveSelectedAreas.rejected.type:
    case updateSelectedAreas.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to save selected areas, please try again')
      next(action)
      break
    }

    case getUserLaunchPlan.fulfilled.type: {
      dispatch(getUserRecommendations())
      next(action)
      break
    }

    case submitPropertyManagementRequest.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case submitPropertyManagementRequest.fulfilled.type: {
      dispatch(hideLoader())
      await routes?.navigate(ggUrl.dashboardUrl())
      showSuccessToast(
        'A request for a Property Management service was successfully submitted. We’ll be in touch shortly!',
      )
      next(action)
      break
    }

    case submitPropertyManagementRequest.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to submit request, please try again')
      next(action)
      break
    }

    case getPropertyManagementRequest.rejected.type: {
      showErrorToast('Failed to get Property Management request, please try again')
      next(action)
      break
    }

    case getAllListings.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case getAllListings.rejected.type: {
      dispatch(hideLoader())
      if (action.payload?.code !== 403) {
        showErrorToast('Failed to get properties, please try again')
      }
      next(action)
      break
    }

    case getAllListings.fulfilled.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case getListingsFilters.rejected.type: {
      if (action.payload?.code !== 403) {
        showErrorToast('Failed to get filters, please try again')
      }
      next(action)
      break
    }

    case getFilteredListings.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case getFilteredListings.rejected.type: {
      dispatch(hideLoader())
      if (action.payload?.code !== 403) {
        showErrorToast('Failed to get filtered properties, please try again')
      }
      next(action)
      break
    }

    case getFilteredListings.fulfilled.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case requestCall.pending.type: {
      dispatch(setLoading(true))
      next(action)
      break
    }
    case requestCall.fulfilled.type: {
      dispatch(setLoading(false))
      dispatch(updateListingProgress('CALL_REQUESTED'))
      if (action.meta.arg.payload?.listingID) {
        dispatch(getPropertyListingDetails({ listingID: action.meta.arg.payload.listingID }))
      }
      if (action.meta.arg.payload?.developmentID) {
        dispatch(getDevelopmentDetails({ developmentID: action.meta.arg.payload.developmentID }))
      }
      next(action)
      break
    }

    case requestCall.rejected.type: {
      dispatch(setLoading(false))
      next(action)
      break
    }

    case requestCallMarketplace.fulfilled.type: {
      dispatch(closeModal())
      next(action)
      break
    }

    case getUserPropertyReports.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case getUserPropertyReports.fulfilled.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case getUserPropertyReports.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to load the property reports, please try again')
      next(action)
      break
    }

    case getSinglePropertyReport.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case getSinglePropertyReport.rejected.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case getSinglePropertyReport.fulfilled.type: {
      dispatch(hideLoader())
      const { meta, payload } = action

      routes?.navigate(
        `?length=10&ltv=${(payload?.starting_ltv_percentage_in_bps || 0) / 100}&interest_rate=${
          (payload?.starting_interest_rate_percentage_in_bps || 0) / 100
        }`,
        { replace: true, state: { ...meta.arg.state } },
      )
      next(action)
      break
    }

    case deletePropertyReport.fulfilled.type: {
      showSuccessToast('Property report deleted.')
      next(action)
      break
    }

    case deletePropertyReport.rejected.type: {
      showErrorToast('Failed to delete property report. Please try again.')
      next(action)
      break
    }

    case updateUser.fulfilled.type: {
      const { meta } = action

      if (meta.arg.callback) {
        meta.arg.callback()
      }
      next(action)
      break
    }

    case getProductPaymentDetails.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case getProductPaymentDetails.fulfilled.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case getProductPaymentDetails.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to load product payment details, please try again')
      next(action)
      break
    }

    case makeProductPaymentIntent.rejected.type: {
      showErrorToast('Failed to create payment intent, please refresh')
      next(action)
      break
    }

    case confirmProductPayment.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed payment, please try again')
      next(action)
      break
    }

    case confirmProductPayment.fulfilled.type: {
      const { meta } = action
      dispatch(hideLoader())
      dispatch(getUser())
      routes?.navigate(`/products/search/${meta.arg.productID}`)
      next(action)
      break
    }

    case updateProduct.fulfilled.type: {
      dispatch(getUser())
      next(action)
      break
    }

    case getAllUserListings.fulfilled.type: {
      dispatch(hideLoader())
      next(action)
      break
    }

    case getAllUserListings.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to load listing options, please try again')
      next(action)
      break
    }

    case saveProductSelectedListings.pending.type: {
      dispatch(showLoader())
      next(action)
      break
    }

    case saveProductSelectedListings.fulfilled.type: {
      const { productID, listings } = action.meta.arg
      const propertiesMessage = listings.length > 1 ? `${listings.length} properties` : '1 property'
      dispatch(hideLoader())
      routes?.navigate(`/products/search/${productID}`)
      showSuccessToast(`${propertiesMessage} added to My Investments`)
      next(action)
      break
    }

    case saveProductSelectedListings.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Failed to save selected listings, please try again')
      next(action)
      break
    }

    case saveDevelopment.fulfilled.type: {
      const { developmentID } = action.meta.arg
      dispatch(setSavedDevelopment(developmentID))
      next(action)
      break
    }
    case removeSavedDevelopment.fulfilled.type: {
      const { developmentID } = action.meta.arg
      dispatch(setRemoveSavedDevelopment(developmentID))
      next(action)
      break
    }
    case createCompanyOnboarding.fulfilled.type: {
      dispatch(getCompanyOnboardings())
      next(action)
      break
    }
    // fetch latest investments and comp. onboardings on the last step completion
    case updateCompanyOnboarding.fulfilled.type: {
      const onboardingObj = action.payload || {}

      const areAllStepsCompleted = COMPANY_STATUS_REQ_FIELDS.every((field) => {
        const step = onboardingObj[field]
        return step && step.status === 'COMPLETED'
      })

      if (areAllStepsCompleted) {
        dispatch(getCompanyOnboardings())
        dispatch(getInvestments())
      }
      next(action)
      break
    }
    case setNewPassword.fulfilled.type: {
      const { email, token, user_id, two_factor_auth_method, phone_last_four_digits, key_uri } =
        action.payload
      const urlParams = new URLSearchParams(window.location.search)
      const redirectParam = urlParams.get('redirect')

      dispatch(hideLoader())
      // redirect into app if redirect param is present
      if (redirectParam) {
        setAuth(token)
        sessionStorage.setItem('userID', user_id?.toString())
        if (email) {
          sessionStorage.setItem('user_email', email)
        }

        identifyWithThrottle(user_id, { email })
        if (window.heap) {
          window.heap.identify(user_id.toString())
        }
        if (window.auryc) {
          window.auryc.identify(user_id.toString())
        }

        if (phone_last_four_digits) {
          saveSMSDigits(phone_last_four_digits)
        }
        if (key_uri) {
          dispatch(saveQR(key_uri))
        }

        if (two_factor_auth_method?.length) {
          sessionStorage.setItem('redirectTo', redirectParam)
          routes?.navigate(two_factor_auth_method === 'totp' ? '/qr-verify' : '/sms')
        } else {
          callUserDetails()

          routes?.navigate(`/${redirectParam}`)
        }
        // if no redirect in params - redirect to login
      } else if (should_setup_2fa) {
        routes?.navigate('/onboarding/choose-2FA')
      } else {
        routes?.navigate(ggUrl.loginUrl())
      }
      showSuccessToast('Password successfully set')
      next(action)
      break
    }
    case setNewPassword.rejected.type: {
      dispatch(hideLoader())
      showErrorToast('Sorry we cannot set your new password right now, please try again later')
      next(action)
      break
    }

    default:
      next(action)
  }
}

export default _middleware
