import get from 'lodash/get'
import remove from 'lodash/remove'
import { base64StringToBlob } from 'blob-util'
import dayjs from 'dayjs'
import Big from 'big.js'

import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

/**
 * inArray
 * checks if item exists in array
 * @param {string} item - item to search for
 * @param {array} array - array to search in
 * @return {boolean} whether item exists
 */
export const inArray = (item, array) => {
  if (array === undefined) return false
  return array.indexOf(item) >= 0
}

/**
 * objectKeyExists
 * checks if key exists on object
 * @param {string} key - key to search for
 * @param {Object} object - object to search in
 * @return {boolean} whether key exists
 */
export const objectKeyExists = (key, object) => !!object[key]

/**
 * getByValue
 * returns object from array by specified key value
 * @param {array} arr - array to search
 * @param {string} key - key to search for
 * @param {any} value - key value to match
 * @return {Object|null} matched object, null if key/value pair not found
 */
export const getByValue = (arr, key, value) => {
  const filteredArray = arr.filter((obj) => obj[key] === value)

  return filteredArray.length ? filteredArray[0] : null
}

/**
 * penniesToPounds
 * returns string with amount value converted into 2 decimal places
 * @param {string} value - string representing cash amount
 * @return {string} eg. 123456 => 1234.56
 */
export const penniesToPounds = (value) => {
  let amountString = value.toString()
  if (value.length < 4) {
    const leadingZeros = '0000'
    amountString = `${leadingZeros}${value}`.slice(-4)
  }

  if (amountString.startsWith(0)) amountString = amountString.slice(1)
  const pennies = amountString.slice(-2)
  const pounds = amountString.slice(0, -2) || 0
  return `${pounds}.${pennies}`
}

export const setCountries = (key, countryData) => {
  const heroCountries = ['GBR', 'SGP', 'HKG']

  const createOption = (country, key, index) => (
    <option key={`country-${index}`} value={Number(country.id)}>
      {country[key]}
    </option>
  )

  const countries = countryData
    .sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0))
    .map((country, index) => createOption(country, key, index))

  const popular = heroCountries.map((code) =>
    createOption(getByValue(countryData, 'alpha_3_code', code), key, code),
  )

  return [
    <option value="" key="empty" />,
    <optgroup label="Popular" key="group-popular">
      {popular}
    </optgroup>,
    <optgroup label="Countries" key="group-countries">
      {countries}
    </optgroup>,
  ]
}

export const formatCurrencyWithComma = (value) =>
  Number.parseFloat(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatCurrencyWithCommaNoDP = (value) =>
  Number.parseFloat(value).toLocaleString('en-US')

/** @deprecated user convertCurrency instead */
export const formatCurrencyToTwoDP = (value) => {
  return formatCurrencyWithComma(
    Big(value || 0)
      .div(100)
      .toFixed(2),
  )
}

export const formatCurrency = (amount, inCents, thousands = ',') => {
  if (!amount) {
    return null
  }

  let amountToFormat = amount.toString()
  if (inCents) {
    amountToFormat = amountToFormat.substring(0, amountToFormat.length - 2)
  }
  const i = parseInt(
    (amountToFormat = Math.abs(Number(stripCurrency(amountToFormat)) || 0)),
  ).toString()
  const j = i.length > 3 ? i.length % 3 : 0

  return (
    (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
  )
}

export const stripCurrency = (amount) => amount.replace(/[,.]/g, '')

export const stripAmountToPennies = (amount) => Big(amount).mul(100).toString()

/**
 * getMobileOperatingSystem
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @return {String} identified device type
 */
export const getMobileOperatingSystem = () => {
  if (typeof window !== 'undefined' && navigator) {
    const userAgent = navigator.userAgent || navigator.vendor

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }
  }

  return 'unknown'
}

export const saveSMSDigits = (digits) => {
  sessionStorage.setItem('SMSlast4Digits', digits)
}

export const addDataURLMediatype = (type, string) => {
  const isDataURL = (imgString) => !!imgString.match(isDataURL.regex)
  isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,.*$/ // eslint-disable-line no-useless-escape

  const isPDFDocument = type === 'pdf'
  let imageString = string

  if (!isDataURL(imageString)) {
    if (isPDFDocument) {
      imageString = `data:application/pdf;base64, ${imageString}`
    } else {
      imageString = `data:image/${type};base64, ${imageString}`
    }
  }

  return imageString
}

export const isPaymentDisabled = (company) => {
  const progress = get(company, ['progress'], {})
  const shareholderDetailsIsComplete = get(company, ['shareholder_details', 'is_complete'], false)

  return !(
    progress.property_address_status === 'COMPLETE' &&
    progress.purchase_details_status === 'COMPLETE' &&
    shareholderDetailsIsComplete &&
    progress.tax_questions_status === 'COMPLETE' &&
    progress.solicitor_details_status === 'COMPLETE' &&
    progress.property_agent_status === 'COMPLETE'
  )
}

export const downloadFile = (title, contents, type, fileName = '') => {
  if (contents) {
    const newBlob = base64StringToBlob(contents)
    const url = URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName || `${title}.${type || 'pdf'}`)
    link.click()
  }
}

export const mapForSelect = (array) => {
  const arrayForSelect = []
  array.map((item) => {
    arrayForSelect.push({
      label: item,
      value: item,
    })
  })
  return arrayForSelect
}

export const createArrayOfStringsFromSelect = (roles) => {
  const roleStrings = []
  roles.map((role) => {
    roleStrings.push(role.value)
  })
  return roleStrings
}

export const hideForCountry = (country) =>
  country.includes('Hong Kong') ||
  country.includes('Singapore') ||
  country.includes('Vatican') ||
  country.includes('Monaco') ||
  country.includes('Macao')

export const formatDate = (value) => (value ? dayjs(value).format('DD/MM/YYYY') : '')

export const getMonthDateRange = (year, month) => {
  const date = dayjs(`${year}-${month}`, 'YYYY-M')
  const startDate = date.startOf('month')
  const endDate = date.endOf('month')
  return { start: startDate, end: endDate }
}

export const createMonthOptionsSinceDate = (creationDate, startValue) => {
  const endDateOfStartMonth = dayjs(startValue).endOf('month')
  const subtraction = dayjs(dayjs().startOf('month')).diff(
    dayjs(creationDate).startOf('month'),
    'month',
  )
  const extractYrAndMth = creationDate.substr(0, 7)
  const extractCreationDate = creationDate.substr(0, 10)
  const [startYear, startMonth] = extractYrAndMth.split('-')
  const creationDateOption = {
    name: dayjs(creationDate).format('MMMM YYYY'),
    value: `${extractCreationDate}T00:00:00Z`,
  }

  const formattedCreationDate = dayjs(creationDate).startOf('month').format('YYYY-MMMM')
  const formattedStartValue = dayjs(startValue).startOf('month').format('YYYY-MMMM')
  const allOption = { name: 'All statements', value: 0 }
  const onlyOption = { name: 'Only', value: endDateOfStartMonth.format() }
  const newStartArr = [creationDateOption]
  const newEndArr = []

  if (formattedCreationDate !== formattedStartValue) {
    newEndArr.push(creationDateOption)
  }

  let year = startYear
  let month = startMonth

  if (subtraction === 0) {
    return { startOptions: [...newStartArr, allOption], endOptions: [...newEndArr, onlyOption] }
  }
  for (let index = 0; index < subtraction; index++) {
    if (Number(month) + 1 <= 12) {
      const nextMonth = Number(month) + 1
      month = nextMonth
    } else {
      year = Number(year) + 1
      month = 1
    }
    const { start, end } = getMonthDateRange(year, month)
    newStartArr.push({ name: start.format('MMMM YYYY'), value: start.format() })

    if (dayjs(end) < dayjs(endDateOfStartMonth)) {
      newEndArr.push({ name: end.format('MMMM YYYY'), value: end.format() })
    }
  }

  newStartArr.push(allOption)
  newEndArr.push(onlyOption)
  newStartArr.reverse()
  newEndArr.reverse()

  return { startOptions: newStartArr, endOptions: newEndArr }
}

export const formatSortCodeToArray = (value) => {
  if (!value.length) return
  let code = value.split('')
  if (value.includes('-')) {
    code = remove(code, (item) => item !== '-')
  }

  const codeIntoArray = []
  code.map((item, idx) => {
    if (idx === 0 || idx === 2 || idx === 4) {
      codeIntoArray.push(`${item}${code[idx + 1]}`)
    }
  })
  return codeIntoArray
}

export const formatBasisPointsToPercentage = (value) => {
  return value * 0.0001 * 100
}

const functions = {
  inArray,
  objectKeyExists,
  getByValue,
  penniesToPounds,
  setCountries,
  formatCurrency,
  formatCurrencyToTwoDP,
  stripCurrency,
  getMobileOperatingSystem,
  saveSMSDigits,
  addDataURLMediatype,
  isPaymentDisabled,
  downloadFile,
  mapForSelect,
  createArrayOfStringsFromSelect,
  hideForCountry,
  formatDate,
  formatSortCodeToArray,
  formatBasisPointsToPercentage,
}

export default functions
