import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import flatMapDeep from 'lodash/flatMapDeep'

import * as api from 'src/api/ggAPI'
import AccountancySubmission from 'src/types/AccountancySubmission'
import { TransactionCategory } from 'src/types/Transactions'
import { deleteCompanyDocuments } from './companyDocuments.slice'

export const getAccountancySubmission = createAsyncThunk(
  'accountancy/getAccountancySubmission',
  ({ submissionID, companyID }: { submissionID: number; companyID: number }) =>
    api.getAccountancySubmission(submissionID, companyID),
)

export const startAccountancySubmission = createAsyncThunk(
  'accountancy/startAccountancySubmission',
  async (_, { getState, rejectWithValue }) => {
    const state = getState()
    try {
      const response = await api.updateAccountSubmission(
        state.accountancySubmission.company_id,
        state.accountancySubmission.id,
        { user_id: state.user.id },
      )
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  },
)

export const updateAccountancySubmission = createAsyncThunk(
  'accountancy/updateAccountancySubmission',
  ({ data }: { path: string; data: Partial<AccountancySubmissionState> }, { getState }) => {
    const state = getState()
    return api.updateAccountSubmission(
      state.accountancySubmission.company_id,
      state.accountancySubmission.id,
      data,
    )
  },
)

interface UpdateLastPageVisitedProps {
  path: string
  companyID: number
  submissionID: number
}

export const updateLastPageVisited = createAsyncThunk(
  'accountancy/updateLastPageVisited',
  ({ path, companyID, submissionID }: UpdateLastPageVisitedProps, { rejectWithValue }) => {
    const accountancyPage = path.split('accountancy')[1]
    if (companyID && submissionID) {
      return api.updateAccountSubmission(companyID, submissionID, {
        last_page_visited: `accountancy${accountancyPage || ''}`,
      })
    }
    return rejectWithValue(null)
  },
)

export const getAccountingDocuments = createAsyncThunk(
  'accountancy/getAccountingDocuments',
  (_, { getState }) => {
    const state = getState()
    return api.getAccountingDocuments(
      state.accountancySubmission.company_id,
      state.accountancySubmission.id,
    )
  },
)

export const getAccountancyExpenses = createAsyncThunk(
  'accountancy/getAccountancyExpenses',
  ({ companyID, submissionID }: { companyID: number; submissionID: number }) =>
    api.getAccountancyExpenses(companyID, submissionID),
)

export const deleteExpenses = createAsyncThunk(
  'accountancy/deleteExpense',
  ({
    companyID,
    submissionID,
    ids,
  }: {
    companyID: number
    submissionID: number
    ids: number[]
  }) => {
    return api.deleteExpenses(companyID, submissionID, ids)
  },
)

export const unlinkCompanyDocuments = createAsyncThunk(
  'companyDocuments/unlinkCompanyDocuments',
  ({ companyID, ids }: { companyID: number; ids: string[] }, { getState }) => {
    const state = getState()
    const accountSubmissionID = state.accountancySubmission.id
    return api.unlinkCompanyDocuments(companyID, accountSubmissionID, ids)
  },
)

export const getExpensesCategories = createAsyncThunk(
  'accountancy/getExpensesCategories',
  api.getExpensesCategories,
)

export const getCashflowsTransactions = createAsyncThunk(
  'accountancy/getCashflowsTransactions',
  ({ companyID, startDate, endDate }: { companyID: number; startDate: string; endDate: string }) =>
    api.getCashflowsTransactions(companyID, startDate, endDate),
)

export const getExternalTransactions = createAsyncThunk(
  'accountancy/getExternalTransactions',
  ({ companyID, submissionID }: { companyID: number; submissionID: number }) =>
    api.getExternalTransactions(companyID, submissionID),
)

export const createExternalTransactions = createAsyncThunk(
  'accountancy/createExternalTransactions',
  ({
    companyID,
    submissionID,
    data,
  }: {
    companyID: number
    submissionID: number
    data: { transactions: Omit<api.ExternalTransaction, 'category' | 'subcategory' | 'id'>[] }
  }) => api.createExternalTransactions(companyID, submissionID, data),
)

export const deleteExternalTransactions = createAsyncThunk(
  'accountancy/deleteExternalTransactions',
  ({
    companyID,
    submissionID,
    provider,
    document_name,
  }: {
    companyID: number
    submissionID: number
    provider: string
    document_name: string
  }) => api.deleteExternalTransactions(companyID, submissionID, provider, document_name),
)

export const updateExternalTransactions = createAsyncThunk(
  'accountancy/updateExternalTransactions',
  ({
    companyID,
    submissionID,
    data,
  }: {
    companyID: number
    submissionID: number
    data: { transactions: Pick<api.ExternalTransaction, 'category' | 'subcategory' | 'id'>[] }
  }) => api.updateExternalTransactions(companyID, submissionID, data),
)

export const getCashflowsCategories = createAsyncThunk(
  'accountancy/getCashflowsCategories',
  api.getCashflowsCategories,
)

export const updateTransactionCategory = createAsyncThunk(
  'accountancy/updateTransactionCategory',
  (
    { data }: { data: Pick<TransactionCategory, 'id' | 'category' | 'subcategory'>[] },
    { getState },
  ) => {
    const state = getState()
    return api.updateTransactionCategory(state.accountancySubmission.company_id, data)
  },
)

export const getSignableAccountsDocuments = createAsyncThunk(
  'accountancy/getSignableAccountsDocuments',
  ({ companyID, submissionID }: { companyID: number; submissionID: number }) =>
    api.fetchSignableAccountsDocs(companyID, submissionID),
)

export const getOtherAccountingDocuments = createAsyncThunk(
  'accountancy/getOtherAccountingDocuments',
  ({ companyID, submissionID }: { companyID: number; submissionID: number }) =>
    api.getOtherAccountingDocuments(companyID, submissionID),
)

interface FieldsErrors {
  property_valuation_in_cents?: boolean
  property_valuation_source?: boolean
  tenancy_type?: boolean
}

type Provider = {
  document_name: string
  name: string
  transactions: Omit<api.ExternalTransaction, 'provider' | 'document_name'>[]
}

interface AccountancySubmissionState extends AccountancySubmission {
  isLoading: boolean
  error: string | null
  fieldsErrors: FieldsErrors | null
  isAlreadyStarted: boolean
  directorName: string | null
  questionsForm: object | null
  documents: {
    isLoading: boolean
    collection: any[]
  }
  signableDocuments: {
    isLoading: boolean
    collection: any[]
  }
  otherAccountingDocuments: {
    isLoading: boolean
    documents: any[]
  }
  external: {
    isLoading: boolean
    providers: Provider[]
  }
}

export const pendingState: AccountancySubmissionState = {
  id: null,
  company_id: null,
  user_id: null,
  accounting_period_start_date: null,
  accounting_period_end_date: null,
  deadline: null,
  submitted_at: null,
  has_exchanged_contracts: null,
  has_confirmed_completion_date: true,
  contract_exchange_date: null,
  has_paid_reservation_fee: null,
  has_paid_deposit: null,
  reservation_fee_date: null,
  reservation_fee_amount_in_cents: null,
  deposit_date: null,
  deposit_amount_in_cents: null,
  is_payments_inline_shareholding: null,
  payments_inline_shareholding_details: null,
  has_made_direct_payments: null,
  has_started_year_with_a_mortgage: null,
  has_taken_a_new_mortgage_or_refinanced: null,
  has_finished_paying_a_mortgage: null,
  has_reviewed_and_signed_documents: false,
  property_valuation_source: null,
  property_valuation_in_cents: null,
  has_property_tenanted: null,
  property_tenancy_dates: null,
  has_paid_council_tax_from_gg_account: null,
  uses_letting_agent: null,
  tenancy_type: null,
  isLoading: true,
  error: null,
  fieldsErrors: null,
  isAlreadyStarted: false,
  directorName: null,
  questionsForm: null,
  customer_notes: null,
  documents: {
    isLoading: false,
    collection: [],
  },
  expenses: {
    isLoading: true,
    categories: [],
    collection: [],
  },
  cashflows: {
    isLoading: true,
    categories: {
      expenses: [],
      cashflows: [],
    },
    collection: [],
    form: [],
  },
  signableDocuments: {
    isLoading: false,
    collection: [],
  },
  otherAccountingDocuments: {
    isLoading: false,
    documents: [],
  },
  last_page_visited: null,
  is_first_submission: false,
  previous_submission_details: {
    property_valuation_in_cents: null,
    property_valuation_source: null,
    has_previously_confirmed_exchanged_date: null,
    has_previously_confirmed_completion_date: null,
  },
  external: {
    isLoading: true,
    providers: [],
  },
}

const accountancySlice = createSlice({
  name: 'accountancySubmission',
  initialState: pendingState,
  reducers: {
    dismissModal: (state) => ({ ...state, isAlreadyStarted: false }),
    addUploadedDocuments: (state, action) => {
      return {
        ...state,
        documents: {
          ...state.documents,
          collection: [...state.documents.collection, ...action.payload.documents],
        },
      }
    },
    addAccountancyExpenses: (state, action) => {
      const newDocuments = action.payload
      state.expenses.collection = [...newDocuments, ...state.expenses.collection]
    },
    saveCashflowForm: (state, action) => {
      state.cashflows.form = action.payload
    },
    saveQuestionsForm: (state, action) => ({
      ...state,
      questionsForm: { ...state.questionsForm, ...action.payload },
    }),
    saveAccountancyNotes: (state, action) => ({
      ...state,
      customer_notes: action.payload.notes,
    }),
    resetAccountancy: () => pendingState,
    setFieldsErrors: (state, action) => ({ ...state, fieldsErrors: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountancySubmission.pending, () => pendingState)

      .addCase(getAccountancySubmission.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
        isLoading: false,
      }))

      .addCase(getAccountancySubmission.rejected, (state, action) => {
        const error = action.payload?.data.error || 'Failed to load submission information.'
        return { ...state, isLoading: false, error }
      })

      .addCase(startAccountancySubmission.rejected, (state, action) => {
        return action.payload.status === 409
          ? {
              ...state,
              isAlreadyStarted: true,
              directorName: action.payload.data.owner_name,
            }
          : state
      })

      .addCase(updateAccountancySubmission.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      })

      .addCase(updateLastPageVisited.fulfilled, (state, action) => {
        state.last_page_visited = action.payload.last_page_visited
      })

      .addCase(getAccountingDocuments.fulfilled, (state, action) => {
        return {
          ...state,
          documents: {
            collection: action.payload.map((doc) => ({
              id: doc.id,
              category: doc.category,
              filename: doc.filename,
            })),
            isLoading: false,
          },
        }
      })

      .addCase(getAccountingDocuments.pending, (state) => {
        state.documents.isLoading = true
      })

      .addCase(getAccountingDocuments.rejected, (state) => {
        state.documents.isLoading = false
      })

      .addCase(deleteCompanyDocuments.fulfilled, (state, action) => {
        return {
          ...state,
          documents: {
            ...state.documents,
            collection: state.documents.collection.filter(
              (doc) => !action.meta.arg.ids.includes(doc.id),
            ),
          },
        }
      })

      .addCase(getAccountancyExpenses.fulfilled, (state, action) => {
        return {
          ...state,
          expenses: {
            ...state.expenses,
            isLoading: false,
            collection: action.payload,
          },
        }
      })

      .addCase(deleteExpenses.fulfilled, (state, action) => {
        const deletedExpense = action.meta.arg
        state.expenses.collection = state.expenses.collection.filter(
          ({ id }) => !deletedExpense.ids.includes(id),
        )
      })

      .addCase(unlinkCompanyDocuments.fulfilled, (state, action) => {
        const unlinkedDocuments = action.meta.arg
        state.documents.collection = state.documents.collection.filter(
          ({ id }) => !unlinkedDocuments.ids.includes(id),
        )
      })

      .addCase(getExpensesCategories.fulfilled, (state, action) => ({
        ...state,
        expenses: {
          ...state.expenses,
          isLoading: false,
          categories: action.payload,
        },
      }))

      .addCase(getCashflowsTransactions.fulfilled, (state, action) => {
        const monthlyTxs = flatMapDeep(action.payload.monthly_transactions)

        // can be cleaned up when BE tackles the tech debt
        const flatArray = []
        monthlyTxs.map((months) =>
          flatMapDeep(months).map((days) =>
            flatMapDeep(days).map((transaction) => flatArray.push(transaction)),
          ),
        )

        return {
          ...state,
          cashflows: {
            ...state.cashflows,
            isLoading: false,
            collection: flatArray
              .filter((item) => item.status === 'SUCCESSFUL')
              .map(({ date, reference, amount_in_cents, category, subcategory, id, fee }) => ({
                date,
                reference,
                amount_in_cents,
                subcategory,
                category,
                id,
                fee,
              })),
          },
        }
      })
      .addCase(getExternalTransactions.pending, (state) => ({
        ...state,
        external: {
          ...state.external,
          isLoading: true,
        },
      }))

      .addCase(getExternalTransactions.fulfilled, (state, action) => {
        const providers = action.payload.transactions.reduce((acc, current) => {
          const provider = acc.find(
            (item) =>
              item.name === current.provider && item.document_name === current.document_name,
          )

          if (provider) {
            provider.transactions.push(current)
          } else {
            const { provider, document_name, ...rest } = current
            acc.push({
              name: provider,
              document_name,
              transactions: [rest],
            })
          }
          return acc
        }, [] as Provider[])

        return {
          ...state,
          external: {
            ...state.external,
            isLoading: false,
            providers,
          },
        }
      })
      .addCase(createExternalTransactions.fulfilled, (state, action) => {
        const providers = action.payload.transactions.reduce((acc, current) => {
          const provider = acc.find(
            (item) =>
              item.name === current.provider && item.document_name === current.document_name,
          )
          if (provider) {
            provider.transactions.push(current)
          } else {
            const { provider, document_name, ...rest } = current
            acc.push({
              name: provider,
              document_name,
              transactions: [rest],
            })
          }
          return acc
        }, [] as Provider[])

        return {
          ...state,
          external: {
            ...state.external,
            providers: [...state.external.providers, ...providers],
          },
        }
      })
      .addCase(deleteExternalTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          external: {
            ...state.external,
            providers: state.external.providers.filter(
              ({ name, document_name }) =>
                action.meta.arg.provider !== name ||
                action.meta.arg.document_name !== document_name,
            ),
          },
        }
      })
      .addCase(updateExternalTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          external: {
            ...state.external,
            providers: state.external.providers.map((provider) => ({
              ...provider,
              transactions: provider.transactions.map((transaction) => {
                const tx = action.meta.arg.data.transactions.find((tx) => tx.id === transaction.id)!
                return {
                  ...transaction,
                  category: tx.category,
                  subcategory: tx.subcategory,
                }
              }),
            })),
          },
        }
      })
      .addCase(updateTransactionCategory.fulfilled, (state, action) => {
        return {
          ...state,
          cashflows: {
            ...state.cashflows,
            isLoading: false,
            collection: state.cashflows.collection.map((transaction) => {
              const tx = action.meta.arg.data.find((tx) => tx.id === transaction.id)!
              return {
                ...transaction,
                category: tx.category,
                subcategory: tx.subcategory,
              }
            }),
          },
        }
      })

      .addCase(getCashflowsCategories.fulfilled, (state, action) => ({
        ...state,
        cashflows: {
          ...state.cashflows,
          isLoading: false,
          categories: {
            expenses: action.payload.expense_categories,
            cashflows: action.payload.cash_flow_categories,
          },
        },
      }))
      .addCase(getSignableAccountsDocuments.pending, (state) => ({
        ...state,
        signableDocuments: {
          ...state.signableDocuments,
          isLoading: true,
        },
      }))
      .addCase(getSignableAccountsDocuments.rejected, (state) => ({
        ...state,
        signableDocuments: {
          ...state.signableDocuments,
          isLoading: false,
        },
      }))
      .addCase(getSignableAccountsDocuments.fulfilled, (state, action) => ({
        ...state,
        signableDocuments: {
          collection: action.payload,
          isLoading: false,
        },
      }))
      .addCase(getOtherAccountingDocuments.pending, (state) => ({
        ...state,
        otherAccountingDocuments: {
          ...state.otherAccountingDocuments,
          isLoading: true,
        },
      }))
      .addCase(getOtherAccountingDocuments.rejected, (state) => ({
        ...state,
        otherAccountingDocuments: {
          ...state.otherAccountingDocuments,
          isLoading: false,
        },
      }))
      .addCase(getOtherAccountingDocuments.fulfilled, (state, action) => ({
        ...state,
        otherAccountingDocuments: {
          documents: action.payload,
          isLoading: false,
        },
      }))
  },
})

export const {
  dismissModal,
  addAccountancyExpenses,
  addUploadedDocuments,
  saveCashflowForm,
  saveQuestionsForm,
  saveAccountancyNotes,
  resetAccountancy,
  setFieldsErrors,
} = accountancySlice.actions

export default accountancySlice.reducer
