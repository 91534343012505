import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'
import { RentTracker, RentalTransactionsQuery } from 'src/types/RentTracker'

export const getRentalTransactions = createAsyncThunk(
  'rentTracker/getRentalTransactions',
  (query: RentalTransactionsQuery) => api.getRentalTransactions(query),
)

export const initialState: RentTracker = {
  transactions: [],
  isLoading: false,
  pageNumber: 1,
  isMore: false,
  isMobileCardOpen: false,
  isError: false,
  num_transactions: 0,
  total_amount_in_cents: 0,
  summary: [],
}

const rentTrackerSlice = createSlice({
  name: 'rentTracker',
  initialState,
  reducers: {
    toggleMobileCard: (state) => {
      state.isMobileCardOpen = !state.isMobileCardOpen
    },
    setIsMore: (state, action) => {
      state.isMore = action.payload
    },
    resetRentTracker: (state) => {
      state.transactions = []
      state.pageNumber = 1
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRentalTransactions.pending, (state) => {
        state.isLoading = true
        state.pageNumber += 1
      })
      .addCase(getRentalTransactions.fulfilled, (state, action) => {
        state.transactions = [...state.transactions, ...action.payload.transactions]
        state.num_transactions = action.payload.num_transactions
        state.summary = action.payload.summary
        state.isLoading = false
        state.total_amount_in_cents = action.payload.total_amount_in_cents
      })
      .addCase(getRentalTransactions.rejected, (state) => {
        state.isError = true
        state.pageNumber -= 1
      })
  },
})

export const { toggleMobileCard, setIsMore, resetRentTracker } = rentTrackerSlice.actions

export default rentTrackerSlice.reducer
