import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  backdrop: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: ({ isLoading }) => (isLoading ? 1 : 0),
    zIndex: ({ isLoading }) => (isLoading ? 9999 : -1),
    visibility: ({ isLoading }) => (isLoading ? 'visible' : 'hidden'),
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  loader: {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.2)',
    width: '100%',
    height: '100%',
  },
  ripple: {
    width: 150,
    height: 150,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1)',

    '& > div': {
      boxSizing: 'content-box',
      position: 'absolute',
      borderWidth: 4,
      borderStyle: 'solid',
      opacity: 1,
      borderRadius: '50%',
      webkitAnimation: '$rippleEffect 0.7s cubic-bezier(0, 0.2, 0.8, 1) infinite',
      animation: '$rippleEffect 0.7s cubic-bezier(0, 0.2, 0.8, 1) infinite',
    },

    '& > div:nth-child(1)': {
      borderColor: '#333333',
    },

    '& > div:nth-child(2)': {
      borderColor: '#dddddd',
      webkitAnimationDelay: '-0.35s',
      animationDelay: '-0.35s',
    },
  },
  '@keyframes rippleEffect': {
    '0%': {
      top: '50%',
      left: '50%',
      width: 0,
      height: 0,
      opacity: 1,
    },

    '100%': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },
})

const Loader = ({ customLoading }: { customLoading?: boolean }) => {
  const isLoading = customLoading ?? useSelector((state) => state.loader.isLoading)
  const classes = useStyles({ isLoading })

  return isLoading ? (
    <div className={classes.backdrop}>
      <div className={classes.loader}>
        <div className={classes.ripple}>
          <div />
          <div />
        </div>
      </div>
    </div>
  ) : null
}

export default Loader
