import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoading: true,
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: () => initialState,
    hideLoader: (state) => ({ ...state, isLoading: false }),
  },
})

export const { showLoader, hideLoader } = loaderSlice.actions

export default loaderSlice.reducer
